import React from 'react';
import { Img } from '../design';
import VisaIcon from '../assets/imgs/visa.png';
import MasterIcon from '../assets/imgs/master.png';
import AeIcon from '../assets/imgs/ae.png';
import DefaultCardIcon from '../assets/imgs/default-card.svg';
import { CardNames } from '../appconstants';

export const AmountDivideByHundred = (amount:number) => (amount ? (amount / 100).toFixed(2) : amount);

export const BrandLogo = (brandName:string) => {
  let icon;
  switch (brandName) {
    case CardNames.AMERICAN_EXPRESS:
      icon = AeIcon;
      break;
    case CardNames.VISA:
      icon = VisaIcon;
      break;
    case CardNames.MASTERCARD:
      icon = MasterIcon;
      break;
    default:
      icon = DefaultCardIcon;
  }
  return <Img verticalAlign="middle" borderRadius="3px" width="23px" src={icon} />;
};
