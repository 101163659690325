import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Menu,
  Icon,
  Card,
  Row,
  Col,
} from 'antd';
import {
  SpTab,
  SpText,
  SpButton,
} from 'components/DesignKit';
import TransferCenter from 'imgs/transfers.svg';
import Contacts from 'imgs/contacts.svg';
import Manage from 'imgs/manageActivity.svg';
import Account from 'imgs/accountTransfer.svg';
import Bulk from 'imgs/bulk.svg';
import Activity from 'imgs/activity.svg';
import Transfer from 'imgs/transferLink.svg';
import Vendor from 'imgs/vendorInvoice.svg';
import SendMoney from 'imgs/sendMoney.svg';

type Props = {
  history: {
    push: Function,
    replace: Function,
  },
};

const Transfercenter = (props: Props) => {
  const {
    history,
  } = props;
  const { transfers, setTransfers } = useState(false);

  useEffect(() => {
    // setTransfers(true);
  }, []);

  return (
    <div
      className="mx-auto mt-5"
      style={{ width: 1000, height: 'auto' }}
      bodyStyle={{ height: '100%' }}
    >
      <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
        <img
          style={{ width: '350px' }}
          src={TransferCenter}
          alt=""
        />
        <SpText fontSize="26px" fontWeight="600">TRANSFER CENTER</SpText>
        <SpText fontSize="18px" fontWeight="400">Quickly and easily transfer money between your accounts.</SpText>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-5">
        <div
          className="mr-2"
          style={{
            border: '1px solid #EBEBEB',
            backgroundColor: '#fff',
            borderRadius: '15px',
            padding: '15px',
            textAlign: 'center',
            width: '25%',
            height: '150px',
            cursor: 'pointer',
          }}
          onClick={() => history.push('/transfers')}
        >
          <img
            style={{ width: '70px' }}
            src={Activity}
            alt=""
            className="mb-2"
          />
          <div className="mt-3">
            <SpText fontSize="18px" fontWeight="400">View Activity</SpText>
          </div>
        </div>
        <div
          className="mr-2"
          style={{
            border: '1px solid #EBEBEB',
            backgroundColor: '#fff',
            borderRadius: '15px',
            padding: '15px',
            textAlign: 'center',
            width: '25%',
            height: '150px',
            cursor: 'pointer',
          }}
          onClick={() => history.push('/funding-sources/contacts')}
        >
          <img
            style={{ width: '70px' }}
            src={Contacts}
            alt=""
            className="mb-2"
          />
          <div className="mt-3">
            <SpText fontSize="18px" fontWeight="400">Contacts</SpText>
          </div>
        </div>
        <div
          className="mr-2"
          style={{
            border: '1px solid #EBEBEB',
            backgroundColor: '#fff',
            borderRadius: '15px',
            padding: '15px',
            textAlign: 'center',
            width: '25%',
            height: '150px',
            cursor: 'pointer',
          }}
          onClick={() => history.push('/sendmoney')}
        >
          <img
            style={{ width: '70px' }}
            src={Account}
            alt=""
            className="mb-2"
          />
          <div className="mt-3">
            <SpText fontSize="18px" fontWeight="400">Transfer Funds</SpText>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center mt-2">
        <div
          className="mr-2"
          style={{
            border: '1px solid #EBEBEB',
            backgroundColor: '#fff',
            borderRadius: '15px',
            padding: '15px',
            textAlign: 'center',
            width: '25%',
            height: '150px',
            cursor: 'pointer',
          }}
          onClick={() => history.push('/vendorinvoice')}
        >
          <img
            style={{ width: '70px' }}
            src={Vendor}
            alt=""
            className="mb-2"
          />
          <div className="mt-3">
            <SpText fontSize="18px" fontWeight="400">Vendor Invoices</SpText>
          </div>
        </div>
        <div
          className="mr-2"
          style={{
            border: '1px solid #EBEBEB',
            backgroundColor: '#fff',
            borderRadius: '15px',
            padding: '15px',
            textAlign: 'center',
            width: '25%',
            height: '150px',
            cursor: 'pointer',
          }}
          onClick={() => history.push('/bulk-transfer')}
        >
          <img
            style={{ width: '70px' }}
            src={Bulk}
            alt=""
            className="mb-2"
          />
          <div className="mt-3">
            <SpText fontSize="18px" fontWeight="400">Bulk Transfer</SpText>
          </div>
        </div>
        <div
          className="mr-2"
          style={{
            border: '1px solid #EBEBEB',
            backgroundColor: '#fff',
            borderRadius: '15px',
            padding: '15px',
            textAlign: 'center',
            width: '25%',
            height: '150px',
            cursor: 'pointer',
          }}
          onClick={() => history.push('/manageaccounts')}
        >
          <img
            style={{ width: '70px' }}
            src={Manage}
            alt=""
            className="mb-2"
          />
          <div className="mt-3">
            <SpText fontSize="18px" fontWeight="400">Manage Accounts</SpText>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Transfercenter);
