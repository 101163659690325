// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
  DatePicker,
  Divider,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import moment from 'moment-timezone';
import {
  countryCodeValidation,
  validators,
  validationRegex,
} from 'utils/validationMessages';
import getStateList from 'utils/stateList';
import getCountryAlpha2 from 'utils/getCountryAlpha2';
import { isValidPhoneNumber, formatNumber } from 'libphonenumber-js';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import {
  COUNTRY_PHONE_CODE,
  COUNTRY_CODES,
} from 'appconstants';
import { vendorsActions } from 'store/actions';

const { Option } = Select;

type Props = {
  close: Function,
  phoneCode: string,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  fetchCustomTypes: Function,
  deleteSingleCustomType: Function,
  deleteAllCustomTypes: Function,
  customSuggestions: Array<Object>,
};

const AddFundingSourceLink = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    phoneCode,
    selectedAccount,
    fetchCustomTypes,
    deleteSingleCustomType,
    deleteAllCustomTypes,
    customSuggestions,
  } = props;

  const [, forceUpdate] = useState();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhone] = useState('');
  const [notify, setNotify] = useState('Email');
  const [countryCode, setCountryCode] = useState(phoneCode);
  const [phoneFormat, setPhoneFormat] = useState();
  const [redirectUrl, setRedirectUrl] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [contactType, setContactType] = useState('CUSTOMER');
  const [contactTypeName, setContactTypeName] = useState('Customer');
  const [customType, setCustomType] = useState('');
  const STRING_LENGTH = 5;
  const [dop, setDop] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const dateFormat = 'MMM DD, YYYY';
  const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  const currentDate = moment().format(MOMENT_FORMAT);
  const disableDate = moment().tz(currentDate, selectedAccount.timezone).endOf('day');
  const [streetName, setStreetName] = useState('');
  const [cityName, setCityName] = useState('');
  const [stateName, setStateName] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [countryName, setCountryName] = useState(selectedAccount.country.name === 'India' ? 'INDIA' : 'USA');
  const [stateList, setSateList] = useState(getStateList(selectedAccount.country.id));
  const [zipTag, setZipTag] = useState('');
  const [countryCod, setCountryCod] = useState(getCountryAlpha2(selectedAccount.country.id));
  const [businessType, setBusinessType] = useState('');

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      stringLength: {
        message: validators.paymentLink.validName,
        rule: val => val.length >= STRING_LENGTH,
      },
      emailId: {
        message: validators.register.validEmail,
        rule: val => validationRegex.email.test(val),
      },
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  useEffect(() => {
    fetchCustomTypes({ type: 'CONTACT_TYPE' });
  }, []);

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const onDateChange = (date, dateString) => {
    setDop(dateString);
    if (date && dateString) {
      const Date = moment(date).format(MOMENT_FORMAT);
      const selectedDate = moment.tz(Date, selectedAccount.timezone);
      const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
      setExpiryDate(utcDate);
    } else if (!date && !dateString) {
      setExpiryDate(null);
    }
  };

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormat(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const onCountryCodeChange = (value) => {
    setCountryCode(value);
    const data = formatNumber(`${value}${phoneNumber}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormat(formater);
  };

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    setDisableButton(true);
    setPhone(phoneNumber.replace(/[^0-9+]/g, ''));
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    submit({
      name,
      email,
      contactType,
      customType,
      phoneNumber,
      countryCode,
      notify,
      redirectUrl,
      expiryDate,
      businessType,
      countryCod,
      countryName,
      streetName,
      cityName,
      zipCode,
      stateName,
    });
  };

  const updateCountry = (value) => {
    setCountryCod(value);
    if (value === 'IN') {
      setSateList(getStateList(2));
      setZipTag('Pin code');
      setCountryName('INDIA');
    } else if (value === 'US') {
      setCountryName('USA');
      setZipTag('Zip code');
      setSateList(getStateList(1));
    } else if (value === 'CA') {
      setCountryName('CANADA');
      setZipTag('Zip code');
      setSateList(getStateList(value));
    }
  };

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
  }, [selectedAccount]);

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
      title="Create Funding Source Link"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', name, 'required|stringLength')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Email</SpH5></label>
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('email', email, 'required|emailId|checkLowerCase')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Phone</SpH5></label>
          <div className="d-flex">
            <div
              style={{ width: '20%' }}
              onKeyPress={e => countryCodeValidation(e)}
            >
              <Select
                className="w-100"
                showSearch
                defaultValue={countryCode}
                onChange={onCountryCodeChange}
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '80%' }}>
              <Input
                className="InputnumFormate"
                placeholder="Phone Number"
                defaultValue={phoneFormat}
                onChange={onPhoneNumberChange}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    setPhone(e.currentTarget.value);
                    const value = formatNumber(`${countryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                    const formater = value.substr(value.indexOf(' ') + 1);
                    setPhoneFormat(formater);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('phone number', phoneNumber, `required|phoneNumValidation:${countryCode}${phoneNumber}`)}
              </SpError>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Street Address</SpH5></label>
          <Input
            placeholder="Street Address"
            value={streetName}
            onChange={(e) => setStreetName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('street', streetName, 'required')}
          </SpError>
        </div>
        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>City</SpH5></label>
              <Input
                placeholder="City"
                value={cityName}
                onChange={(e) => setCityName(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('city', cityName, 'required')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>State</SpH5></label>
              <Select
                className="w-100"
                placeholder="State"
                showSearch
                optionFilterProp="children"
                onChange={(e) => setStateName(e)}
                value={stateName}
              >
                {
                  stateList.map(s => (
                    <Option
                      key={s.id}
                      value={s.id}
                      className="text-capitalize"
                    >
                      {s.name}
                    </Option>
                  ))
                }
              </Select>
              <SpError>
                {simpleValidator.current.message('state', stateName, 'required')}
              </SpError>
            </div>
          </div>
        </div>
        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>{zipTag}</SpH5></label>
              <Input
                placeholder={zipTag}
                value={zipCode}
                onChange={(e) => setZipCode(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message(zipTag, zipCode, 'required|postalcode')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>Country</SpH5></label>
              <Select
                defaultValue={countryName}
                style={{ width: '100%' }}
                onChange={updateCountry}
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.ALPHA2}>
                    {item.NAME}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Business Type</SpH5></label>
          <Select
            className="w-100"
            showSearch
            value={businessType}
            onChange={(value) => (setBusinessType(value))}
          >
            <Option key={1} value="INDIVIDUAL">INDIVIDUAL</Option>
            <Option key={2} value="BUSINESS">BUSINESS</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('business Type', businessType, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Contact Type</SpH5></label>
          <Select
            className="w-100"
            showSearch
            value={contactTypeName}
            onChange={(value) => {
              setContactType(value[0]);
              setContactTypeName(value[1]);
            }}
          >
            <Option key={1} value={['CUSTOMER', 'Customer']}>Customer</Option>
            <Option key={2} value={['EMPLOYEE', 'Employee']}>Employee</Option>
            <Option key={3} value={['VENDOR', 'Vendor']}>Vendor</Option>
            <Option key={4} value={['SELF', 'Self']}>Self</Option>
            <Option key={5} value={['OTHER', 'Other']}>Other</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('contact Type', contactType, 'required')}
          </SpError>
        </div>
        {contactType && contactType === 'OTHER' && (
          <div className="mb-3">
            <label htmlFor="">
              <SpH5>Custom Type</SpH5>
            </label>
            {customSuggestions.length > 0 ? (
              <>
                <div onMouseDown={(e) => { e.preventDefault(); return false; }}>
                  <Select
                    showSearch
                    placeholder="(Optional)"
                    className="w-100 mb-2"
                    value={customType}
                    onChange={(value) => setCustomType(value)}
                    onSearch={(value) => setCustomType(value)} // Handle typing a custom type
                    mode="combobox" // Allows users to input a value not in the list
                    dropdownRender={menu => (
                      <div>
                        <div style={{ maxHeight: '140px', overflowY: customSuggestions.length > 4 ? 'scroll' : 'auto' }}>
                          {menu}
                        </div>
                        {customSuggestions.length > 1 && (
                          <>
                            <Divider style={{ margin: '2px 0' }} />
                            <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 3 }}>
                              <span
                                style={{
                                  flex: 'none',
                                  border: 'none',
                                  background: 'none',
                                  width: '100%',
                                  display: 'block',
                                  cursor: 'pointer',
                                  color: 'red',
                                  padding: '8px',
                                  textAlign: 'center',
                                }}
                                onClick={() => {
                                  deleteAllCustomTypes({ type: 'CONTACT_TYPE' });
                                }}
                              >
                                Delete All
                              </span>
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  >
                    {customSuggestions.map((word, index) => (
                      <Select.Option key={index} value={word}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                          <span>{word}</span>
                          <button
                            type="button"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteSingleCustomType({ customType: word, type: 'CONTACT_TYPE' });
                            }}
                            // eslint-disable-next-line object-curly-newline
                            style={{ border: 'none', background: 'none', cursor: 'pointer', color: 'red' }}
                          >
                            Delete
                          </button>
                        </div>
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </>
            ) : (
              <Input
                placeholder="(Optional)"
                value={customType || ''}
                onChange={(e) => setCustomType(e.target.value)}
              />
            )}
          </div>
        )}
        <div className="mb-3">
          <label htmlFor=""><SpH5>Due Date(Optional)</SpH5></label>
          <div>
            <DatePicker
              value={dop ? moment(dop) : ''}
              format={dateFormat}
              allowClear={false}
              onChange={onDateChange}
              disabledDate={current => current && current < disableDate}
            />
          </div>
        </div>
        <div className="pb-3">
          <label><SpH5>Notification Type</SpH5></label>
          <Select
            className="w-100"
            value={notify}
            onChange={(e) => setNotify(e)}
          >
            <Option key={1} value="EMAIL">Email</Option>
            <Option key={2} value="SMS">SMS</Option>
            <Option key={3} value="ALL">Email and SMS</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('notify', notify, 'required')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Redirect Url (Optional)</SpH5></label>
          <Input
            placeholder="http://www.example.com"
            value={redirectUrl}
            onChange={(e) => setRedirectUrl(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('redirectUrl', redirectUrl, 'url')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => (console.log(state), {
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  customSuggestions: state.vendors.customSuggestions,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCustomTypes: param => dispatch(vendorsActions.fetchCustomTypes({
    payload: param,
  })),
  deleteSingleCustomType: param => dispatch(vendorsActions.deleteSingleCustomType({
    payload: param,
  })),
  deleteAllCustomTypes: param => dispatch(vendorsActions.deleteAllCustomTypes({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(AddFundingSourceLink);
