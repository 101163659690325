// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
} from 'antd';
import { useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';
import _get from 'lodash.get';

import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';

import getCashBackground from 'utils/getCashBackground';
import getCashStatus from 'utils/getCashStatus';
import {
  SpText,
  SpH5,
  SpButton,
  SpForm,
  SpStatusTag,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import { cashtransactionsDetailsActions } from 'store/actions';
import {
  PAGES,
  ROLES,
  API_STATUS,
} from 'appconstants';
import RBAC from 'components/rbac';
import formatAmount from 'utils/formatAmount';
import VoidPayment from './component/VoidPayment';
import RefundPayment from './component/RefundPayment';

type Props = {
  fetchCashTransactionDetails: Function,
  notifyCashTransactionsDetails: Function,
  voidPayment: Function,
  cashtransactionsDetails: Object,
  loading: boolean,
  test: boolean,
  validator: boolean,
  submitting: boolean,
  match: Object,
  params: Object,
  selectedAccount: Object,
  history: {
    push: Function,
  };
  cashtransactionsDetailsStatus: string,
  displayAmount: Object,
  refundCashPayment: Function,
}

const amountFilter = (cashtransactionsDetails) => cashtransactionsDetails;

const displayAmountSelector = createSelector(
  [amountFilter],
  (cashtransactionsDetails) => ({
    prefix: _get(cashtransactionsDetails, 'currency.prefix', null),
    amountDisplay:
      formatNumber(cashtransactionsDetails.amount
        && (cashtransactionsDetails.amount / 100).toFixed(cashtransactionsDetails.currency.toFixed), cashtransactionsDetails.currency),
    amountVoided: formatNumber(cashtransactionsDetails.amountVoided
      && (cashtransactionsDetails.amountVoided / 100).toFixed(2), cashtransactionsDetails.currency),
    refundDisplay:
      formatNumber(
        cashtransactionsDetails.amountRefunded && (cashtransactionsDetails.amountRefunded / 100).toFixed(cashtransactionsDetails.currency.toFixed),
        cashtransactionsDetails.currency,
      ),
    refundAmount: (cashtransactionsDetails.cashTendered && cashtransactionsDetails.amountRefunded)
      ? formatNumber(((cashtransactionsDetails.cashTendered / 100)
        - (cashtransactionsDetails.amountRefunded / 100)), cashtransactionsDetails.currency)
      : (cashtransactionsDetails.cashTendered) ? formatNumber(cashtransactionsDetails.cashTendered / 100, cashtransactionsDetails.currency)
        : formatNumber(cashtransactionsDetails.amount / 100, cashtransactionsDetails.currency),
  }),
);

const CashDetails = (props: Props) => {
  const {
    fetchCashTransactionDetails,
    notifyCashTransactionsDetails,
    voidPayment,
    cashtransactionsDetails,
    loading,
    test,
    validator,
    submitting,
    history,
    selectedAccount,
    cashtransactionsDetailsStatus,
    displayAmount,
    refundCashPayment,
  } = props;

  const location = useLocation();
  const [voided, setVoided] = useState(false);
  const [notify, setNotify] = useState(false);
  const [voidPaymentModal, setVoidPaymentModal] = useState(false);
  const [refundModal, setRefundModal] = useState(false);
  const { gid } = props.match.params;
  const gidPath = gid.split('-')[1];
  const currencyType = selectedAccount && selectedAccount.currency;

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.PAYMENTS);
    }
  }, []);

  useEffect(() => {
    fetchCashTransactionDetails(gid);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    if (!validator) {
      setVoidPaymentModal(false);
      setRefundModal(false);
    }
  }, [validator]);

  useEffect(() => {
    if (cashtransactionsDetails.status === 'PAID') {
      setVoided(true);
    } else {
      setVoided(false);
    }
    if (cashtransactionsDetails.receiptEmail || cashtransactionsDetails.receiptSms) {
      setNotify(true);
    } else {
      setNotify(false);
    }
  }, [cashtransactionsDetails]);

  const selectedReceipts = (record) => {
    const id = record;
    window.open(`${PAGES.CASH_RECEIPT}/${id}`);
  };

  // const selectedReceipts = (record) => {
  //   const id = record;
  //   const terminals = cashtransactionsDetails && cashtransactionsDetails.terminal;
  //   const paymentPageDetails = cashtransactionsDetails && cashtransactionsDetails.paymentPage;
  //   if (terminals && terminals.length !== 0) {
  //     const deviceId = terminals && terminals.deviceId;
  //     if (deviceId && deviceId[0] === 'C') {
  //       const terminalTid = terminals && terminals.terminalTid;
  //       const mrn = cashtransactionsDetails && cashtransactionsDetails.mrn;
  //       let merchantId = '';
  //       if (mrn) {
  //         const firstMrn = mrn.split('_');
  //         merchantId = firstMrn[0];
  //       }
  //       window.open(`${PAGES.CLOVER_RECEIPT}?m=${terminalTid}&o=${merchantId}`);
  //     } else if (paymentPageDetails && (paymentPageDetails.length !== 0)) {
  //       window.open(`${PAGES.CASH_RECEIPT}/${id}`);
  //     } else {
  //       window.open(`${PAGES.CASH_RECEIPT}/${id}`);
  //     }
  //   } else if (paymentPageDetails && (paymentPageDetails.length !== 0)) {
  //     window.open(`${PAGES.CASH_RECEIPT}/${id}`);
  //   } else {
  //     window.open(`${PAGES.CASH_RECEIPT}/${id}`);
  //   }
  // };

  const selectedNotify = () => {
    notifyCashTransactionsDetails(gid);
  };

  const voidSelectedPayment = (data: string) => {
    const {
      status,
    } = data;
    const params = {
      status,
    };
    voidPayment({
      gid,
      params,
    });
  };

  const submitRefund = (refund: Object) => {
    const {
      amount,
      reason,
      description,
      note,
    } = refund;
    const currency = amount.replace(/[^\d]/g, '');
    const params = {
      description: `Partial Refund For Charge ${description}`,
      refundReason: reason,
      amountRefunded: Number(currency),
      refundNote: note,
    };

    refundCashPayment({
      gid,
      params,
    });
  };

  const showVoidPaymentModal = () => {
    setVoidPaymentModal(true);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        cashtransactionsDetails.gid && cashtransactionsDetailsStatus === API_STATUS.SUCCESS && (
          <>
            <Card>
              <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                <Col className="d-flex align-items-center">
                  <SpText className="mr-5" fontSize="20px" fontWeight="600">
                    {`${displayAmount.prefix}${displayAmount.amountDisplay}`}
                  </SpText>
                  <SpStatusTag style={{ backgroundColor: getCashBackground(cashtransactionsDetails.status) }}>
                    {getCashStatus(cashtransactionsDetails.status)}
                  </SpStatusTag>
                </Col>
                <Col>
                  {
                    (cashtransactionsDetails.status === 'PAID' || cashtransactionsDetails.status === 'PARTIALLY_REFUNDED') && (
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <SpButton
                          type="secondary"
                          shape="round"
                          className="mr-4"
                          ghost
                          onClick={() => setRefundModal(true)}
                        >
                          Refund
                        </SpButton>
                      </RBAC>
                    )
                  }
                  {
                    voided && (
                      // eslint-disable-next-line max-len
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.ANALYST, ROLES.SUPPORT, ROLES.POS, ROLES.SUPERUSER]}>
                        <SpButton
                          type="secondary"
                          shape="round"
                          className="mr-4"
                          ghost
                          onClick={(e) => { e.stopPropagation(); showVoidPaymentModal(gid); }}
                        >
                          Void
                        </SpButton>
                      </RBAC>
                    )
                  }
                </Col>

              </Row>
              <SpForm>
                <Row type="flex">
                  <Col span={8}>
                    <div>
                      <span className="mr-3">Date</span>
                    </div>
                    <div>
                      <SpText fontSize="18px" fontWeight="600">
                        {
                          cashtransactionsDetails.transactionDate && selectedAccount && selectedAccount.timezone
                            ? (
                              moment
                                .utc(cashtransactionsDetails.transactionDate)
                                .tz(selectedAccount.timezone)
                                .format('MMM DD, YYYY, hh:mm a')
                            )
                            : <>&#8211;</>
                        }
                      </SpText>
                    </div>
                  </Col>
                  <Col span={8}>
                    <div>
                      <span>Payment Method</span>
                    </div>
                    <div>
                      <SpText fontSize="18px" fontWeight="600">CASH</SpText>
                    </div>
                  </Col>
                  {cashtransactionsDetails && cashtransactionsDetails.customer
                    ? (
                      <Col span={8}>
                        <div>
                          <span>Customer</span>
                        </div>
                        <div>
                          <SpText fontSize="18px" fontWeight="600">
                            {cashtransactionsDetails.customer && cashtransactionsDetails.customer.name}
                          </SpText>
                        </div>
                      </Col>
                    ) : ''}
                </Row>
              </SpForm>
              <hr />
              <SpForm>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Amount</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {`${displayAmount.prefix} ${displayAmount.amountDisplay}`}
                    </SpH5>
                  </Col>
                </Row>
                {
                  (cashtransactionsDetails && cashtransactionsDetails.amountRefunded !== 0) && (
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Refunded</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>
                          {`${displayAmount.prefix}${displayAmount.refundDisplay}`}
                        </SpH5>
                      </Col>
                    </Row>
                  )
                }
                {cashtransactionsDetails && cashtransactionsDetails.amountVoided
                  ? (
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Amount Voided</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>
                          {
                            cashtransactionsDetails.amountVoided
                              ? `(${displayAmount.prefix} ${(displayAmount.amountVoided).replace('-', '')})`
                              : <>&#8211;</>
                          }
                        </SpH5>
                      </Col>
                    </Row>
                  ) : ''}
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Description</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{(cashtransactionsDetails && cashtransactionsDetails.description)
                      ? (cashtransactionsDetails.description === ' ' ? <>&#8211;</> : cashtransactionsDetails.description) : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <hr />
              <SpForm>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Email</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {(cashtransactionsDetails.customer && cashtransactionsDetails.customer.email)
                        ? cashtransactionsDetails.customer.email : <>&#8211;</>}
                    </SpH5>
                  </Col>
                  <Col span={3}>
                    <span>Phone</span>
                  </Col>
                  <Col span={6}>
                    <SpH5>
                      {(cashtransactionsDetails.customer && cashtransactionsDetails.customer.phoneNumber)
                        ? cashtransactionsDetails.customer.phoneNumber : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              {refundModal && (
                <RefundPayment
                  visible={refundModal}
                  submitting={submitting}
                  close={() => setRefundModal(false)}
                  submit={submitRefund}
                  paymentDetails={cashtransactionsDetails}
                  refundAmount={formatAmount(displayAmount.refundAmount, currencyType)}
                  selectedAccount={selectedAccount}
                />
              )}
              {/* VOID PAYMENT MODAL */}
              {voidPaymentModal && (
                <VoidPayment
                  visible={voidPaymentModal}
                  submitting={submitting}
                  description={(cashtransactionsDetails && cashtransactionsDetails.description)}
                  close={() => setVoidPaymentModal(false)}
                  submit={voidSelectedPayment}
                />
              )}
            </Card>
            {
              cashtransactionsDetails.receiptNumber
                ? (
                  <Card className="mb-3">
                    <Row type="flex" justify="space-between" className="my-2">
                      <Col>
                        <SpText className="text-uppercase mb-3" fontSize="20px">Receipt</SpText>
                      </Col>
                      <Col>
                        <SpButton
                          type="secondary"
                          shape="round"
                          className="mr-4"
                          onClick={() => selectedReceipts(cashtransactionsDetails.receiptNumber)}
                        >
                          View Receipt
                        </SpButton>
                        {
                          notify && (
                            // eslint-disable-next-line max-len
                            <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.ANALYST, ROLES.SUPPORT, ROLES.POS, ROLES.SUPERUSER]}>
                              <SpButton
                                type="secondary"
                                shape="round"
                                className="mr-4"
                                ghost
                                onClick={selectedNotify}
                              >
                                Send Receipt
                              </SpButton>
                            </RBAC>
                          )
                        }
                      </Col>
                    </Row>
                    <SpForm>
                      <Row type="flex" justify="start">
                        <Col span={4}>
                          <span>
                            {
                              cashtransactionsDetails.transactionDate && selectedAccount && selectedAccount.timezone
                                ? (
                                  moment
                                    .utc(cashtransactionsDetails.transactionDate)
                                    .tz(selectedAccount.timezone)
                                    .format('MMM DD, YYYY, hh:mm a')
                                )
                                : <>&#8211;</>
                            }
                          </span>
                        </Col>
                        <Col span={4}>
                          <span>
                            {`Receipt ${(cashtransactionsDetails && cashtransactionsDetails.receiptNumber)
                              ? cashtransactionsDetails.receiptNumber
                              : <>&#8211;</>}`}
                          </span>
                        </Col>
                      </Row>
                    </SpForm>
                  </Card>
                ) : ''
            }
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  cashtransactionsDetails: state.cashtransactionsDetails,
  cashtransactionsDetailsStatus: state.cashtransactionsDetails.cashtransactionsDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  displayAmount: displayAmountSelector(state.cashtransactionsDetails),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCashTransactionDetails: gid => dispatch(cashtransactionsDetailsActions.fetchCashTransactionDetails({
    payload: gid,
  })),
  notifyCashTransactionsDetails: gid => dispatch(cashtransactionsDetailsActions.notifyCashTransactionsDetails({
    payload: gid,
  })),
  voidPayment: param => dispatch(cashtransactionsDetailsActions.voidPayment({
    payload: param,
  })),
  refundCashPayment: param => dispatch(cashtransactionsDetailsActions.refundCashPayment({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CashDetails);
