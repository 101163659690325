/* eslint-disable max-len */
// @flow
import React, {
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  Input,
  AutoComplete,
  Select,
} from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import getVendorType from 'utils/getVendorType';
import { countryCodeValidation, validators } from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';
import getSortedList from 'utils/getSortedList';
import getStateList from 'utils/stateList';
import getCountryAlpha2 from 'utils/getCountryAlpha2';
import { SpH5, SpError } from 'components/DesignKit';
import {
  COUNTRY_PHONE_CODE,
  COUNTRY_CODES,
} from 'appconstants';
import { SpText } from 'components/PaymentWidgetDesignKit';

const { Option } = Select;

type Props = {
  submitting: boolean,
  selectedAccount: Array<Object>,
  contactDetailsData: Object,
  fetchContactDetails: Function,
  country: String,
};

const ContactDetails = (props: Props, ref) => {
  const {
    submitting,
    contactDetailsData,
    selectedAccount,
    fetchContactDetails,
    country,
  } = props;
  const [, forceUpdate] = useState();
  const [contactName, setContactName] = useState(contactDetailsData.contactName);
  const [email, setEmail] = useState(contactDetailsData.email);
  const [contactGid, setContactGid] = useState(contactDetailsData.contactGid);
  const [contactPhone, setContactPhone] = useState(contactDetailsData.contactPhone);
  const [customType, setCustomType] = useState(contactDetailsData.customType || '');
  const [customSuggestions, setCustomSuggestions] = useState(contactDetailsData.customSuggestions || []);
  // eslint-disable-next-line max-len
  const [phoneNumberCode, setPhoneCode] = useState(contactDetailsData.phoneNumberCode ? contactDetailsData.phoneNumberCode : getCountryPhoneCode((selectedAccount && selectedAccount.country && selectedAccount.country.id)));
  const [phoneFormate, setPhoneFormate] = useState(contactDetailsData.phoneFormate);
  const [contactType, setContactType] = useState(contactDetailsData.contactType || 'SELF');
  const [contactTypeName, setContactTypeName] = useState(contactDetailsData.contactTypeName || 'Self');
  const contacts = contactDetailsData.contactResult;
  const [disableContact, setDisableContact] = useState(contactDetailsData.disableContact);
  const [postalCodeRegex, setPostalCodeRegex] = useState(country === 'India' ? /^[1-9][0-9]{5}$/ : /^[0-9][0-9]{4}$/);
  const nameRegex = /^[ ]*([a-zA-Z'-.]{1,}( [a-zA-z'-. ]{1,})?)$/;
  const [streetName, setStreetName] = useState(contactDetailsData.streetName || '');
  const [cityName, setCityName] = useState(contactDetailsData.cityName || '');
  const [stateName, setStateName] = useState(contactDetailsData.stateName || '');
  const [zipCode, setZipCode] = useState(contactDetailsData.zipCode || '');
  const [countryName, setCountryName] = useState(contactDetailsData.countryCode ? (contactDetailsData.countryCode === 'IN' ? 'INDIA' : 'USA') : (country === 'India' ? 'INDIA' : 'USA'));
  const [stateList, setSateList] = useState(getStateList(selectedAccount.country.id));
  const [zipTag, setZipTag] = useState('');
  const [countryCode, setCountryCode] = useState(contactDetailsData.countryCode ? contactDetailsData.countryCode : getCountryAlpha2(selectedAccount.country.id));

  useEffect(() => {
    if (selectedAccount && selectedAccount.country) {
      if (selectedAccount.country.id === 1) {
        setZipTag('Zip code');
      } else if (selectedAccount.country.id === 2) {
        setZipTag('Pin code');
      }
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (country && country === 'India') {
      setCountryName('INDIA');
    } else if (country && country === 'United States of America') {
      setCountryName('USA');
    }
  }, []);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
      name: {
        message: validators.bussiness.validContactName,
        rule: val => (nameRegex.test(val)),
      },
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      contactName,
      contactGid,
      email,
      phoneNumberCode,
      contactPhone,
      phoneFormate,
      contactType,
      customType,
      customSuggestions,
      contactTypeName,
      disableContact,
      countryCode,
      streetName,
      cityName,
      stateName,
      zipCode,
    }),
  }));

  useEffect(() => {
    simpleValidator.current.purgeFields();
  }, [disableContact]);

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setContactPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormate(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const onCountryCodeChange = (value) => {
    setPhoneCode(value);
    const data = formatNumber(`${value}${contactPhone}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormate(formater);
  };

  const updateCountry = (value) => {
    setCountryCode(value);
    if (value === 'IN') {
      setSateList(getStateList(2));
      setZipTag('Pin code');
      setPostalCodeRegex(/^[1-9][0-9]{5}$/);
    } else if (value === 'US') {
      setZipTag('Zip code');
      setSateList(getStateList(1));
      setPostalCodeRegex(/^[0-9][0-9]{4}$/);
    } else if (value === 'CA') {
      setZipTag('Zip code');
      setSateList(getStateList(value));
      setPostalCodeRegex(/^[0-9][0-9]{4}$/);
    }
  };

  return (
    <>
      <div>
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mt-2 mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <Input
            placeholder="Name"
            value={contactName}
            onChange={(e) => setContactName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('name', contactName, 'required')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Email</SpH5></label>
          <Input
            placeholder="Email"
            value={email}
            disabled={disableContact}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('email', email, 'required|email|checkLowerCase')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Phone</SpH5></label>
          <div className="d-flex">
            <div
              style={{ width: '20%' }}
              onKeyPress={e => countryCodeValidation(e)}
            >
              <Select
                className="w-100"
                showSearch
                value={phoneNumberCode}
                onChange={onCountryCodeChange}
                disabled={disableContact}
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '80%' }}>
              <Input
                className="InputnumFormate"
                placeholder="Phone Number"
                value={phoneFormate}
                disabled={disableContact}
                onChange={onPhoneNumberChange}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    setContactPhone(e.currentTarget.value);
                    const value = formatNumber(`${phoneNumberCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                    const formater = value.substr(value.indexOf(' ') + 1);
                    setPhoneFormate(formater);
                  }
                }}
              />
              {
                !disableContact && (
                  <SpError>
                    {simpleValidator.current.message('phone number', contactPhone, `required|phoneNumValidation:${phoneNumberCode}${contactPhone}`)}
                  </SpError>
                )
              }
            </div>
          </div>
        </div>
        <div className="mb-3">
          <SpText fontSize="18px" fontWeight="600">Address Details</SpText>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Address Line</SpH5></label>
          <Input
            placeholder="Street Address"
            value={streetName}
            disabled={disableContact}
            onChange={(e) => setStreetName(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('street', streetName, 'required')}
          </SpError>
        </div>
        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>City</SpH5></label>
              <Input
                placeholder="City"
                value={cityName}
                disabled={disableContact}
                onChange={(e) => setCityName(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message('city', cityName, 'required')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>State</SpH5></label>
              <Select
                className="w-100"
                placeholder="State"
                showSearch
                optionFilterProp="children"
                disabled={disableContact}
                onChange={(e) => setStateName(e)}
                value={stateName}
              >
                {
                  stateList.map(s => (
                    <Option
                      key={s.id}
                      value={s.id}
                      className="text-capitalize"
                    >
                      {s.name}
                    </Option>
                  ))
                }
              </Select>
              <SpError>
                {simpleValidator.current.message('state', stateName, 'required')}
              </SpError>
            </div>
          </div>
        </div>
        <div className="pb-3">
          <div className="d-flex">
            <div className="w-50 mr-3">
              <label className="d-block"><SpH5>{zipTag}</SpH5></label>
              <Input
                placeholder={zipTag}
                value={zipCode}
                disabled={disableContact}
                onChange={(e) => setZipCode(e.currentTarget.value)}
              />
              <SpError>
                {simpleValidator.current.message(zipTag, zipCode, 'required')}
              </SpError>
            </div>
            <div className="w-50">
              <label className="d-block"><SpH5>Country</SpH5></label>
              <Select
                defaultValue={countryName}
                disabled={disableContact}
                style={{ width: '100%' }}
                onChange={updateCountry}
              >
                {COUNTRY_CODES.map((item) => (
                  <Option key={item.ID} value={item.ALPHA2}>
                    {item.NAME}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// $FlowFixMe
export default forwardRef(ContactDetails);
