// @flow
import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Icon,
  Select,
  Menu,
} from 'antd';
import { QRCode } from 'react-qrcode-logo';
// import QRCode from 'react-qr-code';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
// import { toPng } from 'html-to-image';

import styled from 'styled-components';

import {
  SpForm,
  SpText,
  SpH5,
  SpButton,
  SpStatusTag,
  SpTab,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  eventDetailsAction,
  notificationActions,
  accountSlugActions,
} from 'store/actions';
import { NOTIFICATION } from 'store/actionTypes';
import {
  MESSAGES,
  PAGES,
  ROLES,
  API_STATUS,
  COUNTRIES,
} from 'appconstants';
import ResendMail from 'imgs/ResendMail.svg';
import RBAC from 'components/rbac';
import getEventsBackground from 'utils/getEventsBackground';
import getEventsStatus from 'utils/getEventsStatus';
import formatNumber from 'utils/formatNumber';
import getPaymentBackground from 'utils/getPaymentBackground';
import getPaymentStatus from 'utils/getPaymentStatus';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import Clipboard from 'components/NavIcons/copyclipboard';
import OfflinePaymentPage from './components/offlinePaymentPage';
// import NotifySeatedPage from './components/NotifySeatedPage';
import Qrlogo from '../../../../assets/imgs/SwirepayQrlogo.png';


const { Option } = Select;

const StatusTag = styled('div')({
  borderRadius: '10px',
  display: 'inline-block',
  paddingLeft: '5px',
  paddingRight: '5px',
  marginLeft: 'auto',
  marginRight: 'auto',
  color: '#434343',
  fontWeight: 600,
  minWidth: '75px',
  textAlign: 'center',
});

type Props = {
  fetchEventDetails: Function,
  fetchCardDetails: Function,
  fetchAccountSlug: Function,
  setNotification: Function,
  eventDetails: Object,
  loading: boolean,
  submitting: boolean,
  selectedAccount: Object,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  cardsDetails: Array<Object>,
  totalElements: number,
  eventDetailsStatus: string,
  soldOutEvent: Function,
  deactivateEvent: Function,
  resumeSales: Function,
  offlinePayments: Function,
  validator: boolean,
  accountSlug: Array<Object>,
  paymentSlug: Array,
  linkAccountSlug: Function,
  unLinkAccountSlug: Function,
  fetchOfflineEventsPayments: Function,
  offlineEventPayments: Array,
  totalofflineElements: number,
  fetchEventsSlug: Function,
  clearEventDetails: Function,
  notifySeatedPageDetails: Function,
  // notifySeatedPage: Function,
  isMobileView: Boolean,
}

const EventsDetails = (props: Props) => {
  const {
    fetchEventDetails,
    fetchCardDetails,
    fetchAccountSlug,
    setNotification,
    eventDetails,
    loading,
    submitting,
    history,
    cardsDetails,
    totalElements,
    selectedAccount,
    eventDetailsStatus,
    soldOutEvent,
    deactivateEvent,
    resumeSales,
    offlinePayments,
    validator,
    accountSlug,
    paymentSlug,
    linkAccountSlug,
    unLinkAccountSlug,
    fetchOfflineEventsPayments,
    offlineEventPayments,
    totalofflineElements,
    fetchEventsSlug,
    clearEventDetails,
    notifySeatedPageDetails,
    // notifySeatedPage,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const location = useLocation();
  const { gid } = props.match.params;
  const { id } = selectedAccount && selectedAccount.currency;
  const [pagination, setPagination] = useState({});
  const gidPath = gid.split('-')[1];
  const [phoneCode, setPhoneCode] = useState('');
  const path = props.match.url;
  const currencyType = selectedAccount && selectedAccount.currency;
  const [current, setCurrent] = useState('payments');
  const [offlinePaymentModal, setOfflinePaymentModal] = useState(false);
  const qrCodeRef = useRef(null);
  const [slugLink, setSlugLink] = useState();
  const [slugGid, setSlugGid] = useState();
  const [enableUnlink, setEnableUnlink] = useState(false);
  const [redirect, setRedirect] = useState(false);
  // const [notifySeatedPageModal, setNotifySeatedPageModal] = useState(false);

  // const getData = () => {
  //   fetchEventDetails(gid);
  // };
  const { prefix } = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const direction = parsed.direction || 'DESC';
    const params = `&sortBy=${parsed.sortBy || 'createdAt'}&direction=${direction}`;
    fetchEventDetails(gid);
    fetchEventsSlug(gid);
    fetchAccountSlug({ currentPage, params });
  };

  // const notifyPayment = (customer: Object) => {
  //   const {
  //     emailId,
  //     phoneNumber,
  //     countryCode,
  //     subject,
  //     message,
  //     type,
  //   } = customer;
  //   notifySeatedPage({
  //     phoneNumber: phoneNumber ? `${countryCode}${phoneNumber}` : null,
  //     emailId,
  //     subject,
  //     message,
  //     type,
  //   });
  //   setRedirect(true);
  // };

  const getCardData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchCardDetails({ gid, currentPage, sortParams });
  };

  const getOfflineData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    fetchOfflineEventsPayments({ gid, currentPage, sortParams });
  };

  const copyCode = (record) => {
    const el = record;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTSLINK.SUCCESS,
    });
  };

  /**
   * Get data on page load
   */
  useEffect(() => {
    getData();
    if (current === 'payments') {
      getCardData(location.search);
    } else if (current === 'offlineData') {
      getOfflineData(location.search);
    }
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
  }, [current, location]);

  useEffect(() => {
    if (paymentSlug && paymentSlug.length > 0) {
      setEnableUnlink(true);
      setSlugLink(paymentSlug[0].slugName);
      setSlugGid(paymentSlug[0].gid);
    } else {
      setEnableUnlink(false);
      setSlugLink('');
      setSlugGid('');
    }
  }, [paymentSlug]);

  const linkSlug = () => {
    const params = {
      type: 'SEATED_PAGE',
      spObjectGid: gid,
      forceSet: false,
    };
    linkAccountSlug({ slugGid, params });
    setRedirect(true);
  };
  const unlinkSlug = async () => {
    unLinkAccountSlug(slugGid);
    setRedirect(true);
  };

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.EVENTS);
    }
  }, []);

  useEffect(() => {
    if (!validator && redirect) {
      history.push(PAGES.EVENTS);
    }
  }, [validator]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData();
    getCardData(location.search);
  }, [selectedAccount]);

  useEffect(() => {
    getCardData(location.search);
  }, [location]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const stat = parsed['status.IN'] || 'PAYMENTS';
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: stat === 'OFFLINEPAYMENTS' ? totalofflineElements : totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [cardsDetails, offlineEventPayments]);

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const stat = parsed['status.IN'] || 'PAYMENTS';
    const status = `?status.IN=${stat}`;
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    history.push({
      pathname: `${PAGES.EVENTS}/${gid}`,
      search: `${status}&pageNum=${tempPage}${tempParams}`,
    });
  };

  const selectedRecord = (record) => {
    const searchParams = record.gid;
    history.push({
      pathname: `${PAGES.PAYMENTS}/${searchParams}`,
      state: location.search,
    });
  };

  const copyReceiptCode = (record) => {
    const el = `${window.location.origin}${PAGES.EVENT_RECIPT}/${record}`;
    navigator.clipboard.writeText(el);
    document.execCommand('copy');
    setNotification({
      type: NOTIFICATION.SUCCESS,
      payload: 'Seated Event receipt copied successfully',
    });
  };

  const selectedNotify = (paymentDetails: object) => {
    const isPaymentPage = paymentDetails && paymentDetails.seatPage;
    notifySeatedPageDetails(isPaymentPage && isPaymentPage.gid);
  };

  const columns = [
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '13%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (amount, record) => {
        const netransaction = (amount / 100).toFixed(record.currency.toFixed);
        if (netransaction < 0) {
          return (
            <Tooltip placement="top" title="Click here to view details">
              <span style={{ cursor: 'pointer' }}>
                {`(${record.currency.prefix} ${formatNumber(netransaction, currencyType).replace(/-/g, '')})`}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span style={{ cursor: 'pointer' }}>
              {`${record.currency.prefix} ${formatNumber(netransaction, currencyType)}`}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (name, record) => {
        const customerName = record && record.customer && record.customer.name;
        return (
          <Tooltip placement="top" title="Click here to view details">
            <span>
              {customerName || <>&#8211;</>}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '14%',
      align: 'left',
      sorter: false,
      render: (status) => (
        <StatusTag style={{ backgroundColor: getPaymentBackground(status) }}>
          {getPaymentStatus(status)}
        </StatusTag>
      ),
    },
    {
      title: 'EMAIL',
      dataIndex: 'receiptEmail',
      width: '15%',
      align: 'left',
      sorter: false,
    },
    {
      title: 'PHONE',
      dataIndex: 'receiptSms',
      width: '15%',
      align: 'left',
      sorter: false,
    },
    {
      title: 'DATE',
      dataIndex: 'paymentDate',
      width: '15%',
      align: 'left',
      sorter: true,
      render: (paymentDate) => {
        if (paymentDate && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(paymentDate)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'RECEIPT',
      dataIndex: 'receiptNumber',
      width: '10%',
      align: 'left',
      render: (receiptNumber) => {
        if (receiptNumber) {
          return (
            <span>
              <SpButton
                type="secondary"
                borderRadius="50px"
                style={{ cursor: 'pointer' }}
                onClick={(e) => { e.stopPropagation(); copyReceiptCode(receiptNumber); }}
              >
                <Clipboard />
              </SpButton>
            </span>
          );
        }
        return (
          <span>
            <SpButton
              type="secondary"
              borderRadius="50px"
              disabled
            >
              <Clipboard />
            </SpButton>
          </span>
        );
      },
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      width: '15%',
      align: 'left',
      render: (text, record) => (
        <>
          <Tooltip placement="top" title="Notify">
            <span
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); selectedNotify(record); }}
            >
              <img
                src={ResendMail}
                alt="notify"
                style={{
                  height: '50px',
                  marginTop: '5px',
                }}
              />
            </span>
          </Tooltip>
        </>
      ),
    },
  ];

  const offlineOrderColumns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const name = record && record.seatPage && record.seatPage.name;
        return (
          <span>
            {name || <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'EMAIL',
      dataIndex: 'email',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const email = record && record.seatPage && record.seatPage.email;
        return (
          <span>
            {email || <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'PHONE',
      dataIndex: 'phone',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const phone = record && record.seatPage && record.seatPage.phone;
        return (
          <span>
            {phone || <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'TYPE',
      dataIndex: 'offlinePaymentType',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const offlinePaymentType = record && record.seatPage && record.seatPage.offlinePaymentType;
        let type;
        if (offlinePaymentType === 'CHECK') {
          type = isIndia ? 'Cheque' : 'Check';
        } else if (offlinePaymentType === 'CASH') {
          type = 'Cash';
        } else if (offlinePaymentType === 'CUSTOM') {
          type = 'Custom';
        }
        return (
          <span>
            {type || <>&#8211;</>}
          </span>
        );
      },
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amount',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (text, record) => {
        const amount = record && record.seatPage && record.seatPage.amount;
        const total = (amount / 100).toFixed(2);
        return (
          <span>
            {`${prefix}${formatNumber(total, currencyType)}`}
          </span>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '15%',
      align: 'left',
      render: (text, record) => {
        const createdAt = record && record.seatPage && record.seatPage.createdAt;
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
      sorter: false,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'RECEIPT',
      dataIndex: 'receiptNumber',
      width: '10%',
      align: 'left',
      render: (receiptNumber) => {
        if (receiptNumber) {
          return (
            <span>
              <SpButton
                type="secondary"
                borderRadius="50px"
                style={{ cursor: 'pointer' }}
                onClick={(e) => { e.stopPropagation(); copyReceiptCode(receiptNumber); }}
              >
                <Clipboard />
              </SpButton>
            </span>
          );
        }
        return (
          <span>
            <SpButton
              type="secondary"
              borderRadius="50px"
              disabled
            >
              <Clipboard />
            </SpButton>
          </span>
        );
      },
    },
    {
      title: 'ACTION',
      dataIndex: 'action',
      width: '10%',
      align: 'left',
      render: (text, record) => (
        <>
          <Tooltip placement="top" title="Notify">
            <span
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); selectedNotify(record); }}
            >
              <img
                src={ResendMail}
                alt="notify"
                style={{
                  height: '50px',
                  marginTop: '5px',
                }}
              />
            </span>
          </Tooltip>
        </>
      ),
    },
  ];

  const downloadQRCode = async () => {
    const qrCode = qrCodeRef.current.canvas.current;
    if (!qrCode) {
      console.error('QR code element not found.');
      return;
    }
    try {
      const dataUrl = qrCode.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'qrcode.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setNotification({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.QR_Download,
      });
    } catch (error) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: 'Error capturing QR code',
      });
    }
  };

  const OfflinePayment = (data) => {
    const payload = {
      gid: eventDetails.seatPageLinks[0].gid,
      params: data,
    };
    offlinePayments(payload);
    setOfflinePaymentModal(false);
  };

  const cloneSeatedPage = () => {
    history.push(`${PAGES.ADDSEATEDPAGE}/${gid}`);
    clearEventDetails(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        eventDetails.gid && eventDetailsStatus === API_STATUS.SUCCESS && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col className="d-flex align-items-center">
                  <SpText className="mr-5" fontSize="20px" fontWeight="600">
                    {eventDetails.title}
                  </SpText>
                  <SpStatusTag style={{ backgroundColor: getEventsBackground(eventDetails.status) }}>
                    {getEventsStatus(eventDetails.status)}
                  </SpStatusTag>
                </Col>
              </Row>
              <Row type="flex" justify="end" className="my-3 mb-3">
                <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                  <Col className="mt-2">
                    {
                      eventDetails.supportOfflinePayment && (
                        <SpButton
                          className="mr-2"
                          type="secondary"
                          shape="round"
                          onClick={() => setOfflinePaymentModal(true)}
                          ghost
                          disabled={eventDetails.status !== 'ACTIVE'}
                        >
                          Offline Payment
                        </SpButton>
                      )
                    }
                    <SpButton
                      type="secondary"
                      shape="round"
                      className="mr-2"
                      onClick={() => cloneSeatedPage()}
                      disabled={!eventDetails.seatEventId}
                    >
                      Clone Seated Event Page
                    </SpButton>
                    <SpButton
                      type="secondary"
                      shape="round"
                      className="mr-2"
                      disabled={eventDetails.status !== 'SOLD_OUT'}
                      onClick={() => resumeSales(gid)}
                    >
                      Resume Sales
                    </SpButton>
                    <SpButton
                      type="secondary"
                      shape="round"
                      className="mr-2"
                      disabled={eventDetails.status !== 'ACTIVE'}
                      onClick={() => soldOutEvent(gid)}
                    >
                      Sold Out
                    </SpButton>
                    <SpButton
                      type="danger"
                      shape="round"
                      disabled={eventDetails.status !== 'ACTIVE'}
                      onClick={() => deactivateEvent(gid)}
                    >
                      Deactive
                    </SpButton>
                    {/* <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                      <SpButton
                        className="ml-2"
                        type="secondary"
                        shape="round"
                        onClick={() => setNotifySeatedPageModal(true)}
                        ghost
                      >
                        Notify
                      </SpButton>
                    </RBAC> */}
                  </Col>
                </RBAC>
              </Row>
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col span={18}>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={4}>
                        <span>Created On</span>
                      </Col>
                      <Col span={12}>
                        <SpH5>{
                          eventDetails.createdAt && selectedAccount && selectedAccount.timezone
                            ? (
                              moment
                                .utc(eventDetails.createdAt)
                                .tz(selectedAccount.timezone)
                                .format('MMM DD, YYYY')
                            )
                            : <>&#8211;</>
                        }
                        </SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={4}>
                        <span>Link</span>
                      </Col>
                      <Col span={18}>
                        {eventDetails.seatPageLinks && eventDetails.seatPageLinks[0]
                          && eventDetails.seatPageLinks[0].link
                          ? (
                            <SpH5>{eventDetails.seatPageLinks[0].link}
                              <SpButton
                                type="secondary"
                                className="ml-2"
                                borderRadius="50px"
                                disabled={eventDetails.status !== 'ACTIVE'}
                                onClick={() => { copyCode(eventDetails.seatPageLinks[0].link); }}
                              >
                                <Clipboard />
                              </SpButton>
                            </SpH5>
                          )
                          : (
                            <>&#8211;</>
                          )}
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={4}>
                        <span>Total Online Payments</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>{eventDetails.totalSales}</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                  <SpForm>
                    <Row type="flex" justify="start" align="middle">
                      <Col span={4}>
                        <span>Total Online Revenue</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>{selectedAccount.currency.prefix} {formatNumber(eventDetails.totalRevenue / 100, currencyType).replace(/-/g, '')}</SpH5>
                      </Col>
                    </Row>
                  </SpForm>
                </Col>
                <Col span={6} style={{ textAlign: 'center' }}>
                  <QRCode
                    size="256"
                    value={eventDetails.seatPageLinks && eventDetails.seatPageLinks[0] && eventDetails.seatPageLinks[0].link}
                    fgColor="#000000"
                    ref={qrCodeRef}
                    logoPadding={5}
                    removeQrCodeBehindLogo
                    logoImage={Qrlogo}
                  />
                  <div className="mt-4">
                    <SpButton
                      type="secondary"
                      shape="round"
                      disabled={eventDetails.status !== 'ACTIVE'}
                      onClick={() => downloadQRCode()}
                    >
                      <Icon type="download" />
                    </SpButton>
                  </div>
                </Col>
              </Row>
              <SpForm>
                {
                  ((accountSlug.length > 0)) ? (
                    <>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={6}>
                          <SpText className="mr-3" fontSize="14px" fontWeight="600">
                            Link Account Slug
                          </SpText>
                          <Select
                            className="mt-2 w-100"
                            showSearch
                            disabled={enableUnlink}
                            value={slugLink || 'Select slug'}
                            onChange={(value) => {
                              setSlugGid(value[0]);
                              setSlugLink(value[1]);
                            }}
                          >
                            {
                              accountSlug.map(slug => (
                                <Option
                                  key={1}
                                  value={[slug.gid, slug.slugName]}
                                >
                                  {slug.slugName}
                                </Option>
                              ))
                            }
                          </Select>
                        </Col>
                      </Row>
                      <Row className="mt-4" type="flex" justify="start" align="middle">
                        <Col span={3}>
                          {
                            enableUnlink ? (
                              <SpButton
                                type="primary"
                                shape="round"
                                onClick={unlinkSlug}
                              >
                                Unlink
                              </SpButton>
                            ) : slugLink ? (
                              <SpButton
                                type="primary"
                                shape="round"
                                onClick={linkSlug}
                              >
                                Link
                              </SpButton>
                            ) : (
                              <SpButton
                                type="primary"
                                shape="round"
                                disabled
                              >
                                Link
                              </SpButton>
                            )
                          }

                        </Col>
                      </Row>
                    </>
                  ) : ''
                }
              </SpForm>
              <SpForm>
                <Row>
                  <Col span={24} className="d-flex justify-content-end">
                    <SpH5 color="#7f7f7f">{eventDetails.gid}</SpH5>
                  </Col>
                </Row>
              </SpForm>
            </Card>
            <Card className="pl-2">
              {/* NOTIFY PAYMENTPAGE MODAL
              {notifySeatedPageModal && (
                <NotifySeatedPage
                  visible={notifySeatedPageModal}
                  submitting={submitting}
                  phoneCode={phoneCode}
                  selectedAccount={selectedAccount}
                  id={id}
                  paymentPagesDetails={eventDetails}
                  close={() => setNotifySeatedPageModal(false)}
                  submit={notifyPayment}
                />
              )}
              */}
              {/* CHEQ PAYMENTPAGE MODAL */}
              {offlinePaymentModal && (
                <OfflinePaymentPage
                  visible={offlinePaymentModal}
                  submitting={submitting}
                  phoneCode={phoneCode}
                  selectedAccount={selectedAccount}
                  id={id}
                  paymentPagesDetails={eventDetails}
                  close={() => setOfflinePaymentModal(false)}
                  nolayout={!eventDetails.seatEventId}
                  submit={OfflinePayment}
                />
              )}
              <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
                <Menu.Item key="payments">
                  <SpTab
                    to={`${path}?status.IN=PAYMENTS`}
                    selected={current === 'payments'}
                  >
                    PAYMENTS
                  </SpTab>
                </Menu.Item>
                <Menu.Item key="offlineData">
                  <SpTab
                    to={`${path}?status.IN=OFFLINEPAYMENTS`}
                    selected={current === 'offlineData'}
                  >
                    OFFLINE PAYMENTS
                  </SpTab>
                </Menu.Item>
              </Menu>
              {
                current === 'payments' ? (
                  <>
                    <Table
                      onRow={(record) => ({
                        onClick: () => {
                          selectedRecord(record);
                        },
                      })}
                      columns={columns}
                      rowKey={(record) => record.gid}
                      dataSource={cardsDetails}
                      pagination={pagination}
                      onChange={handleTableChange}
                    />
                  </>
                ) : (
                  <Table
                    columns={offlineOrderColumns}
                    rowKey={(record) => record.gid}
                    dataSource={offlineEventPayments}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                )
              }
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  eventDetails: state.eventDetails,
  eventDetailsStatus: state.eventDetails.eventDetailsStatus,
  cardsDetails: state.eventDetails.cardDetails.content,
  totalElements: state.eventDetails.cardDetails ? state.eventDetails.cardDetails.totalElements : '',
  loading: state.loading.loading,
  paymentSlug: state.eventDetails.paymentSlug,
  accountSlug: state.accountSlug.content,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  offlineEventPayments: state.eventDetails.offlineEventPayments.content,
  totalofflineElements: state.eventDetails.offlineEventPayments ? state.eventDetails.offlineEventPayments.totalElements : '',
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchEventDetails: gid => dispatch(eventDetailsAction.fetchEventDetails({
    payload: gid,
  })),
  fetchCardDetails: param => dispatch(eventDetailsAction.fetchCardDetails({
    payload: param,
  })),
  updateEvents: param => dispatch(eventDetailsAction.updateEvents({
    payload: param,
  })),
  fetchEventsSlug: param => dispatch(eventDetailsAction.fetchEventsSlug({
    payload: param,
  })),
  fetchAccountSlug: param => dispatch(accountSlugActions.fetchAccountSlug({
    payload: param,
  })),
  linkAccountSlug: param => dispatch(accountSlugActions.linkAccountSlug({
    payload: param,
  })),
  unLinkAccountSlug: param => dispatch(accountSlugActions.unLinkAccountSlug({
    payload: param,
  })),
  deactivateEvent: param => dispatch(eventDetailsAction.deactivateEvent({
    payload: param,
  })),
  clearEventDetails: param => dispatch(eventDetailsAction.clearEventDetails({
    payload: param,
  })),
  soldOutEvent: param => dispatch(eventDetailsAction.soldOutEvent({
    payload: param,
  })),
  resumeSales: param => dispatch(eventDetailsAction.resumeSales({
    payload: param,
  })),
  offlinePayments: param => dispatch(eventDetailsAction.offlinePayments({
    payload: param,
  })),
  fetchOfflineEventsPayments: param => dispatch(eventDetailsAction.fetchOfflineEventsPayments({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  notifySeatedPageDetails: gid => dispatch(eventDetailsAction.notifySeatedPageDetails({
    payload: gid,
  })),
  // notifySeatedPage: param => dispatch(eventDetailsAction.notifySeatedPage({
  //   payload: param,
  // })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(EventsDetails);
