/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Col, Hidden } from 'react-awesome-styled-grid';
import { useForm, useWatch } from 'react-hook-form';
import AsYouType from 'libphonenumber-js';
import { DescriptionText, SubmitButton } from '../../../design';
import InputEmail from './InputEmail';
import InputPhone from './InputPhone';
import PaymentPageSupport from './PaymentPageSupport';
import CardIconList from './CardIconList';
import SmallScreenView from '../../../components/smallscreen';
import ProductDetails from './ProductDetails';
import Account from './Account';
import InputFixedAmount from './InputFixedAmount';
import InputQuantity from './InputQuantity';
import InputAdjustableAmount from './InputAdjustableAmount';
import InputNumber from './InputNumber';
import InputAlpha from './InputAlpha';
import InputAlphaNumeric from './InputAlphaNumeric';
import InputPromotionCode from './InputPromotionCode';
import InputSingleLineText from './InputSingleLineText';
import InputLargeText from './InputLargeText';
import InputFileUpload from './InputFileUpload'
import InputTip from './InputTip';
import ComboBox from './ComboBox';
import { h2FontSize, submitFontSize, submitHeight } from '../../../utils/reseponsive';
import currencyFormatter from '../../../utils/currencyformatter';
import { AmountDivideByHundred } from '../../../helper';
import { COUNTRY_PHONE_CODE } from '../../../appconstants';
import Waiver from './Waiver';


type Props = {
  paymentPageMeta: Object,
  onSubmit: Function,
  account: Object,
  phoneCode: String,
  isPreview: boolean,
  isAdaptive: boolean,
  paymentHeading: String,
}

const PaymentPageDetails = (props: Props) => {
  const {
    paymentPageMeta, onSubmit, account, isPreview, phoneCode, isAdaptive, paymentHeading,
  } = props;
  const [paymentPageMetaFields, setPaymentPageMetaFields] = useState([]);
  const [amount, setAmount] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [currency, setCurrency] = useState(null);
  const [submitBtnText, setSubmitBtnText] = useState('PAY NOW');
  const soldOut = (paymentPageMeta != null
    // eslint-disable-next-line max-len
    && (paymentPageMeta.gid === 'paymentpagemeta-0bfe7010378a4cd7b95d0d6f7dd52aba' || paymentPageMeta.gid === 'paymentpagemeta-73d3c3b9e4034c9c92290dc808a15b85'))
    ? true : !!((paymentPageMeta != null && (paymentPageMeta.status === 'SOLD_OUT')));
  const {
    control,
    handleSubmit,
    errors,
    setValue,
    getValues,
  } = useForm();


  const watch = useWatch({ control });

  const [filterObject, setFilterObject] = useState(null);

  useEffect(() => {
    setPaymentPageMetaFields(paymentPageMeta && paymentPageMeta.paymentPageMetaFields);
  }, [paymentPageMeta]);

  const generateData = () => {
    const data = getValues();
    let initAmount = 0;
    let localCurrency = null;
    const paymentPageMetaFieldTypes = ['PRICE_AMOUNT_FIXED', 'PRICE_AMOUNT_CUSTOMER_DECIDED', 'PRICE_AMOUNT_QUANTITY_BASED'];
    // eslint-disable-next-line array-callback-return
    paymentPageMeta && paymentPageMeta.paymentPageMetaFields && paymentPageMeta.paymentPageMetaFields.map(meta => {
      // eslint-disable-next-line no-shadow
      let amount = 0;
      let quantity = 0;
      if (paymentPageMetaFieldTypes.includes(meta.type) && meta.currency && !localCurrency) {
        localCurrency = meta.currency;
      }
      if (meta.type === 'PRICE_AMOUNT_QUANTITY_BASED') {
        // eslint-disable-next-line prefer-destructuring
        amount = meta.amount;
        // eslint-disable-next-line radix
        quantity = parseInt(data[meta.gid]);
        if (amount && quantity) { initAmount += parseFloat(amount * quantity); }
      }
      if (meta.type === 'PRICE_AMOUNT_CUSTOMER_DECIDED') {
        amount = (data[meta.gid] && parseFloat(data[meta.gid]) * 100);
        if (amount) {
          initAmount += parseFloat(amount);
        }
      }
      if (meta.type === 'PRICE_AMOUNT_FIXED' && data[meta.gid]) {
        // eslint-disable-next-line prefer-destructuring
        amount = meta.amount;
        if (amount) { initAmount += parseFloat(amount); }
      }
    });
    setAmount(initAmount);
    setCurrency(localCurrency);
    setSubmitBtnText(
      initAmount
      && localCurrency
        ? `PAY NOW ${currencyFormatter(AmountDivideByHundred(initAmount), localCurrency && localCurrency.name)}` : 'PAY NOW',
    );
  };

  useEffect(() => {
    if (control.getValues().Combo) {
      setFilterObject(control.getValues().Combo);
    }
    generateData();
  }, [watch]);

  const findCountryCallingCode = (value) => (
    value && COUNTRY_PHONE_CODE.includes(value) && { value }
  );

  // eslint-disable-next-line consistent-return
  const getPaymentComponent = (data, index) => {
    const name = data.gid || index;
    const email = paymentPageMeta && paymentPageMeta.contactUsEmail;
    const phoneNum = paymentPageMeta && paymentPageMeta.contactUsPhone && AsYouType(paymentPageMeta.contactUsPhone);
    // eslint-disable-next-line no-mixed-operators
    const phone = phoneNum && phoneNum.nationalNumber || '';
    const countryCode = phoneNum && `+${phoneNum.countryCallingCode}`;
    // eslint-disable-next-line no-mixed-operators
    const countryCallingCode = countryCode && findCountryCallingCode(countryCode) || '';
    // eslint-disable-next-line default-case
    switch (data.type) {
      case 'CONSENT_FORM':
        return <Waiver data={data} header={data.label} description={data.description} docurl={data.documentUrl} />;
      case 'INPUT_EMAIL':
        return (
          <InputEmail
            isPreview={isPreview}
            isAdaptive={isAdaptive}
            control={control}
            errors={errors}
            isRequired={data.isRequired}
            label={data.label}
            description={data.description}
            name={`${name}`}
            email={email}
          />
        );
      case 'FILE_UPLOAD':
        return (
          <InputFileUpload
            label={data.label}
            description={data.description}
            quantityMaxLimit={data.quantityMaxLimit}
          />
        );
      case 'INPUT_PHONE':
        return (
          <InputPhone
            isPreview={isPreview}
            isAdaptive={isAdaptive}
            control={control}
            errors={errors}
            label={data.label}
            description={data.description}
            isRequired={data.isRequired}
            countryCallingCode={phoneCode}
            name={`${name}`}
            phone={phone}
          />
        );
      case 'INPUT_NUMBER':
        return (
          <InputNumber
            isPreview={isPreview}
            isAdaptive={isAdaptive}
            control={control}
            errors={errors}
            label={data.label}
            description={data.description}
            isRequired={data.isRequired}
            name={`${name}`}
          />
        );
      case 'INPUT_ALPHA':
        return (
          <InputAlpha
            isPreview={isPreview}
            isAdaptive={isAdaptive}
            control={control}
            errors={errors}
            label={data.label}
            description={data.description}
            isRequired={data.isRequired}
            name={`${name}`}
          />
        );
      case 'INPUT_ALPHA_NUMERIC':
        return (
          <InputAlphaNumeric
            isPreview={isPreview}
            isAdaptive={isAdaptive}
            control={control}
            errors={errors}
            label={data.label}
            description={data.description}
            isRequired={data.isRequired}
            name={`${name}`}
          />
        );
      case 'PROMOTIONAL_CODES':
        return (
          <InputPromotionCode
            isPreview={isPreview}
            isAdaptive={isAdaptive}
            control={control}
            errors={errors}
            label={data.label}
            description={data.description}
            isRequired={data.isRequired}
            name={`${name}`}
          />
        );
      case 'INPUT_SINGLE_LINE_TEXT':
        return (
          <InputSingleLineText
            isPreview={isPreview}
            isAdaptive={isAdaptive}
            control={control}
            errors={errors}
            label={data.label}
            description={data.description}
            isRequired={data.isRequired}
            name={`${name}`}
          />
        );
      case 'INPUT_LARGE_TEXT':
        return (
          <InputLargeText
            isPreview={isPreview}
            isAdaptive={isAdaptive}
            control={control}
            errors={errors}
            label={data.label}
            description={data.description}
            isRequired={data.isRequired}
            name={`${name}`}
          />
        );
      case 'PRICE_AMOUNT_FIXED':
        return (
          <InputFixedAmount
            label={data.label}
            setValue={setValue}
            isPreview={isPreview}
            isAdaptive={isAdaptive}
            control={control}
            errors={errors}
            description={data.description}
            isRequired={data.isRequired}
            name={`${name}`}
            options={data.comboBox}
            amount={data.amount}
            currencyName={data.currency && data.currency.name}
          />
        );
      case 'PRICE_AMOUNT_CUSTOMER_DECIDED':
        return (
          <InputAdjustableAmount
            label={data.label}
            isPreview={isPreview}
            isAdaptive={isAdaptive}
            control={control}
            errors={errors}
            description={data.description}
            isRequired={data.isRequired}
            name={`${name}`}
            amount={data.amount}
            currencyPrefix={data.currency && data.currency.prefix}
            maxAmount={data.maxPrice}
            minAmount={data.minPrice}
          />
        );
      case 'REFERENCE_NUMBER':
        return (
          <InputAlphaNumeric
            isPreview={isPreview}
            isAdaptive={isAdaptive}
            control={control}
            errors={errors}
            label={data.label}
            description={data.description}
            isRequired={data.isRequired}
            name={`${name}`}
          />
        );
      case 'TIP':
        return (
          <InputTip
            isPreview={isPreview}
            isAdaptive={isAdaptive}
            setValue={setValue}
            control={control}
            errors={errors}
            tipPercentages={data.tipPercentages}
            label={data.label}
            description={data.description}
            isRequired={data.isRequired}
            name={`${name}`}
          />
        );
      case 'COMBO_BOX':
        return (
          <ComboBox
            isPreview={isPreview}
            isAdaptive={isAdaptive}
            control={control}
            errors={errors}
            label={data.label}
            description={data.description}
            isRequired={data.isRequired}
            options={data.comboBox}
            name="Combo"
            filterObject={filterObject}
          />
        );
      case 'PRICE_AMOUNT_QUANTITY_BASED':
        return (
          <InputQuantity
            label={data.label}
            setValue={setValue}
            isPreview={isPreview}
            isAdaptive={isAdaptive}
            control={control}
            errors={errors}
            description={data.description}
            isRequired={data.isRequired}
            name={`${name}`}
            amount={data.amount}
            options={data.comboBox || []}
            id={data.gid}
            currencyName={data.currency && data.currency.name}
            maxQty={data.quantityMaxLimit}
            minQty={data.quantityMinLimit}
          />
        );
    }
  };

  return (
    <div id="payment-page">
      {
        soldOut
          ? <div className="sold_out">Sold out</div> : null
      }
      <Col
        md={5}
        sm={8}
        lg={5}
        className="mt-md-5 mb-2 p-4 payment-page-right"
        style={{
          position: 'absolute', marginLeft: '55%', boxShadow: 'rgba(0, 0, 0, 0.1) 0px 0px 15px 0px', backgroundColor: '#fff',
        }}
      >
        <SmallScreenView>
          {account
          && (
          <Account
            primaryColor={paymentPageMeta && paymentPageMeta.primaryColor}
            isPreview={isPreview}
            isAdaptive={isAdaptive}
            name={account.name}
            logo={account.largeLogo}
          />
          )}
          {paymentPageMeta && (
          <ProductDetails
            isPreview={isPreview}
            isAdaptive={isAdaptive}
            primaryColor={paymentPageMeta && paymentPageMeta.primaryColor}
            content={paymentPageMeta.content}
            imageUrl={paymentPageMeta.logo}
            title={paymentPageMeta.title}
            venueAddress={paymentPageMeta.venueAddress}
            coordinate={paymentPageMeta.coordinate}
            ytVideoId={paymentPageMeta.ytVideoId}
            ytVideoDescription={paymentPageMeta.ytVideoDescription}
            ytVideoHeading={paymentPageMeta.ytVideoHeading}
          />
          )}
        </SmallScreenView>
        <div className="pt-3 s">
          <DescriptionText fontSize={h2FontSize(isPreview, isAdaptive)} color="#474747">{paymentHeading || 'PAYMENT DETAILS'}</DescriptionText>
        </div>
        <form onSubmit={(e) => { e.preventDefault(); handleSubmit(onSubmit)(e); }}>
          <div className="payment-page-form-elements py-2 pr-2">
            {
              phoneCode
              && paymentPageMetaFields && paymentPageMetaFields.sort((a, b) => (a.sequenceNumber > b.sequenceNumber ? 1 : -1)).map((value, index) => (
                <div className="mt-3" key={index}>
                  {getPaymentComponent(value, index)}
                </div>
              ))
            }

          </div>
          {
            paymentPageMeta && (
              <div className="pt-4 text-center">
                {
                  (amount === 0) ? (
                    <SubmitButton
                      disabled
                      backgroundColor={paymentPageMeta && paymentPageMeta.primaryColor ? paymentPageMeta.primaryColor : '#40A9FF'}
                      hoverBackgroundColor={paymentPageMeta && paymentPageMeta.primaryColor ? paymentPageMeta.primaryColor : '#40A9FF'}
                      height={submitHeight(42, isAdaptive)}
                      className="m-0"
                      fontSize={submitFontSize(isAdaptive)}
                      type="submit"
                    >
                      {soldOut ? 'Sold Out' : submitBtnText}
                    </SubmitButton>
                  ) : (
                    <SubmitButton
                      backgroundColor={paymentPageMeta && paymentPageMeta.primaryColor ? paymentPageMeta.primaryColor : '#40A9FF'}
                      hoverBackgroundColor={paymentPageMeta && paymentPageMeta.primaryColor ? paymentPageMeta.primaryColor : '#40A9FF'}
                      disabled={soldOut ? true : isPreview}
                      height={submitHeight(42, isPreview, isAdaptive)}
                      className="m-0"
                      fontSize={submitFontSize(isPreview, isAdaptive)}
                      type="submit"
                    >
                      {soldOut ? 'Sold Out' : submitBtnText}
                    </SubmitButton>
                  )
                }
                <CardIconList />
              </div>
            )
          }
        </form>
        <SmallScreenView>
          {paymentPageMeta
          && (
          <PaymentPageSupport
            primaryColor={paymentPageMeta && paymentPageMeta.primaryColor}
            email={paymentPageMeta.contactUsEmail}
            termsAndCondition={paymentPageMeta.termsAndConditions}
            phone={paymentPageMeta.contactUsPhone}
            isPreview={isPreview}
            isAdaptive={isAdaptive}
          />
          )}
        </SmallScreenView>
      </Col>
      <Hidden xs sm>
        <Col
          md={6}
          className="p-4 bg-white"
          style={{
            boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)', borderRadius: '10px', minHeight: '100%', height: '100%', overflow: 'hidden auto',
          }}
        >
          <div className="p-3 col-12 col-lg-9" style={{ minHeight: '100%' }}>
            {account
            && (
            <Account
              primaryColor={paymentPageMeta && paymentPageMeta.primaryColor}
              isPreview={isPreview}
              isAdaptive={isAdaptive}
              name={account.name}
              logo={account.largeLogo}
            />
            )}
            {paymentPageMeta && (
            <ProductDetails
              isPreview={isPreview}
              isAdaptive={isAdaptive}
              content={paymentPageMeta.content}
              imageUrl={paymentPageMeta.logo}
              title={paymentPageMeta.title}
              primaryColor={paymentPageMeta && paymentPageMeta.primaryColor}
              venueAddress={paymentPageMeta.venueAddress}
              coordinate={paymentPageMeta.coordinate}
              ytVideoId={paymentPageMeta.ytVideoId}
              ytVideoDescription={paymentPageMeta.ytVideoDescription}
              ytVideoHeading={paymentPageMeta.ytVideoHeading}
            />
            )}
            {paymentPageMeta && (
            <PaymentPageSupport
              isPreview={isPreview}
              isAdaptive={isAdaptive}
              email={paymentPageMeta.contactUsEmail}
              phone={paymentPageMeta.contactUsPhone}
              termsAndCondition={paymentPageMeta.termsAndConditions}
              primaryColor={paymentPageMeta && paymentPageMeta.primaryColor}
              agregatorName={account && account.aggregatorName}
            />
            )}
          </div>
        </Col>
      </Hidden>
    </div>
  );
};

export default PaymentPageDetails;
