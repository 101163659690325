import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Collapse,
  Row,
  Col,
} from 'antd';
import {
  SpButton,
} from 'components/DesignKit';
import { CaretRightOutlined } from '@ant-design/icons';
import SimpleReactValidator from 'simple-react-validator';
import {
  acquirerActions,
} from 'store/actions';
import {
  ACQUIRER,
} from 'store/actionTypes';
import {
  ACQUIRER_TYPES,
} from 'appconstants';

import getUniquePaymentTypes from 'utils/getUniquePaymentTypes';

import Acquirer from './components/Acquirer';
import Mdr from './components/Mdr';
import Issuer from './components/Issuer';
import SupportedCurrency from './components/SupportedCurrency';
import SupportedOfflinePayments from './components/SupportedOfflinePayments';
import SupportedPayments from './components/SupportedPayments';
import SupportedRecurringPayments from './components/SupportedRecurringPayments';
import PartnerImages from './components/PartnerImages';
import UpdateChanges from './components/UpdateChanges';
import CommissionRate from './components/CommissionRate';
import ServerCallBack from './components/ServerCallBack';
import PaymentOrder from './components/PaymentOrder';
import SupportedBins from './components/SupportedBins';

const { Panel } = Collapse;

type Props = {
  internalAccount: Object,
  updateInternalAccount: Function,
  fetchAcquirerSearch: Function,
  acquirer: Object,
  issuer: Object,
  acquirerResult: Object,
  paymentType: Object,
  setUpdate: Function,
  test: boolean,
  selectedAccount: Object,
};

const UpdateMerchant = (props: Props) => {
  const {
    internalAccount,
    fetchAcquirerSearch,
    acquirer,
    issuer,
    acquirerResult,
    paymentType,
    test,
    selectedAccount,
  } = props;

  const acquirerName = (internalAccount && internalAccount.acquirer)
    ? (internalAccount.acquirer && internalAccount.acquirer.name)
    : '';
  const [isRecurringPaymentValidate, setIsRecurringPaymentValidate] = useState(true);
  const [selectedPanel, setSelectedPanel] = useState();
  const [activePanel, setActivePanel] = useState();
  const [acquirerList, setAcquirerList] = useState(acquirer);
  const prefix = (internalAccount
    ? (internalAccount.currency && internalAccount.currency.prefix)
    : (selectedAccount.currency && selectedAccount.currency.prefix));
  const currencyType = (internalAccount
    ? (internalAccount.currency)
    : (selectedAccount.currency));
  const [popUp, setPopUp] = useState(false);
  const [updateChangesModal, setUpdateChangesModal] = useState(false);

  const acquirerData = {
    acquirerList,
  };

  const simpleValidator = useRef(new SimpleReactValidator());

  simpleValidator.current.purgeFields();

  useEffect(() => {
    if ((acquirerName === ACQUIRER_TYPES.IMS) || (acquirerName === ACQUIRER_TYPES.IMSPOS)) {
      setIsRecurringPaymentValidate(false);
    } else setIsRecurringPaymentValidate(true);
  }, []);

  function callback(key) {
    setActivePanel(key[0]);
    if (key.length > 1) {
      if (popUp === true) {
        setUpdateChangesModal(true);
        setSelectedPanel(key);
      } else {
        const newKey = new Array(key.at(-1));
        setActivePanel(newKey);
      }
    } else setActivePanel(key[0]);
  }

  const notNow = (data) => {
    const newKey = new Array(data.at(-1));
    setActivePanel(newKey);
    setPopUp(false);
    setUpdateChangesModal(false);
  };

  const openModal = (data) => {
    setPopUp(data);
  };

  const updateChanges = (data) => {
    setActivePanel(data[0]);
    setUpdateChangesModal(false);
  };

  useEffect(() => {
    setAcquirerList(acquirerResult || acquirerList);
  }, [acquirerResult]);


  const fetchAcquirerDetails = (search) => {
    fetchAcquirerSearch(search);
  };

  const updateAcquirer = (Info: Object) => {
    const {
      acquirername,
      acquirerId,
      timeZone,
      publicKey,
      privateKey,
      cashAppID,
      secretKey,
      notify,
      subMerchantId,
      commerceSubMerchantId,
      acquirerStatus,
      classificationId,
      subMerchantDetails,
      encryptionKey,
      encryptionIv,
      pageId,
      atomPassword,
      atomProductId,
      atomAccountNumber,
      billDeskId,
      gpayMerchantId,
      achMerchantId,
      ipgApiKey,
      ipgSecretKey,
      ipgSharedSecretKey,
      schemaCode,
      encryptionCode,
      encryptionIV,
      usPooledSubmerchant,
      workingKey,
      accessCode,
    } = Info;
    let MerchantDetails = {};
    if (acquirername === ACQUIRER_TYPES.AFFINIPAY) {
      MerchantDetails = {
        AFFINIPAY_PUBLIC_KEY: publicKey,
        AFFINIPAY_PRIVATE_KEY: privateKey,
      };
    } else if ((acquirername === ACQUIRER_TYPES.COMMERCECONNECT)
      || (acquirername === ACQUIRER_TYPES.COMMERCECONNECTV2)
    ) {
      MerchantDetails = {
        COMMERCE_CONNECT_ENCRYPTION_KEY: encryptionKey,
        COMMERCE_CONNECT_ENCRYPTION_IV: encryptionIv,
        COMMERCE_CONNECT_PAGE_ID: pageId,
      };
    } else if (acquirername === ACQUIRER_TYPES.IPGCOMMERCECONNECT) {
      MerchantDetails = {
        IPG_API_KEY: ipgApiKey,
        IPG_API_SECRET: ipgSecretKey,
        IPG_API_SHARED_SECRET: ipgSharedSecretKey,
        COMMERCE_CONNECT_ENCRYPTION_KEY: encryptionKey,
        COMMERCE_CONNECT_ENCRYPTION_IV: encryptionIv,
        COMMERCE_CONNECT_PAGE_ID: pageId,
        COMMERCE_CONNECT_SUB_MERCHANT_ID: commerceSubMerchantId,
      };
    } else if ((acquirername === ACQUIRER_TYPES.CASHFREE)
      || (acquirername === ACQUIRER_TYPES.CASHFREEUSPOOL)
      || (acquirername === ACQUIRER_TYPES.INCCFUCF)) {
      MerchantDetails = {
        CASHFREE_APP_ID: cashAppID,
        CASHFREE_SECRET_KEY: secretKey,
      };
    } else if (acquirername === ACQUIRER_TYPES.ATOM) {
      MerchantDetails = {
        ATOM_PASSWORD: atomPassword,
        ATOM_ACCOUNT_NO: atomAccountNumber,
        ATOM_PRODUCT_ID: atomProductId,
      };
    } else if (acquirername === ACQUIRER_TYPES.BILLDESK) {
      MerchantDetails = {
        BILLDESK_CLIENT_ID: billDeskId,
      };
    } else if (acquirername === ACQUIRER_TYPES.CARDPOINTE || acquirername === ACQUIRER_TYPES.CLOVERPOS) {
      MerchantDetails = {
        GOOGLE_PAY_MERCHANT_ID: gpayMerchantId,
        ACH_MERCHANT_ID: achMerchantId,
      };
    } else if (acquirername === ACQUIRER_TYPES.IPG) {
      MerchantDetails = {
        IPG_API_KEY: ipgApiKey,
        IPG_API_SECRET: ipgSecretKey,
        IPG_API_SHARED_SECRET: ipgSharedSecretKey,
      };
    } else if (acquirername === ACQUIRER_TYPES.WORLDLINE) {
      MerchantDetails = {
        SCHEME_CODE: schemaCode,
        ENCRYPTION_CODE: encryptionCode,
        ENCRYPTION_IV: encryptionIV,
      };
    } else if (acquirername === ACQUIRER_TYPES.INCWLCSTNWLUWL) {
      MerchantDetails = {
        SCHEME_CODE: schemaCode,
        ENCRYPTION_CODE: encryptionCode,
        ENCRYPTION_IV: encryptionIV,
        US_POOLED_SUB_MERCHANT: usPooledSubmerchant,
      };
    } else if (acquirername === ACQUIRER_TYPES.CCAVENUE) {
      MerchantDetails = {
        ACCESS_CODE: accessCode,
        WORKING_KEY: workingKey,
      };
    } else if ((acquirername !== ACQUIRER_TYPES.IMSPOS) && (acquirername !== ACQUIRER_TYPES.STRIPE)) {
      MerchantDetails = {
        IMS_SHARED_SECRET: subMerchantDetails,
      };
    }
    const payload = {
      acquirerId,
      subMerchantId,
      active: notify,
      status: acquirerStatus,
      payoutTimezone: timeZone,
      subMerchantDetails: MerchantDetails,
      dwollaBusinessClassificationId: classificationId,
    };
    props.updateInternalAccount(payload);
  };

  const updateMdr = (Info: Object) => {
    const {
      mdrName,
    } = Info;
    const payload = {
      mdr: mdrName,
    };
    props.updateInternalAccount(payload);
  };

  const updateServerCallBack = (Info: Object) => {
    const {
      fundingType,
      reconciliationEnabled,
      encryptServerCallBack,
      isWhitelabel,
      aggregatorName,
      tipEnabled,
      legacySubscription,
      plaidStatus,
      businessType,
      organisationType,
      settlementCurrency,
      transactionLimit,
      walletAcquirer,
      walletReferenceId,
      enableWoocommerceOrder,
      paymentPageOrderFcmType,
      collectCustomerAddress,
      collectBillingAddress,
      collectShippingAddress,
    } = Info;
    const amount = transactionLimit ? transactionLimit.replace(/,/g, '') : '';
    const payload = {
      fundingType,
      aggregatorName,
      whiteLabel: isWhitelabel,
      reconciliationEnabled,
      encryptServerCallBack,
      paymentPageOrderFcmType,
      tipEnabled,
      legacySubscription,
      plaidStatus,
      businessType,
      organisationType,
      collectCustomerAddress,
      collectBillingAddress,
      collectShippingAddress,
      maxTransactionLimit: amount ? Number(amount * 100) : null,
      defaultSettlementCurrencyCode: settlementCurrency,
      walletReferenceId,
      walletAcquirer,
      enableWoocommerceOrder,
    };
    props.updateInternalAccount(payload);
  };

  const updateIssuer = (Info: Object) => {
    const {
      activeIssuer,
      issuerStatus,
      dwollaStatus,
      issuerType,
      issuerID,
      cashFreeStatus,
    } = Info;
    const payload = {
      issuerActive: activeIssuer,
      issuerId: issuerID,
    };
    if (internalAccount.dwollaCustomerStatus) {
      payload.issuerId = 3;
      payload.dwollaCustomerStatus = dwollaStatus;
    }
    if (internalAccount.iciciCustomerStatus) {
      payload.issuerId = 4;
      payload.iciciCustomerStatus = issuerStatus;
    }
    if (issuerType === 'Cashfree') {
      payload.issuerId = 5;
      payload.cashfreeCustomerStatus = cashFreeStatus;
    }
    props.updateInternalAccount(payload);
  };

  const updatePartnerImages = (Info: Object) => {
    const {
      bankImageUrl,
      partnerImageUrl,
    } = Info;
    const payload = {
      bankImageUrl: bankImageUrl || null,
      partnerImageUrl: partnerImageUrl || null,
    };
    props.updateInternalAccount(payload);
  };

  const updatePartnerCommission = (Info: Object) => {
    const {
      commissionRate,
    } = Info;
    const payload = {
      partnerCommissionRate: commissionRate * 100,
    };
    props.updateInternalAccount(payload);
  };

  const updateCurrency = (Info: Object) => {
    const {
      currencyData,
    } = Info;
    const payload = {
      supportedCurrencies: currencyData,
    };
    props.updateInternalAccount(payload);
  };

  const updateOfflinePayment = (Info: Object) => {
    const {
      offlinePaymentData,
    } = Info;
    const payload = {
      supportedOfflinePaymentTypes: offlinePaymentData,
    };
    props.updateInternalAccount(payload);
  };

  const updateRecurringPaymentType = (Info: Object) => {
    const {
      recuringPaymentData,
    } = Info;
    const payload = {
      supportedRecurringPaymentTypes: recuringPaymentData,
    };
    props.updateInternalAccount(payload);
  };

  const updateBinRate = (Info: Object) => {
    const {
      addBin,
    } = Info;
    const payload = {
      supportedBins: addBin,
    };
    props.updateInternalAccount(payload);
  };

  const updatePaymentType = (Info: Object) => {
    const {
      acquirerId,
      paymentMethodData,
    } = Info;
    const uniqePaymentTypes = [...new Set(paymentMethodData)];
    const id = acquirerId || (test ? 1 : '');
    const payload = {
      acquirerId: id,
      supportedPaymentTypes: uniqePaymentTypes,
    };
    props.updateInternalAccount(payload);
  };

  const paymentOrderPayload = (val) => {
    const orderData = val.map((res, index) =>
      // eslint-disable-next-line
      Object.assign({
        category: res,
        order: index + 1,
      }));
    return orderData;
  };

  const updatePaymentOrder = (Info: Object) => {
    const {
      response,
    } = Info;
    const paymentTypeOrder = paymentOrderPayload(response);
    const payload = {
      paymentTypeOrder,
    };
    props.updateInternalAccount(payload);
  };

  return (
    <>
      <div className="mr-4">
        <Collapse
          bordered={false}
          onChange={callback}
          activeKey={activePanel}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          className="site-collapse-custom-collapse"
        >
          <Panel header="Acquirer Details" key="1">
            <Acquirer
              internalAccount={internalAccount}
              acquirerData={acquirerData}
              fetchAcquirerDetails={fetchAcquirerDetails}
              openModal={openModal}
              submit={updateAcquirer}
            />
          </Panel>
          <Panel header="MDR" key="2">
            <Mdr
              internalAccount={internalAccount}
              openModal={openModal}
              submit={updateMdr}
            />
          </Panel>
          <Panel header="Supported Currencies" key="3">
            <SupportedCurrency
              internalAccount={internalAccount}
              openModal={openModal}
              submit={updateCurrency}
            />
          </Panel>
          <Panel header="Supported Offline Payments" key="4">
            <SupportedOfflinePayments
              internalAccount={internalAccount}
              openModal={openModal}
              submit={updateOfflinePayment}
            />
          </Panel>
          <Panel header="Supported Payment Types" key="5">
            <SupportedPayments
              internalAccount={internalAccount}
              paymentType={paymentType}
              openModal={openModal}
              submit={updatePaymentType}
            />
          </Panel>
          <Panel header="Payment Type Sequence" key="11">
            <PaymentOrder
              internalAccount={internalAccount}
              paymentOrder={internalAccount && internalAccount.paymentTypeOrder}
              paymentTypes={getUniquePaymentTypes((internalAccount && internalAccount.supportedPaymentTypes) || ['CARD'])}
              openModal={openModal}
              submit={updatePaymentOrder}
            />
          </Panel>
          <Panel header="Supported Recurring Payment Types" key="6">
            <SupportedRecurringPayments
              internalAccount={internalAccount}
              paymentType={paymentType}
              openModal={openModal}
              isRecurringPaymentValidate={isRecurringPaymentValidate}
              submit={updateRecurringPaymentType}
            />
          </Panel>
          <Panel header="Supported Bins" key="12">
            <SupportedBins
              internalAccount={internalAccount}
              openModal={openModal}
              submit={updateBinRate}
              selectedAccount={selectedAccount}
            />
          </Panel>
          <Panel header="Issuer Details" key="7">
            <Issuer
              internalAccount={internalAccount}
              issuer={issuer}
              openModal={openModal}
              submit={updateIssuer}
            />
          </Panel>
          <Panel header="Partner Images" key="8">
            <PartnerImages
              internalAccount={internalAccount}
              openModal={openModal}
              submit={updatePartnerImages}
            />
          </Panel>
          <Panel header="Partner Commision Rate" key="9">
            <CommissionRate
              internalAccount={internalAccount}
              openModal={openModal}
              submit={updatePartnerCommission}
              selectedAccount={selectedAccount}
            />
          </Panel>
          <Panel header="Others" className="mb-4" key="10">
            <ServerCallBack
              internalAccount={internalAccount}
              openModal={openModal}
              prefix={prefix}
              currencyType={currencyType}
              submit={updateServerCallBack}
            />
          </Panel>
          {/* UPDATE CHANGES MODAL */}
          {updateChangesModal && (
            <UpdateChanges
              visible={updateChangesModal}
              panelSelected={selectedPanel}
              submit={notNow}
              close={updateChanges}
            />
          )}
        </Collapse>
        <hr />
        <Row type="flex" justify="end" gutter={[16, 16]}>
          <Col>
            <SpButton
              type="primary"
              shape="round"
              onClick={() => props.setUpdate(false)}
            >
              Done
            </SpButton>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  internalAccount: state.account.internalAccount,
  acquirerResult: state.acquirer.acquirerSearch.content,
  loading: state.loading.loading,
  validator: state.loading.validator,
  test: state.account.test,
});

const mapDispatchToProps = dispatch => ({
  fetchAcquirerSearch: param => dispatch(acquirerActions.fetchAcquirerSearch({
    type: ACQUIRER.SEARCH_FETCH,
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(UpdateMerchant);
