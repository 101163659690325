import { BUSINESS } from '../actionTypes';

export const fetchBusinessByAccountId = action => ({
  type: BUSINESS.FETCH_BUSINESS_BY_ID,
  payload: action.payload,
});

export const addBusiness = payload => ({
  type: BUSINESS.ADD_BUSINESS,
  payload,
});

export const addBusinessSignUp = payload => ({
  type: BUSINESS.ADD_BUSINESS_SIGN_UP,
  payload,
});

export const getBusinessCategory = payload => ({
  type: BUSINESS.BUSINESS_CATEGORIES_FETCH,
  payload,
});

export const editBusiness = payload => ({
  type: BUSINESS.EDIT_BUSINESS,
  payload,
});

export const selectedBusinessType = action => ({
  type: BUSINESS.BUSINESS_TYPE_SELECTED,
  payload: action.payload,
});

export const fetchDocusignStatus = action => ({
  type: BUSINESS.FETCH_DOCUSIGN_STATUS,
  payload: action.payload,
});
