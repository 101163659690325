import { call, put } from 'redux-saga/effects';

import {
  axiosAuthInstance,
  axiosAuthInstance2,
  API_END_POINTS,
} from 'api';
import { MESSAGES, REFUND_STATUS } from 'appconstants';

import {
  PAYMENTSDETAILS,
  NOTIFICATION,
  LOADING,
} from '../actionTypes';

const fetchPaymentDetails = async gid => axiosAuthInstance2.get(`${API_END_POINTS.GET_PAYMENTS}/${gid}`);

const fetchPaymentTimeline = async gid => axiosAuthInstance2.get(`${API_END_POINTS.GET_PAYMENTS}/${gid}/payment-session-timeline`);

const patchPaymentDetails = async ({ gid, params }) => axiosAuthInstance2.patch(`${API_END_POINTS.GET_PAYMENTS}/${gid}/refund`, params);

const notifyPaymentDetails = async gid => axiosAuthInstance2.get(`${API_END_POINTS.GET_PAYMENTS}/${gid}/notify`);

const notifyPaymentPageDetails = async gid => axiosAuthInstance.get(`${API_END_POINTS.GET_OFFLINE}/${gid}/notify`);

const acceptDisputeEvidence = async gid => axiosAuthInstance.patch(`${API_END_POINTS.SUBMIT_EVIDENCE}/${gid}/dispute/accept`);

const completeRefundPayment = async gid => axiosAuthInstance2.patch(`/internal${API_END_POINTS.GET_PAYMENTS}/${gid}/refund/complete`);

const resolveDisputeEvidence = async ({ gid, params }) => axiosAuthInstance.patch(`/internal${API_END_POINTS.GET_PAYMENTS}/${gid}/dispute`, params);

const raiseDispute = async ({ gid, params }) => axiosAuthInstance.post(`/internal${API_END_POINTS.GET_PAYMENTS}/${gid}/dispute`, params);

const fetchPaymentDisputeDetails = async gid => axiosAuthInstance2.get(`/internal${API_END_POINTS.GET_PAYMENTS}/${gid}`);

export function* getPaymentDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchPaymentDetails, action.payload);
      yield put({ type: PAYMENTSDETAILS.SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchPaymentDetails, action);
      yield put({ type: PAYMENTSDETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: PAYMENTSDETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* getPaymentTimeline(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchPaymentTimeline, action.payload);
      yield put({ type: PAYMENTSDETAILS.TIMELINE_SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchPaymentTimeline, action);
      yield put({ type: PAYMENTSDETAILS.TIMELINE_SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: PAYMENTSDETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* refundPaymentDetails(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    const { data: { entity } } = yield call(patchPaymentDetails, action.payload);
    yield call(getPaymentTimeline, action.payload.gid);
    yield put({ type: PAYMENTSDETAILS.SUCCESS, payload: entity });
    if (REFUND_STATUS.includes(entity.status)) {
      yield put({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.PAYMENTSDETAILS.REFUND_SUCCESS,
      });
    } else if (!REFUND_STATUS.includes(entity.status)) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.PAYMENTSDETAILS.ERROR,
      });
    }
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getNotifyDetails(action) {
  try {
    if (action.payload) {
      const { data: { entity } } = yield call(notifyPaymentDetails, action.payload);
      yield put({ type: PAYMENTSDETAILS.SUCCESS, payload: entity });
      yield put({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.NOTIFY.SUCCESS,
      });
    } else {
      const { data: { entity } } = yield call(notifyPaymentDetails, action);
      yield put({ type: PAYMENTSDETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* acceptEvidenceDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    yield call(acceptDisputeEvidence, action.payload);
    const { data: { entity } } = yield call(fetchPaymentDetails, action.payload);
    yield call(getPaymentTimeline, action.payload);
    yield put({ type: PAYMENTSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTSDETAILS.UPDATE_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* completeRefund(action) {
  try {
    yield put({ type: LOADING.SET });
    yield call(completeRefundPayment, action.payload);
    const { data: { entity } } = yield call(fetchPaymentDetails, action.payload);
    yield call(getPaymentTimeline, action.payload);
    yield put({ type: PAYMENTSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTSDETAILS.UPDATE_SUCCESS,
    });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* resolveEvidenceDetails(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(resolveDisputeEvidence, action.payload);
    const { data: { entity } } = yield call(fetchPaymentDetails, action.payload.gid);
    yield call(getPaymentTimeline, action.payload.gid);
    yield put({ type: PAYMENTSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTSDETAILS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getPaymentDisputeDetails(action) {
  try {
    yield put({ type: LOADING.SET });
    if (action.payload) {
      const { data: { entity } } = yield call(fetchPaymentDisputeDetails, action.payload);
      yield put({ type: PAYMENTSDETAILS.DISPUTE_SUCCESS, payload: entity });
    } else {
      const { data: { entity } } = yield call(fetchPaymentDisputeDetails, action);
      yield put({ type: PAYMENTSDETAILS.DISPUTE_SUCCESS, payload: entity });
    }
  } catch (e) {
    yield put({ type: PAYMENTSDETAILS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.UNSET });
  }
}

export function* raiseDisputeDetails(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(raiseDispute, action.payload);
    const { data: { entity } } = yield call(fetchPaymentDetails, action.payload.gid);
    yield call(getPaymentTimeline, action.payload.gid);
    yield put({ type: PAYMENTSDETAILS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.PAYMENTSDETAILS.DISPUTE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* getNotifyPaymentPageDetails(action) {
  try {
    if (action.payload) {
      const { data: { entity } } = yield call(notifyPaymentPageDetails, action.payload);
      yield put({ type: PAYMENTSDETAILS.SUCCESS, payload: entity });
      yield put({
        type: NOTIFICATION.SUCCESS,
        payload: MESSAGES.NOTIFY.SUCCESS,
      });
    } else {
      const { data: { entity } } = yield call(notifyPaymentPageDetails, action);
      yield put({ type: PAYMENTSDETAILS.SUCCESS, payload: entity });
    }
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
