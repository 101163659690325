// @flow
import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Switch,
  Upload,
  message,
  Icon,
} from 'antd';
import {
  SpH5,
  SpError,
  SpText,
} from 'components/DesignKit';
import SimpleReactValidator from 'simple-react-validator';
import { NOTIFICATION } from 'store/actionTypes';
import {
  axiosAuthInstance,
  axiosEmptyInstance,
  API_END_POINTS,
} from 'api';
import {
  notificationActions,
} from 'store/actions';
import {
  MESSAGES,
} from 'appconstants';

import Previewicon from 'components/NavIcons/previewIcon';
import Uploadicon from 'components/NavIcons/uploadIcon';

const { Option } = Select;
const { TextArea } = Input;

type Props = {
  submitting: boolean,
  itemsList: Array,
  modifierItemsList: Array,
  labelList: Array,
  setNotification: Function,
  detailsData: Object,
  shopsList: Array,
};

const Details = forwardRef((props: Props, ref) => {
  const {
    submitting,
    itemsList,
    modifierItemsList,
    labelList,
    setNotification,
    detailsData,
    shopsList,
  } = props;
  const [, forceUpdate] = useState();
  const [logoUrl, setLogo] = useState(detailsData ? detailsData.logoUrl : '');
  const [preview, setPreview] = useState(detailsData ? detailsData.preview : '');
  const [fileName, setFileName] = useState(detailsData ? detailsData.fileName : '');
  const [description, setDescription] = useState(detailsData ? detailsData.description : '');
  const [tagDetails, setTagDetails] = useState(detailsData ? detailsData.tagDetails : []);
  const [order, setOrder] = useState(detailsData ? detailsData.order : '');
  const [categories, setCategories] = useState((detailsData && detailsData.categories) ? detailsData.categories : []);
  const [isPos, setIsPos] = useState(detailsData ? detailsData.isPos : false);
  const [isItemAvailable, setIsItemAvailable] = useState(detailsData ? detailsData.isItemAvailable : false);
  const [isRecurring, setIsRecurring] = useState(detailsData ? detailsData.isRecurring : false);
  const [modifierGroups, setModifierGroups] = useState(detailsData ? detailsData.modifierGroups : []);
  const [shopDetails, setShopDetails] = useState(detailsData ? detailsData.shopDetails : []);
  const [loader, setLoading] = useState(false);
  const simpleValidator = useRef(new SimpleReactValidator());

  const uploadButton = (
    <div>
      {loader ? <Icon type="loading" /> : <Uploadicon />}
      <div className="ant-upload-text mt-2">Click or drag the file here to upload</div>
      <div className="mt-2"><SpText color="rgba(0, 0, 0, 0.45)">Support extension: .jpg .png</SpText></div>
    </div>
  );

  const beforeUpload = (file) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    const IMAGE = 'image/*';
    const pattern = file.type.match(IMAGE);
    if (!pattern) {
      message.error('File is not image');
      return false;
    }
    if (pattern && !isLt5M) {
      message.error('File must smaller than 5MB!');
      return false;
    }
    return true;
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const fileReader = async file => new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsArrayBuffer(file);
  });

  const fileUpload = async (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true);
      return;
    }
    try {
      setLoading(true);
      const file = info.file.originFileObj;
      if (file) {
        const { data: { entity } } = await axiosAuthInstance.get(`${API_END_POINTS.UPLOAD_ITEM_IMAGE}`);
        await axiosEmptyInstance.put(entity, await fileReader(file), { headers: { 'Content-Type': file.type } });
        const { data: { entity: { fileUrl } } } = await axiosAuthInstance.post(`${API_END_POINTS.UPLOAD_ITEM_IMAGE}`, { fileUrl: entity });
        setLogo(fileUrl);
        const pattern = /image*/;
        if (file.type.match(pattern)) {
          getBase64(file, imageUrl => {
            setPreview(<img src={imageUrl} alt="..." style={{ width: '115px' }} />);
          });
        } else {
          setPreview(<Previewicon />);
        }
        setFileName(info.file.name);
      }
    } catch (e) {
      setNotification({
        type: NOTIFICATION.ERROR,
        payload: MESSAGES.FILE_UPLOAD.ERROR,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (values) => {
    setCategories(values);
  };

  const handleSelectModifierChange = (values) => {
    setModifierGroups(values);
  };

  const handleSelectTagChange = (values) => {
    setTagDetails(values);
  };

  const isPosChange = (value) => {
    setIsPos(value);
  };

  const isAvailableChange = (value) => {
    setIsItemAvailable(value);
  };

  const handleSelectShopChange = (values) => {
    setShopDetails(values);
  };

  const isRecurringChange = (value) => {
    setIsRecurring(value);
  };

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if (!formValid) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      logoUrl,
      preview,
      fileName,
      description,
      tagDetails,
      order,
      categories,
      isItemAvailable,
      isRecurring,
      modifierGroups,
      isPos: !isPos,
      shopDetails,
    }),
  }));

  return (
    <div>
      <div className={submitting ? 'OVERLAY' : ''} />
      <div className="mt-4 mb-4">
        <label htmlFor=""><SpH5>Add Image</SpH5></label>
        <Upload
          name="avatar"
          listType="picture-card upload-card"
          className="avatar-uploader"
          showUploadList={false}
          beforeUpload={beforeUpload}
          onChange={fileUpload}
        >
          {fileName ? preview : uploadButton}
        </Upload>
      </div>
      <div className="mb-3">
        <label htmlFor=""><SpH5>Description</SpH5></label>
        <TextArea
          maxLength={255}
          rows={5}
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
        />
      </div>
      <div className="mb-3">
        <label htmlFor=""><SpH5>Shops</SpH5></label>
        <Select
          className="w-100"
          mode="multiple"
          allowClear
          placeholder="Select shops"
          onChange={handleSelectShopChange}
          defaultValue={shopDetails}
          showSearch
          optionFilterProp="name"
          style={{ width: '100%' }}
        >
          {shopsList.map((item) => (
            <Option key={item.gid} name={item.name} value={item.gid}>
              {item.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className="mb-3">
        <label htmlFor=""><SpH5>Categories</SpH5></label>
        <Select
          className="w-100"
          mode="multiple"
          allowClear
          placeholder="Select categories"
          onChange={handleSelectChange}
          defaultValue={categories}
          showSearch
          optionFilterProp="name"
          style={{ width: '100%' }}
        >
          {itemsList.map((item) => (
            <Option key={item.gid} name={item.name} value={item.gid}>
              {item.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className="mb-3">
        <label htmlFor=""><SpH5>Modfier Groups</SpH5></label>
        <Select
          className="w-100"
          mode="multiple"
          allowClear
          placeholder="Select modifier groups"
          onChange={handleSelectModifierChange}
          showSearch
          defaultValue={modifierGroups}
          optionFilterProp="name"
          style={{ width: '100%' }}
        >
          {modifierItemsList.map((item) => (
            <Option key={item.gid} name={item.name} value={item.gid}>
              {item.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className="mb-3">
        <label htmlFor=""><SpH5>TAGS</SpH5></label>
        <Select
          className="w-100"
          mode="multiple"
          allowClear
          placeholder="Select tags"
          onChange={handleSelectTagChange}
          defaultValue={tagDetails}
          showSearch
          optionFilterProp="name"
          style={{ width: '100%' }}
        >
          {labelList.map((item) => (
            <Option key={item.gid} name={item.name} value={item.gid}>
              {item.name}
            </Option>
          ))}
        </Select>
      </div>
      <div className="mb-3">
        <label htmlFor=""><SpH5>Sort Order</SpH5></label>
        <Input
          placeholder="Enter the order"
          type="number"
          onChange={(e) => setOrder(e.currentTarget.value)}
          value={order}
        />
        <SpError>
          {simpleValidator.current.message('order', order, 'numeric|numericValidation|minLimit')}
        </SpError>
      </div>
      <div className="mb-3">
        <Switch
          className="mr-2"
          checked={isPos}
          onChange={isPosChange}
        /> ON POS
      </div>
      <div>
        <Switch
          className="mr-2"
          checked={isItemAvailable}
          onChange={isAvailableChange}
        /> AVAILABLE
      </div>
      <div className='mt-2'>
        <Switch
          className="mr-2"
          checked={isRecurring}
          onChange={isRecurringChange}
        /> Is Recurring ?
      </div>
    </div>
  );
});

const mapDispatchToProps = (dispatch) => ({
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

// $FlowFixMe
export default connect(
  null,
  mapDispatchToProps,
  null,
  { forwardRef: true },
)(Details);
