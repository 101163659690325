import React, { useState, useRef } from 'react';
import {
  Row,
  Col,
  Input,
} from 'antd';
import {
  SpError,
  SpButton,
  SpH5,
  SpText,
} from 'components/DesignKit';

import SimpleReactValidator from 'simple-react-validator';
import {
  MAX_AMOUNT,
} from 'appconstants';
import { validationRegex } from 'utils/validationMessages';


type Props = {
  addSetting: Function,
  isIndia: Boolean,
  prefix: String,
};

const Add = (props: Props) => {
  const {
    addSetting,
    isIndia,
    prefix,
  } = props;
  const [, forceUpdate] = useState();
  const [rewardRate, setRewardRate] = useState();
  const [cashBackRate, setCashBackRate] = useState();
  const [rewardCap, setRewardCap] = useState();
  const [cashBackCap, setCashBackCap] = useState();

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
    },
  }));

  const formatMinAmount = (e) => {
    const { value } = e.currentTarget;
    const amount = parseFloat(value);
    if (Number(amount)) {
      setRewardCap(parseInt(amount, 10).toFixed(2));
    }
  };

  const updateMinAmount = (e) => {
    const { value } = e.currentTarget;
    if (validationRegex.amountRegex.test(value) || value === '') {
      setRewardCap(value);
    } else if (!validationRegex.amountRegex.test(value)) {
      setRewardCap(0);
    }
  };

  const updateMaxAmount = (e) => {
    const { value } = e.currentTarget;
    if (validationRegex.amountRegex.test(value) || value === '') {
      setCashBackCap(value);
    } else if (!validationRegex.amountRegex.test(value)) {
      setCashBackCap(0);
    }
  };

  const formatMaxAmount = (e) => {
    const { value } = e.currentTarget;
    const amount = parseFloat(value);
    if (Number(amount)) {
      setCashBackCap(parseInt(amount, 10).toFixed(2));
    }
  };

  const addRewardSettings = () => {
    const formValid = simpleValidator.current.allValid();

    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }

    addSetting({
      rewardCap,
      rewardRate,
      cashBackCap,
      cashBackRate,
    });
  };

  return (
    <>
      <Row className="my-2 mb-4 mt-4">
        <Col>
          <SpText className="text-uppercase" fontSize="14px" fontWeight="700" color="#999999">
            REWARD SETTINGS
          </SpText>
        </Col>
      </Row>
      <>
        <Row type="flex" className="mb-4" justify="start" align="middle">
          <Col span={4}>
            <SpH5>Reward Rate</SpH5>
          </Col>
          <Col span={6}>
            <Input
              placeholder="0.00"
              prefix="%"
              value={rewardRate}
              onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                const { value } = e.currentTarget;
                if (regex.test(value) || value === '') {
                  setRewardRate(e.currentTarget.value);
                } else if (!regex.test(value)) {
                  setRewardRate(0.00);
                }
              }}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
              onBlur={e => {
                if (e.currentTarget.value) {
                  const { value } = e.currentTarget;
                  const amount = parseFloat(value);
                  if (Number(amount)) {
                    setRewardRate(parseInt(amount, 10).toFixed(2));
                  }
                }
              }}
            />
            <SpError>
              {simpleValidator.current.message('reward rate', rewardRate, 'required')}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" className="mb-4" justify="start" align="middle">
          <Col span={4}>
            <SpH5>Reward Cap</SpH5>
          </Col>
          <Col span={6}>
            <Input
              placeholder="0.00"
              prefix={prefix}
              value={cashBackCap && cashBackCap}
              onBlur={formatMaxAmount}
              onChange={updateMaxAmount}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
            />
            <SpError>
              {simpleValidator.current.message('cashback cap', cashBackCap, 'required|amount',
                { messages: { required: 'The cash back cap field is required.' } })}
            </SpError>
          </Col>
        </Row>
        <Row type="flex" className="mb-4" justify="start" align="middle">
          <Col span={4}>
            <SpH5>Cashback Rate</SpH5>
          </Col>
          <Col span={6}>
            <Input
              placeholder="0.00"
              prefix="%"
              value={cashBackRate}
              onChange={(e) => {
                const regex = /^\d*\.?\d*$/;
                const { value } = e.currentTarget;
                if (regex.test(value) || value === '') {
                  setCashBackRate(e.currentTarget.value);
                } else if (!regex.test(value)) {
                  setCashBackRate(0.00);
                }
              }}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
              onBlur={e => {
                if (e.currentTarget.value) {
                  const { value } = e.currentTarget;
                  const amount = parseFloat(value);
                  if (Number(amount)) {
                    setCashBackRate(parseInt(amount, 10).toFixed(2));
                  }
                }
              }}
            />
            <SpError>
              {simpleValidator.current.message('cashback rate', cashBackRate, 'required')}
            </SpError>
          </Col>
        </Row>

        <Row type="flex" className="mb-4" justify="start" align="middle">
          <Col span={4}>
            <SpH5>Cashback Cap</SpH5>
          </Col>
          <Col span={6}>
            <Input
              placeholder="0.00"
              prefix={prefix}
              value={rewardCap && rewardCap}
              onBlur={formatMinAmount}
              onChange={updateMinAmount}
              onKeyPress={e => {
                const keyCode = e.charCode || e.which;
                if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                  e.preventDefault();
                }
              }}
            />
            <SpError>
              {simpleValidator.current.message('reward cap', rewardCap, 'required|amount',
                { messages: { required: 'The reward cap field is required.' } })}
            </SpError>
          </Col>
        </Row>
      </>
      <Row type="flex" justify="end" gutter={[16, 16]}>
        <Col>
          <SpButton
            type="primary"
            shape="round"
            onClick={addRewardSettings}
          >
            Update Settings
          </SpButton>
        </Col>
      </Row>
    </>
  );
};

export default Add;
