// @flow
import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Input,
  Select,
  Modal,
  Checkbox,
  Row,
  Col,
  DatePicker,
  Switch,
  AutoComplete,
} from 'antd';
import moment from 'moment-timezone';
import SimpleReactValidator from 'simple-react-validator';
import formatAmount from 'utils/formatAmount';
import {
  countryCodeValidation,
  validators,
  validationRegex,
} from 'utils/validationMessages';
import { isValidPhoneNumber, formatNumber, findPhoneNumbersInText } from 'libphonenumber-js';
import getPrefix from 'utils/getPrefix';
import { SpH5, SpError, SpButton } from 'components/DesignKit';
import {
  ROLES,
  COUNTRY_PHONE_CODE,
  COUNTRIES,
  MAX_AMOUNT,
  SUPPORTED_PAYMENT_TYPES,
  SUPPORTED_PAYMENT_LABEL,
} from 'appconstants';

const { Option } = Select;

type Props = {
  close: Function,
  phoneCode: string,
  submit: Function,
  visible: boolean,
  submitting: boolean,
  selectedAccount: Object,
  role: Object,
  customersList: Array<Object>,
  getCustomers: Function,
  paymentMethods: Array,
  supportedCurrency: Array,
};

const AddPaymentLink = (props: Props) => {
  const {
    visible,
    close,
    submit,
    submitting,
    phoneCode,
    selectedAccount,
    role,
    customersList,
    getCustomers,
    paymentMethods,
    supportedCurrency,
  } = props;

  const [, forceUpdate] = useState();
  const [amount, setAmount] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhone] = useState('');
  const [notify, setNotify] = useState('Email');
  const [countryCode, setCountryCode] = useState(phoneCode);
  const [phoneFormat, setPhoneFormat] = useState();
  const [redirectUrl, setRedirectUrl] = useState('');
  const [paymentMethodData, setPaymentMethodData] = useState(paymentMethods || ['CARD']);
  const [disableButton, setDisableButton] = useState(false);
  const [dop, setDop] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [disable, setDisable] = useState(false);
  const [customerGid, setCustomerGid] = useState('');
  const [currencyCode, setCurrencyCode] = useState('');
  const [applicationFee, setApplicationFee] = useState('0.00');
  const [prefix, setPrefix] = useState(selectedAccount.currency && selectedAccount.currency.prefix);
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;
  const currencyType = selectedAccount && selectedAccount.currency;
  const currencyName = (selectedAccount.currency && selectedAccount.currency.name);
  const STRING_LENGTH = 5;
  const dateFormat = 'MMM DD, YYYY';
  const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
  const currentDate = moment().format(MOMENT_FORMAT);
  const disableDate = moment().tz(currentDate, selectedAccount.timezone).endOf('day');
  const [canDecline, setCanDecline] = useState(false);
  const [serviceFeeEnabled, setServiceFeeEnabled] = useState(false);
  const [serviceFee, setServiceFee] = useState(null);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      amount: {
        message: isIndia ? MAX_AMOUNT.MESSAGE_INR : MAX_AMOUNT.MESSAGE_USD,
        rule: (val) => (
          isIndia ? MAX_AMOUNT.LIMIT_INR_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_INR
            : MAX_AMOUNT.LIMIT_USD_MIN <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_USD),
      },
      stringLength: {
        message: validators.paymentLink.validName,
        rule: val => val.length >= STRING_LENGTH,
      },
      phoneNumValidation: {
        message: validators.register.validPhone,
        rule: (val, param) => (isValidPhoneNumber(param[0])),
      },
      feeAmount: {
        message: 'fee should be greater than or equal to zero',
        rule: val => Number(val && val.replace(/,/g, '')) >= 0,
      },
      emailId: {
        message: validators.register.validEmail,
        rule: val => validationRegex.email.test(val),
      },
      percentage: {
        message: MAX_AMOUNT.MESSAGE_PERCENTAGE,
        rule: (val) => MAX_AMOUNT.MIN_AMOUNT <= Number(val && val.replace(/,/g, '')) && Number(val && val.replace(/,/g, '')) <= MAX_AMOUNT.LIMIT_MAX,
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
    },
  }));

  useEffect(() => {
    if (!submitting) {
      setDisableButton(false);
    }
  }, [submitting]);

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhone(updatedPhone.replace(/[^0-9+]/g, ''));
    setPhoneFormat(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  const onCheckChange = (checkedValues) => {
    setPaymentMethodData(checkedValues);
  };

  const onCountryCodeChange = (value) => {
    setCountryCode(value);
    const data = formatNumber(`${value}${phoneNumber}`, 'INTERNATIONAL');
    const formater = data.substr(data.indexOf(' ') + 1);
    setPhoneFormat(formater);
  };

  const updateCustomer = (value) => {
    const results = customersList.filter((item) => (item.gid === value));
    if (results && results[0].gid) {
      setDisable(true);
      setCustomerGid(results[0].gid);
      setEmail(results[0].email);
      setName(results[0].name);
      if (results[0].phoneNumber) {
        const phoneResult = results[0].phoneNumber ? findPhoneNumbersInText(results[0].phoneNumber) : null;
        if ((phoneResult && phoneResult.length > 0) && phoneResult[0].number) {
          setCountryCode(`+${phoneResult[0].number.countryCallingCode}`);
          setPhone(phoneResult[0].number.nationalNumber);
          const data = formatNumber(phoneResult[0].number.number, 'INTERNATIONAL');
          const formater = data.substr(data.indexOf(' ') + 1);
          setPhoneFormat(formater);
        }
      }
    }
  };

  useEffect(() => {
    const results = customersList.filter((item) => (item.name === name));
    if (results && !results[0]) {
      setCustomerGid('');
    }
  }, [customersList]);

  const searchCustomer = (searchText) => {
    getCustomers(searchText);
    setName(searchText);
    setDisable(false);
  };

  const onCurrencyChange = (e) => {
    setCurrencyCode(e);
    setPrefix(getPrefix(e));
  };

  const onDateChange = (date, dateString) => {
    setDop(dateString);
    if (date && dateString) {
      const Date = moment(date).format(MOMENT_FORMAT);
      const selectedDate = moment.tz(Date, selectedAccount.timezone);
      const utcDate = selectedDate.utc().format(MOMENT_FORMAT);
      setExpiryDate(utcDate);
    } else if (!date && !dateString) {
      setExpiryDate(null);
    }
  };

  simpleValidator.current.purgeFields();

  const validate = (event) => {
    setDisableButton(true);
    setPhone(phoneNumber.replace(/[^0-9+]/g, ''));
    event.preventDefault();
    const formValid = simpleValidator.current.allValid();
    setDisableButton(formValid);
    if (!formValid) {
      simpleValidator.current.showMessages();
      forceUpdate(1);
      return;
    }
    const gpayIndex = paymentMethodData.indexOf('GOOGLE_PAY_US');
    const applepayIndex = paymentMethodData.indexOf('APPLE_PAY_US');
    if (gpayIndex !== -1) {
      paymentMethodData[gpayIndex] = 'WALLET';
    }
    if (applepayIndex !== -1) {
      paymentMethodData[applepayIndex] = 'WALLET';
    }
    submit({
      name,
      email,
      phoneNumber,
      countryCode,
      amount,
      applicationFee,
      notify,
      description,
      redirectUrl,
      paymentMethodData,
      expiryDate,
      customerGid,
      currencyCode,
      canDecline,
      serviceFee,
      serviceFeeEnabled,
    });
  };

  return (
    <Modal
      centered
      closable={false}
      visible={visible}
      footer={[
        <SpButton onClick={() => close()} type="secondary">Cancel</SpButton>,
        <SpButton onClick={validate} disabled={disableButton}>Create</SpButton>,
      ]}
      title="Add Payment Link"
    >
      <div className="px-4">
        <div className={submitting ? 'OVERLAY' : ''} />
        <div className="mb-3">
          <label htmlFor=""><SpH5>Amount</SpH5></label>
          <div className="d-flex">
            <div style={{ width: '85%' }}>
              <Input
                value={amount}
                prefix={prefix}
                placeholder="0.00"
                onChange={(e) => {
                  const regex = /^\d*\.?\d*$/;
                  const { value } = e.currentTarget;
                  if (regex.test(value) || value === '') {
                    setAmount(e.currentTarget.value);
                  } else if (!regex.test(value)) {
                    setAmount(0.00);
                  }
                }}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    const value = formatAmount(e.currentTarget.value, currencyType);
                    setAmount(value);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('amount', amount, 'required|amount')}
              </SpError>
            </div>
            <div
              style={{ width: '15%' }}
            >
              <Select
                className="w-100"
                showSearch
                defaultValue={currencyName}
                onChange={(e) => onCurrencyChange(e)}
              >
                {supportedCurrency.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        </div>
        {
          (role && role.name === ROLES.PARTNER) && (
            <div className="mb-3">
              <div className="d-flex">
                <div>
                  <label htmlFor=""><SpH5>Application Fee</SpH5></label>
                  <div className="d-flex" style={{ width: '50%' }}>
                    <Input
                      value={applicationFee}
                      prefix={prefix}
                      onChange={(e) => {
                        const regex = /^\d*\.?\d*$/;
                        const { value } = e.currentTarget;
                        if (regex.test(value) || value === '') {
                          setApplicationFee(e.currentTarget.value);
                        } else if (!regex.test(value)) {
                          setApplicationFee(0.00);
                        }
                      }}
                      onKeyPress={e => {
                        const keyCode = e.charCode || e.which;
                        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                          e.preventDefault();
                        }
                      }}
                      onBlur={e => {
                        if (e.currentTarget.value) {
                          const value = formatAmount(e.currentTarget.value, currencyType);
                          setApplicationFee(value);
                        }
                      }}
                    />
                  </div>
                  <SpError>
                    {simpleValidator.current.message('percentage', applicationFee, 'required')}
                  </SpError>
                </div>
              </div>
            </div>
          )
        }
        <div className="mb-3">
          <label htmlFor="">
            <Checkbox
              className="mr-2"
              checked={serviceFeeEnabled}
              onChange={({ target: { checked } }) => {
                setServiceFeeEnabled(checked);
                simpleValidator.current.purgeFields();
                if (!checked) {
                  setServiceFee(null);
                }
              }}
            />
            <SpH5>Apply Service Fees</SpH5>
          </label>
        </div>
        {
          serviceFeeEnabled && (
            <div className="mb-3">
              <label htmlFor=""><SpH5>Service Fee</SpH5></label>
              <Input
                value={serviceFee}
                prefix={prefix}
                onChange={(e) => {
                  const regex = /^\d*\.?\d*$/;
                  const { value } = e.currentTarget;
                  if (regex.test(value) || value === '') {
                    setServiceFee(e.currentTarget.value);
                  } else if (!regex.test(value)) {
                    setServiceFee(0.00);
                  }
                }}
                onKeyPress={e => {
                  const keyCode = e.charCode || e.which;
                  if ((keyCode < 48 || keyCode > 57) && keyCode !== 46 && keyCode !== 44) {
                    e.preventDefault();
                  }
                }}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    const value = formatAmount(e.currentTarget.value, currencyType);
                    setServiceFee(value);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('service fee', serviceFee, 'feeAmount')}
              </SpError>
            </div>
          )
        }
        <div className="mb-3">
          <label htmlFor=""><SpH5>Description</SpH5></label>
          <Input
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('description', description, 'required')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Name</SpH5></label>
          <AutoComplete
            placeholder="Name"
            showSearch
            className="w-100"
            dataSource={customersList.map((item) => (
              <Option key={item.gid} value={item.gid}>
                {item.name}
              </Option>
            ))}
            onSearch={searchCustomer}
            defaultValue={name}
            onSelect={e => updateCustomer(e)}
          >
            <Input.Search enterButton />
          </AutoComplete>
          <SpError>
            {simpleValidator.current.message('name', name, 'required|stringLength')}
          </SpError>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Email</SpH5></label>
          <Input
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            disabled={disable}
          />
          <SpError>
            {simpleValidator.current.message('email', email, 'required|emailId|checkLowerCase')}
          </SpError>
        </div>

        <div className="mb-3">
          <label htmlFor=""><SpH5>Phone Number</SpH5></label>
          <div className="d-flex">
            <div
              style={{ width: '20%' }}
              onKeyPress={e => countryCodeValidation(e)}
            >
              <Select
                className="w-100"
                showSearch
                value={countryCode}
                onChange={onCountryCodeChange}
                disabled={disable}
              >
                {COUNTRY_PHONE_CODE.map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
            </div>
            <div style={{ width: '80%' }}>
              <Input
                className="InputnumFormate"
                placeholder="Phone Number"
                value={phoneFormat}
                disabled={disable}
                onChange={onPhoneNumberChange}
                onBlur={e => {
                  if (e.currentTarget.value) {
                    setPhone(e.currentTarget.value);
                    const value = formatNumber(`${countryCode}${e.currentTarget.value}`, 'INTERNATIONAL');
                    const formater = value.substr(value.indexOf(' ') + 1);
                    setPhoneFormat(formater);
                  }
                }}
              />
              <SpError>
                {simpleValidator.current.message('phone number', phoneNumber, `phoneNumValidation:${countryCode}${phoneNumber}`)}
              </SpError>
            </div>
          </div>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Due Date(Optional)</SpH5></label>
          <div>
            <DatePicker
              value={dop ? moment(dop) : ''}
              format={dateFormat}
              allowClear={false}
              onChange={onDateChange}
              disabledDate={current => current && current < disableDate}
            />
          </div>
        </div>
        <div className="pb-3">
          <label><SpH5>Notify</SpH5></label>
          <Select
            className="w-100"
            value={notify}
            onChange={(e) => setNotify(e)}
          >
            <Option key={1} value="EMAIL">Email</Option>
            <Option key={2} value="SMS">SMS</Option>
            <Option key={3} value="WHATSAPP">WhatsApp</Option>
            <Option key={4} value="ALL">All</Option>
            <Option key={4} value="NONE">None</Option>
          </Select>
          <SpError>
            {simpleValidator.current.message('notify', notify, 'required')}
          </SpError>
        </div>
        <div className="mb-3">
          <Switch
            checked={canDecline}
            className="mr-2"
            onChange={val => {
              setCanDecline(val);
            }}
          />
          <SpH5>Can Decline</SpH5>
        </div>
        <div className="mb-3">
          <label htmlFor=""><SpH5>Acceptable Payment Methods</SpH5></label>
          <Checkbox.Group style={{ width: '100%' }} value={paymentMethodData} onChange={onCheckChange}>
            <Row>
              {
                paymentMethods.map(item => (
                  <Col span={item === SUPPORTED_PAYMENT_TYPES.NET_BANKING
                    ? 8 : item === SUPPORTED_PAYMENT_TYPES.INSTANT_PAYMENT
                      ? 8 : 6}
                  >
                    <Checkbox value={item}>
                      {(item === SUPPORTED_PAYMENT_TYPES.ACH_LEGACY)
                        ? SUPPORTED_PAYMENT_LABEL.ACH : (item === SUPPORTED_PAYMENT_TYPES.ACH)
                          ? SUPPORTED_PAYMENT_LABEL.BANK : (item === SUPPORTED_PAYMENT_TYPES.NET_BANKING)
                            ? SUPPORTED_PAYMENT_LABEL.NETBANKING : (item === SUPPORTED_PAYMENT_TYPES.GOOGLE_PAY_US)
                              ? SUPPORTED_PAYMENT_LABEL.GOOGLE_PAY_US : (item === SUPPORTED_PAYMENT_TYPES.INSTANT_PAYMENT)
                                ? SUPPORTED_PAYMENT_LABEL.INSTANT_PAYMENT : (item === SUPPORTED_PAYMENT_TYPES.APPLE_PAYMENT)
                                  ? SUPPORTED_PAYMENT_LABEL.GOOGLE_PAY_US : item}

                    </Checkbox>
                  </Col>
                ))
              }
            </Row>
          </Checkbox.Group>
          <SpError>
            {simpleValidator.current.message('checkbox', paymentMethodData, 'required')}
          </SpError>
        </div>
        <div>
          <label htmlFor=""><SpH5>Redirect Url (Optional)</SpH5></label>
          <Input
            placeholder="http://www.example.com"
            value={redirectUrl}
            onChange={(e) => setRedirectUrl(e.currentTarget.value)}
          />
          <SpError>
            {simpleValidator.current.message('redirectUrl', redirectUrl, 'url')}
          </SpError>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
});

// $FlowFixMe
export default connect(mapStateToProps, null)(AddPaymentLink);
