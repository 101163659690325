import { BULKTRANSFER_STATUSES } from 'appconstants';

export default value => {
  if (value === 'CREATED') {
    return BULKTRANSFER_STATUSES.CREATED;
  } if (value === 'PROCESSING') {
    return BULKTRANSFER_STATUSES.PROCESSING;
  } if (value === 'SUCCEEDED') {
    return BULKTRANSFER_STATUSES.SUCCEEDED;
  } if (value === 'PARTIAL_SUCCEEDED') {
    return BULKTRANSFER_STATUSES.PARTIAL_SUCCEEDED;
  } if (value === 'FAILED') {
    return BULKTRANSFER_STATUSES.FAILED;
  } return null;
};
