import React, {
  useEffect,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  Row,
  Col,
  Empty,
} from 'antd';
import styled from 'styled-components';
import {
  SpText,
  CardSchemeIcon,
} from 'components/DesignKit';

import {
  cloverReceiptsActions,
  notificationActions,
} from 'store/actions';
import Loading from 'components/Loading';
import moment from 'moment-timezone';
import PosLine from 'imgs/posline.svg';
import CashIcon from 'imgs/cashIcon.svg';
import WhiteLogo from 'imgs/swirepay-logowhite.svg';
import CloverHeader from 'imgs/cloverHeader.png';
import Dollorimg from 'imgs/dollorimg.svg';
import { Hr } from 'design';

// $FlowFixMe
const Wrapper = styled.div`
    background-image: linear-gradient(120deg, #05d5ff 3%, #007EE5);
    width: 100%;
    min-height: 100vh;
    min-height: 100vh;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  `;

// $FlowFixMe
const PosWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: '24px',
  paddingBottom: '24px',
  paddingLeft: '34px',
  paddingRight: '34px',
  width: '395px',
  minHeight: '370px',
  marginBottom: '24px',
  boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.15)',
  backgroundColor: '#FFFFFF',
  fontFamily: 'Roboto',
  fontStyle: 'none',
});

// $FlowFixMe
const PosHeadWrapper = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  marginTop: '24px',
  paddingBottom: '24px',
  paddingLeft: '34px',
  paddingRight: '34px',
  width: '395px',
  minHeight: '240px',
  backgroundColor: '#242F40',
});

// $FlowFixMe
// const FormCircle = styled.div`
//     width: 80px;
//     height: 80px;
//     background: #DDDFFF;
//     border-radius: 50%;
//     border: 3px solid #3C40A3;
//     margin-top: 20px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//   `;


type Props = {
  fetchCloverReceipts: Function,
  loading: boolean,
  receipts: Object,
  history: {
    replace: Function,
  },
};

const Receipt = (props: Props) => {
  const {
    fetchCloverReceipts,
    receipts,
    loading,
  } = props;

  const { search } = useLocation();
  const [viewDetails, setViewDetails] = useState(false);
  const [isIframed, setIsIframed] = useState(true);

  const merchantId = new URLSearchParams(search).get('m');
  const orderId = new URLSearchParams(search).get('o');
  const paymentId = new URLSearchParams(search).get('p');

  const receiptID = `orderId=${orderId}&paymentId=${paymentId}&merchantId=${merchantId}`;
  // eslint-disable-next-line jsx-a11y/control-has-associated-label
  const redirectUrl = <a target="_blank" rel="noopener noreferrer" href={window.location.href}>See detailed receipt</a>;

  useEffect(() => {
    setIsIframed(window.self === window.top);
  }, []);

  useEffect(() => {
    fetchCloverReceipts(receiptID);
  }, []);

  const formatDate = (date, timezone) => {
    if (date && timezone) {
      const dayeofWeek = date && moment.utc(date).tz(timezone).format('dddd');
      const displayDate = date && moment.utc(date).tz(timezone).format('MMMM D [at] h:mm A');
      return `${dayeofWeek}, ${displayDate}`;
    }
    const dayeofWeek = date && moment(date).format('dddd');
    const displayDate = date && moment.utc(date).format('MMMM D [at] h:mm A');
    return `${dayeofWeek}, ${displayDate}`;
  };

  const getDiscountAmount = (info) => {
    let amount;
    if (info) {
      const amnt = Math.abs(info);
      amount = (amnt / 100).toFixed(2);
    }
    return `-$${amount}`;
  };

  const getpaymentMethod = (data) => {
    if (data && data.includes('***')) {
      const method = data.replace('***', '');
      return method;
    } return data;
  };

  const getAmountDisplay = (amt) => {
    if (amt) {
      const divided = (amt / 100).toFixed(2);
      const integerPart = Math.floor(divided);
      return integerPart;
    } return 0;
  };

  const getDecimalDisplay = (amt) => {
    if (amt) {
      const divided = (amt / 100).toFixed(2);
      const decimalPart = divided.toString().split('.')[1];
      return decimalPart;
    } return 0;
  };

  const openMap = (mapInfo) => {
    const address = `${mapInfo && mapInfo.street}, ${mapInfo && mapInfo.city}, ${mapInfo && mapInfo.state} ${mapInfo && mapInfo.postalCode}`;
    const mapsource = 'http://maps.google.com/maps?q=';
    const mapURL = `${mapsource}${address}`;
    window.open(mapURL, '_blank');
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      {
        isIframed ? (
          <>
            {(receipts && !(Object.keys(receipts).length === 0)) ? (
              <>
                <Wrapper>
                  <PosHeadWrapper>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '10px',
                        paddingBotom: '10px',
                      }}
                    >
                      <>
                        {
                          receipts.account && receipts.account.largeLogo ? (
                            <img
                              style={{
                                height: '60px',
                              }}
                              src={(receipts.account && receipts.account.largeLogo)}
                              alt=""
                            />
                          ) : (
                            <img
                              style={{ height: '40px' }}
                              src={CloverHeader}
                              alt=""
                            />
                          )
                        }
                      </>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '5px',
                      }}
                    >
                      <img src={WhiteLogo} alt="" />
                    </div>
                    {
                      (receipts.account && receipts.account.name) ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '10px',
                            cursor: 'pointer',
                          }}
                        >
                          <SpText
                            fontSize="16px"
                            fontWeight="400"
                            color="#FFFFFF"
                          >
                            {(receipts.account.name) || <>&#8211;</>}
                          </SpText>
                        </div>
                      ) : null
                    }
                    {
                      (receipts.account && receipts.account.address && receipts.account.address.street) ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '10px',
                            cursor: 'pointer',
                          }}
                          onClick={() => openMap(receipts.account.address)}
                        >
                          <SpText
                            fontSize="16px"
                            fontWeight="400"
                            color="#3A81ED"
                            style={{
                              textDecorationLine: 'underline',
                            }}
                          >
                            {(receipts.account.address && receipts.account.address.street) || <>&#8211;</>}
                          </SpText>
                        </div>
                      ) : null
                    }
                    {
                      (receipts.account && receipts.account.address) ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          onClick={() => openMap(receipts.account.address)}
                        >
                          <SpText
                            fontSize="16px"
                            fontWeight="400"
                            color="#3A81ED"
                            style={{
                              textDecorationLine: 'underline',
                            }}
                          >
                            {
                              `${receipts.account.address && receipts.account.address.city
                              }, ${receipts.account.address && receipts.account.address.state
                              }
                        ${receipts.account.address && receipts.account.address.postalCode} `
                            }
                          </SpText>
                        </div>
                      ) : null
                    }

                  </PosHeadWrapper>
                  <PosWrapper>
                    {
                      receipts.inventoryOrder && receipts.inventoryOrder.orderNumber && (
                        <div
                          style={{
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <SpText fontSize="20px" fontWeight="400" color="#000000">
                            Order# &nbsp;
                          </SpText>
                          <SpText fontSize="20px" fontWeight="700" color="#000000">
                            {
                              receipts.inventoryOrder && receipts.inventoryOrder.orderNumber
                            }
                          </SpText>
                        </div>
                      )
                    }
                    {
                      receipts.inventoryOrder && receipts.inventoryOrder.inventoryOrderType && (
                        <div
                          style={{
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <SpText fontSize="36px" fontWeight="400" color="#4C51C2">
                            {
                              (receipts.inventoryOrder && receipts.inventoryOrder.inventoryOrderType
                                ? (receipts.inventoryOrder.inventoryOrderType && receipts.inventoryOrder.inventoryOrderType.label)
                                : <>&#8211;</>
                              )
                            }
                          </SpText>
                        </div>
                      )
                    }

                    {
                      receipts.inventoryOrder && receipts.inventoryOrder.inventoryOrderLineItems && (
                        receipts.inventoryOrder.inventoryOrderLineItems.map((data, id) => (
                          <>
                            <div key={id}>
                              {
                                data && data.itemData && data.itemData.name !== 'Item Tax' ? (
                                  <Row type="flex" className="mt-3" justify="space-between">
                                    <Col>
                                      <SpText
                                        fontSize="16px"
                                        fontWeight="500"
                                        color="#000000"
                                      >
                                        {
                                          (data && data.quantity) ? `${data && data.quantity} ` : ''
                                        }
                                        {
                                          (data && data.itemData && data.itemData.name)
                                            ? (data && data.itemData && data.itemData.name)
                                            : (data && data.itemData && data.itemData.alternateName)
                                        }
                                      </SpText>
                                    </Col>
                                    <Col>
                                      <SpText
                                        fontSize="16px"
                                        fontWeight="500"
                                        color="#000000"
                                      >
                                        {
                                          (data && data.amount && data.quantity) ? (
                                            `$${(((data && data.amount) / 100) * data.quantity)
                                              .toFixed(2)
                                            } `
                                          ) : <>&#8211;</>
                                        }
                                      </SpText>
                                    </Col>
                                  </Row>
                                ) : data && data.itemName && data.itemName !== 'Item Tax' ? (
                                  <Row type="flex" className="mt-3" justify="space-between">
                                    <Col>
                                      <SpText
                                        fontSize="16px"
                                        fontWeight="500"
                                        color="#000000"
                                      >
                                        {
                                          (data && data.quantity) ? `${data && data.quantity} ` : ''
                                        }
                                        {
                                          (data && data.itemName)
                                            ? (data && data.itemName)
                                            : ''
                                        }
                                      </SpText>
                                    </Col>
                                    <Col>
                                      <SpText
                                        fontSize="16px"
                                        fontWeight="500"
                                        color="#000000"
                                      >
                                        {
                                          (data && data.amount && data.quantity) ? (
                                            `$${(((data && data.amount) / 100) * data.quantity)
                                              .toFixed(2)
                                            } `
                                          ) : <>&#8211;</>
                                        }
                                      </SpText>
                                    </Col>
                                  </Row>
                                ) : null
                              }
                              {
                                (data.modifierData && data.modifierData.length !== 0) ? data.modifierData.map((info, idx) => (
                                  <>
                                    <div key={idx}>
                                      <Row type="flex" className="mt-1" justify="space-between">
                                        <Col className="ml-2">
                                          <SpText
                                            fontSize="16px"
                                            fontWeight="400"
                                            color="#6E6E6E"
                                          >
                                            {
                                              (info && info.name)
                                                ? (info && info.name)
                                                : (info && info.alternateName)
                                            }
                                          </SpText>
                                        </Col>
                                        <Col>
                                          <SpText
                                            fontSize="16px"
                                            fontWeight="400"
                                            color="#6E6E6E"
                                          >
                                            {
                                              (info && info.price && data.quantity) ? (
                                                `$${(((info && info.price) / 100) * data.quantity)
                                                  .toFixed(2)
                                                } `
                                              ) : (info && info.price) ? (
                                                `$${(((info && info.price) / 100))
                                                  .toFixed(2)
                                                } `
                                              ) : <>&#8211;</>
                                            }
                                          </SpText>
                                        </Col>
                                      </Row>
                                    </div>
                                  </>
                                )) : (data.lineItemModifiers && data.lineItemModifiers.length !== 0) ? data.lineItemModifiers.map((info, idx) => (
                                  <>
                                    <div key={idx}>
                                      <Row type="flex" className="mt-1" justify="space-between">
                                        <Col className="ml-2">
                                          <SpText
                                            fontSize="16px"
                                            fontWeight="400"
                                            color="#6E6E6E"
                                          >
                                            {
                                              (info && info.modifierName)
                                                ? (info && info.modifierName)
                                                : (info && info.alternateName)
                                            }
                                          </SpText>
                                        </Col>
                                        <Col>
                                          <SpText
                                            fontSize="16px"
                                            fontWeight="400"
                                            color="#6E6E6E"
                                          >
                                            {
                                              (info && info.price && info.quantity && data.quantity) ? (
                                                `$${(((info && info.price) / 100) * info.quantity * data.quantity)
                                                  .toFixed(2)
                                                } `
                                              ) : (info && info.price && info.quantity) ? (
                                                `$${(((info && info.price) / 100) * info.quantity)
                                                  .toFixed(2)
                                                } `
                                              ) : (info && info.price) ? (
                                                `$${(((info && info.price) / 100))
                                                  .toFixed(2)
                                                } `
                                              ) : <>$0.00</>
                                            }
                                          </SpText>
                                        </Col>
                                      </Row>
                                    </div>
                                  </>
                                )) : ''
                              }
                              {
                                data.note && (
                                  <Row type="flex" className="mt-1" justify="space-between">
                                    <Col className="ml-2">
                                      <SpText
                                        fontSize="16px"
                                        fontWeight="400"
                                        color="#6E6E6E"
                                      >
                                        {data.note}
                                      </SpText>
                                    </Col>
                                  </Row>
                                )
                              }
                              {
                                (data.taxData && data.taxData.length !== 0) ? (
                                  <>
                                    <div>
                                      <Row type="flex" className="mt-1" justify="space-between">
                                        <Col className="ml-2">
                                          <SpText
                                            fontSize="16px"
                                            fontWeight="400"
                                            color="#6E6E6E"
                                          >
                                            Taxes
                                          </SpText>
                                        </Col>
                                        <Col>
                                          <SpText
                                            fontSize="16px"
                                            fontWeight="400"
                                            color="#6E6E6E"
                                          >
                                            {
                                              (data.taxData && data.taxData[0].percentage) ? (
                                                `${data.taxData && data.taxData[0].percentage}% `
                                              ) : <>&#8211;</>
                                            }
                                          </SpText>
                                        </Col>
                                        <Col>
                                          <SpText
                                            fontSize="16px"
                                            fontWeight="400"
                                            color="#6E6E6E"
                                          >
                                            {
                                              (data && data.taxAmount) ? (
                                                `$${((data.taxAmount) / 100)} `
                                              ) : <>&#8211;</>
                                            }
                                          </SpText>
                                        </Col>
                                      </Row>
                                    </div>
                                  </>
                                ) : ''
                              }
                              {
                                (data.lineItemDiscounts && data.lineItemDiscounts.length !== 0) ? (
                                  <>
                                    <div>
                                      <Row type="flex" className="mt-1" justify="space-between">
                                        <Col className="ml-2">
                                          <SpText
                                            fontSize="16px"
                                            fontWeight="700"
                                            color="#FF4B51"
                                          >
                                            {
                                              data.discountData && data.discountData[0].name
                                                ? data.discountData && data.discountData[0].name
                                                : 'Discount'
                                            }
                                          </SpText>
                                        </Col>
                                        <Col>
                                          <SpText
                                            fontSize="16px"
                                            fontWeight="700"
                                            color="#FF4B51"
                                          >
                                            {getDiscountAmount((Number(data.discountAmount) || '0') * (Number(data.quantity) || '0'))}
                                          </SpText>
                                        </Col>
                                      </Row>
                                    </div>
                                  </>
                                ) : ''
                              }
                            </div>
                          </>
                        ))
                      )
                    }
                    <Hr />
                    {
                      receipts && receipts.subTotal && (
                        <Row type="flex" className="mt-3" justify="space-between">
                          <Col>
                            <SpText fontSize="16px" fontWeight="500" color="#000000">
                              Subtotal
                            </SpText>
                          </Col>
                          <Col>
                            <SpText fontSize="16px" fontWeight="500" color="#000000">
                              {
                                (receipts && receipts.subTotal) ? (
                                  `$${((receipts.subTotal) / 100)
                                    .toFixed(2)
                                  } `
                                ) : <>&#8211;</>
                              }
                            </SpText>
                          </Col>
                        </Row>
                      )
                    }
                    {
                      (receipts && receipts.inventoryOrder && (receipts.inventoryOrder.totalServiceFee !== 0)) ? (
                        <Row type="flex" justify="space-between">
                          <Col>
                            <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                              Service Charge
                            </SpText>
                          </Col>
                          <Col>
                            <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                              {
                                (receipts && receipts.inventoryOrder && receipts.inventoryOrder.totalServiceFee) ? (
                                  `$${((receipts.inventoryOrder.totalServiceFee) / 100)
                                    .toFixed(2)
                                  } `
                                ) : <>&#8211;</>
                              }
                            </SpText>
                          </Col>
                        </Row>
                      ) : (
                        <Row type="flex" justify="space-between">
                          <Col>
                            <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                              Service Charge
                            </SpText>
                          </Col>
                          <Col>
                            <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                              $0.00
                            </SpText>
                          </Col>
                        </Row>
                      )
                    }
                    {
                      (receipts && (receipts.totalTax !== 0)) ? (
                        <Row type="flex" justify="space-between">
                          <Col>
                            <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                              Taxes
                            </SpText>
                          </Col>
                          <Col>
                            <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                              {
                                (receipts && receipts.totalTax) ? (
                                  `$${((receipts.totalTax) / 100)
                                    .toFixed(2)
                                  } `
                                ) : <>&#8211;</>
                              }
                            </SpText>
                          </Col>
                        </Row>
                      ) : (
                        <Row type="flex" justify="space-between">
                          <Col>
                            <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                              Taxes
                            </SpText>
                          </Col>
                          <Col>
                            <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                              $0.00
                            </SpText>
                          </Col>
                        </Row>
                      )
                    }
                    {
                      (receipts && (receipts.totalTip !== 0)) ? (
                        <Row type="flex" justify="space-between">
                          <Col>
                            <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                              Tip
                            </SpText>
                          </Col>
                          <Col>
                            <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                              {
                                (receipts && receipts.totalTip) ? (
                                  `$${((receipts.totalTip) / 100)
                                    .toFixed(2)
                                  } `
                                ) : <>&#8211;</>
                              }
                            </SpText>
                          </Col>
                        </Row>
                      ) : (
                        <Row type="flex" justify="space-between">
                          <Col>
                            <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                              Tip
                            </SpText>
                          </Col>
                          <Col>
                            <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                              $0.00
                            </SpText>
                          </Col>
                        </Row>
                      )
                    }
                    {
                      (receipts && receipts.inventoryOrder && (receipts.inventoryOrder.orderDiscountAmount)) ? (
                        <Row type="flex" justify="space-between">
                          <Col>
                            <SpText fontSize="16px" fontWeight="400" color="#FF4B51">
                              Order Discount
                            </SpText>
                          </Col>
                          <Col>
                            <SpText fontSize="16px" fontWeight="400" color="#FF4B51">
                              {getDiscountAmount(receipts && receipts.inventoryOrder && (receipts.inventoryOrder.orderDiscountAmount))}
                            </SpText>
                          </Col>
                        </Row>
                      ) : null
                    }
                    {
                      receipts && receipts.total && (
                        <Row type="flex" className="mt-3" justify="space-between">
                          <Col>
                            <SpText fontSize="24px" fontWeight="600" color="#4C51C2">
                              <div
                                style={{
                                  lineHeight: '60px',
                                }}
                              >
                                Total
                              </div>
                            </SpText>
                          </Col>
                          <Col>
                            <div
                              style={{
                                display: 'flex',
                              }}
                            >
                              <img
                                src={Dollorimg}
                                alt=""
                                style={{
                                  marginTop: '-5px',
                                  paddingRight: '10px',
                                }}
                              />
                              <div
                                style={{
                                  fontFamily: 'Roboto',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  fontSize: '36px',
                                  lineHeight: '70px',
                                  color: '#4C51C2',
                                  paddingRight: '5px',
                                }}
                              >
                                {getAmountDisplay(receipts && receipts.total)}
                              </div>
                              <div
                                style={{
                                  fontFamily: 'Roboto',
                                  fontStyle: 'normal',
                                  fontWeight: 400,
                                  fontSize: '24px',
                                  lineHeight: '65px',
                                  color: '#4C51C2',
                                }}
                              >
                                {getDecimalDisplay(receipts && receipts.total)}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )
                    }
                    <Hr />
                    {
                      (receipts.cloverPayments && receipts.cloverPayments.length !== 0) && (
                        receipts.cloverPayments.map((payments, idx) => (
                          <div key={idx}>
                            {payments && payments.paymentSession ? (
                              <>
                                {
                                  (receipts.cloverPayments.length >= 2) && (
                                    <Row type="flex" className="mt-3" justify="space-between">
                                      <Col>
                                        <SpText fontSize="16px" fontWeight="500" color="#000000">Split payment</SpText>
                                      </Col>
                                    </Row>
                                  )
                                }
                                <Row type="flex" className="mt-3" justify="space-between">
                                  <Col span={14}>
                                    <Row type="flex" justify="space-between">
                                      <Col>
                                        <CardSchemeIcon scheme={(payments.scheme) || null} />
                                      </Col>
                                      <Col className="mr-4" span={14}>
                                        <Col>
                                          <SpText fontSize="16px" fontWeight="500" color="#000000">
                                            {
                                              (payments.scheme)
                                            }
                                            {
                                              (payments.paymentSession) ? (
                                                // eslint-disable-next-line max-len
                                                getpaymentMethod((payments.paymentSession && payments.paymentSession.method))
                                              ) : <>&#8211;</>
                                            }
                                          </SpText>
                                        </Col>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col>
                                    <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                      {
                                        (payments.paymentSession) ? (
                                          `$${((payments.paymentSession.paidAmount
                                            ? payments.paymentSession.paidAmount : payments.paymentSession.amount) / 100)
                                            .toFixed(2)
                                          } `
                                        ) : <>&#8211;</>
                                      }
                                    </SpText>
                                  </Col>
                                </Row>
                                {/* <Row className="mb-3 ml-4">
                            <Col className="ml-2">
                              <SpText fontSize="14px" fontWeight="600" color="#6E6E6E">
                                &nbsp;&nbsp;Cashier: {
                                  (payments.employeeId) || <>&#8211;</>
                                }
                              </SpText>
                            </Col>
                          </Row> */}
                              </>
                            ) : payments && payments.cashTransaction ? (
                              <>
                                {
                                  (receipts.cloverPayments.length >= 2) && (
                                    <Row type="flex" className="mt-3" justify="space-between">
                                      <Col>
                                        <SpText fontSize="16px" fontWeight="500" color="#000000">Split payment</SpText>
                                      </Col>
                                    </Row>
                                  )
                                }
                                <Row type="flex" className="mt-3" justify="space-between">
                                  <Col>
                                    <img style={{ height: '35px' }} src={CashIcon} alt="" />
                                  </Col>
                                  <Col className="mr-4" span={14}>
                                    <Col>
                                      <SpText fontSize="16px" fontWeight="500" color="#000000">
                                        CASH GIVEN
                                      </SpText>
                                    </Col>
                                  </Col>
                                  <Col>
                                    <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                      {
                                        (payments.cashTransaction) ? (
                                          `$${((payments.cashTransaction.amount) / 100)
                                            .toFixed(2)
                                          } `
                                        ) : <>&#8211;</>
                                      }
                                    </SpText>
                                  </Col>
                                </Row>
                              </>
                            ) : payments && payments.checkTransaction ? (
                              <>
                                {
                                  (receipts.cloverPayments.length >= 2) && (
                                    <Row type="flex" className="mt-3" justify="space-between">
                                      <Col>
                                        <SpText fontSize="16px" fontWeight="500" color="#000000">Split payment</SpText>
                                      </Col>
                                    </Row>
                                  )
                                }
                                <Row type="flex" className="mt-3" justify="space-between">
                                  <Col>
                                    <img style={{ height: '35px' }} src={CashIcon} alt="" />
                                  </Col>
                                  <Col className="mr-4" span={14}>
                                    <Col>
                                      <SpText fontSize="16px" fontWeight="500" color="#000000">
                                        CHEQUE
                                      </SpText>
                                    </Col>
                                  </Col>
                                  <Col>
                                    <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                      {
                                        (payments.checkTransaction) ? (
                                          `$${((payments.checkTransaction.amount) / 100)
                                            .toFixed(2)
                                          } `
                                        ) : <>&#8211;</>
                                      }
                                    </SpText>
                                  </Col>
                                </Row>
                              </>
                            ) : ''}
                            {receipts.cloverPayments.length > 0 && receipts.cloverPayments.length !== (idx + 1) ? <Hr /> : null}
                          </div>
                        ))
                      )
                    }

                    <img className="mt-3" src={PosLine} alt="" />
                    <div
                      style={{
                        marginTop: '10px',
                        textAlign: 'center',
                      }}
                    >
                      <Row>
                        <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                          {
                            (receipts.cloverPayments && receipts.cloverPayments[0].paymentSession
                              && receipts.cloverPayments[0].paymentSession.paymentDate
                              ? formatDate(receipts.cloverPayments[0].paymentSession.paymentDate, receipts.account && receipts.account.timezone)
                              : receipts.cloverPayments && receipts.cloverPayments[0].cashTransaction
                                && receipts.cloverPayments[0].cashTransaction.transactionDate
                                // eslint-disable-next-line max-len
                                ? formatDate(receipts.cloverPayments[0].cashTransaction.transactionDate, receipts.account && receipts.account.timezone)
                                : receipts.cloverPayments && receipts.cloverPayments[0].checkTransaction
                                  && receipts.cloverPayments[0].checkTransaction.transactionDate
                                  // eslint-disable-next-line max-len
                                  ? formatDate(receipts.cloverPayments[0].checkTransaction.transactionDate, receipts.account && receipts.account.timezone) : <>&#8211;</>
                            )
                          }
                        </SpText>
                      </Row>
                      <Row>
                        <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                          Order ID: {
                            (receipts.cloverPayments && receipts.cloverPayments[0].orderId
                              ? receipts.cloverPayments[0].orderId : <>&#8211;</>
                            )
                          }
                        </SpText>
                      </Row>
                      <Row>
                        <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                          Employee ID: {
                            (receipts.cloverPayments && receipts.cloverPayments[0].employeeId
                              ? receipts.cloverPayments[0].employeeId : <>&#8211;</>
                            )
                          }
                        </SpText>
                      </Row>
                      {
                        receipts.cloverPayments.length === 1 && (
                          receipts.cloverPayments && receipts.cloverPayments[0].paymentSession && (
                            <>
                              <Row
                                style={{
                                  textDecorationLine: 'underline',
                                  color: '#3A81ED',
                                  cursor: 'pointer',
                                }}
                                className="mt-4"
                                onClick={() => setViewDetails(!viewDetails)}
                              >
                                <SpText fontSize="16px" fontWeight="400" color="#3A81ED">
                                  {viewDetails ? 'Hide Details' : 'Show Details'}
                                </SpText>
                              </Row>
                              {
                                viewDetails && (
                                  <>
                                    <Row>
                                      <SpText fontSize="16px" fontWeight="500" color="#000000">
                                        {
                                          // eslint-disable-next-line max-len
                                          (receipts.cloverPayments && receipts.cloverPayments[0].paymentSession && receipts.cloverPayments[0].paymentSession.paymentType)
                                            ? `${receipts.cloverPayments[0].paymentSession.paymentType}: `
                                            : ''
                                        }
                                        {
                                          (receipts.cloverPayments && receipts.cloverPayments[0]) ? (
                                            // eslint-disable-next-line max-len
                                            (receipts.cloverPayments[0].scheme)
                                          ) : null
                                        }
                                        {
                                          (receipts.cloverPayments && receipts.cloverPayments[0].paymentSession) ? (
                                            // eslint-disable-next-line max-len
                                            getpaymentMethod((receipts.cloverPayments && receipts.cloverPayments[0].paymentSession && receipts.cloverPayments[0].paymentSession.method))
                                          ) : <>&#8211;</>
                                        }
                                      </SpText>
                                    </Row>
                                    {/* <Row>
                                <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                  {
                                    (receipts.cloverPayments && receipts.cloverPayments[0].paymentSession
                                      && receipts.cloverPayments[0].paymentSession.paymentDate
                                      ? formatDate(receipts.cloverPayments[0].paymentSession.paymentDate)
                                      : receipts.cloverPayments && receipts.cloverPayments[0].cashTransaction
                                        && receipts.cloverPayments[0].cashTransaction.transactionDate
                                        ? formatDate(receipts.cloverPayments[0].cashTransaction.transactionDate) : <>&#8211;</>
                                    )
                                  }
                                </SpText>
                              </Row> */}
                                    <Row>
                                      <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                        Auth ID: {
                                          (receipts.cloverPayments && receipts.cloverPayments[0].authCode
                                            ? receipts.cloverPayments && receipts.cloverPayments[0].authCode : <>&#8211;</>
                                          )
                                        }
                                      </SpText>
                                    </Row>
                                    <Row>
                                      <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                        Reference ID: {
                                          (receipts.cloverPayments && receipts.cloverPayments[0].paymentId
                                            ? receipts.cloverPayments && receipts.cloverPayments[0].paymentId : <>&#8211;</>
                                          )
                                        }
                                      </SpText>
                                    </Row>
                                    <Row>
                                      <SpText fontSize="16px" fontWeight="400" color="#6E6E6E">
                                        CVM: Signature verified
                                      </SpText>
                                    </Row>
                                  </>
                                )
                              }
                            </>
                          )
                        )
                      }
                    </div>
                  </PosWrapper>
                </Wrapper>
              </>
            ) : <Empty style={{ height: '100vh', paddingTop: '40px' }} />}
          </>
        ) : (
          <SpText fontSize="16px" fontWeight="500" color="#000000" style={{ padding: '10px' }}>
            {redirectUrl}
          </SpText>
        )
      }
    </>

  );
};

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
  receipts: state.cloverReceipts,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCloverReceipts: gid => dispatch(cloverReceiptsActions.fetchCloverReceipts({
    payload: gid,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Receipt);
