// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
} from 'antd';
import { useLocation } from 'react-router-dom';
import { createSelector } from 'reselect';
import _get from 'lodash.get';

import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import formatAmount from 'utils/formatAmount';

import getPaymentBackground from 'utils/getPaymentBackground';
import getPaymentStatus from 'utils/getPaymentStatus';
import {
  SpText,
  SpH5,
  SpButton,
  SpForm,
  SpStatusTag,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  paymentDetailsAction,
  paymentsActions,
  globalRestrictedKeyActions,
} from 'store/actions';
import {
  PAYMENT_TYPES,
  PAGES,
  ROLES,
  API_STATUS,
  COUNTRIES,
  ACQUIRER_TYPES,
} from 'appconstants';

import TimelineBar from 'components/TimelineBar';
import CardType from 'components/PaymentDetails/Card';
import Upi from 'components/PaymentDetails/Upi';
import Ach from 'components/PaymentDetails/Ach';
import NetBanking from 'components/PaymentDetails/NetBanking';
import RBAC from 'components/rbac';
import RefundPayment from './component/RefundPayment';
import ResolveDispute from './component/ResolveDispute';
import UpdatePayment from '../PaymentList/components/UpdatePayment';

type Props = {
  fetchPaymentDetails: Function,
  fetchPaymentTimeline: Function,
  refundPaymentDetails: Function,
  notifyPaymentDetails: Function,
  fetchGlobalRestrictedKey: Function,
  acceptDisputeEvidence: Function,
  resolveDisputeEvidence: Function,
  paymentDetails: Object,
  paymentTimeline: Array<Object>,
  globalRestrictedKey: Array<Object>,
  loading: boolean,
  test: boolean,
  submitting: boolean,
  validator: boolean,
  match: Object,
  params: Object,
  updatePayment: Function,
  selectedAccount: Object,
  history: {
    push: Function,
    location: Object,
  };
  paymentDetailsStatus: string,
  displayAmount: Object,
}

const amountFilter = (paymentDetails) => paymentDetails;

const displayAmountSelector = createSelector(
  [amountFilter],
  (paymentDetails) => ({
    prefix: _get(paymentDetails, 'currency.prefix', null),
    amountDisplay:
      formatNumber(paymentDetails.amount && (paymentDetails.amount / 100).toFixed(paymentDetails.currency.toFixed), paymentDetails.currency),
    paidAmountDisplay:
      formatNumber(paymentDetails.paidAmount && (paymentDetails.paidAmount / 100).toFixed(paymentDetails.currency.toFixed), paymentDetails.currency),
    feeDisplay:
      formatNumber(
        (paymentDetails.feeAmount || paymentDetails.feeTax) && (paymentDetails.feeAmount / 100 + paymentDetails.feeTax / 100).toFixed(2),
        paymentDetails.currency,
      ),
    netDisplay: formatNumber(paymentDetails.net && (paymentDetails.net / 100).toFixed(2), paymentDetails.currency),
    tipDisplay: formatNumber(paymentDetails.tip && (paymentDetails.tip / 100).toFixed(2), paymentDetails.currency),
    taxDisplay: formatNumber(paymentDetails.tax && (paymentDetails.tax / 100).toFixed(2), paymentDetails.currency),
    serviceFeeDisplay: formatNumber(paymentDetails.serviceFee && (paymentDetails.serviceFee / 100).toFixed(2), paymentDetails.currency),
    refundDisplay:
      formatNumber(
        paymentDetails.amountRefunded && (paymentDetails.amountRefunded / 100).toFixed(paymentDetails.currency.toFixed),
        paymentDetails.currency,
      ),
    refundAmount: (paymentDetails.amount && paymentDetails.amountRefunded)
      ? formatNumber(((paymentDetails.amount / 100) - (paymentDetails.amountRefunded / 100)), paymentDetails.currency)
      : formatNumber(paymentDetails.amount / 100, paymentDetails.currency),
  }),
);

const PaymentDetails = (props: Props) => {
  const {
    fetchPaymentDetails,
    fetchPaymentTimeline,
    fetchGlobalRestrictedKey,
    refundPaymentDetails,
    notifyPaymentDetails,
    acceptDisputeEvidence,
    resolveDisputeEvidence,
    paymentDetails,
    paymentTimeline,
    globalRestrictedKey,
    updatePayment,
    loading,
    test,
    submitting,
    validator,
    history,
    selectedAccount,
    paymentDetailsStatus,
    displayAmount,
  } = props;


  const location = useLocation();
  const [refundModal, setRefundModal] = useState(false);
  const [resolveModal, setResolveModal] = useState(false);
  const [notify, setNotify] = useState(false);
  const [updatePaymentModal, setUpdatePaymentModal] = useState(false);
  const { gid } = props.match.params;
  const gidPath = gid.split('-')[1];
  const getApiKey = (globalRestrictedKey) ? globalRestrictedKey.filter((data) => data.isEnabled) : '';
  const acquirerName = (selectedAccount && selectedAccount.acquirer && selectedAccount.acquirer.name);
  const currencyType = selectedAccount && selectedAccount.currency;
  const isIndia = (selectedAccount && selectedAccount.country && selectedAccount.country.id) === COUNTRIES.INDIA.id;

  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.ADMIN_PAYMENTS);
    }
  }, []);

  useEffect(() => {
    fetchPaymentDetails(gid);
    fetchPaymentTimeline(gid);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    const currentPage = 1;
    const sortBy = 'createdAt';
    const direction = 'DESC';
    const globalParams = `&sortBy=${sortBy}&direction=${direction}&isEnabled.EQUALS=true`;
    fetchGlobalRestrictedKey({ currentPage, globalParams });
  }, []);

  useEffect(() => {
    if (paymentDetails.receiptEmail || paymentDetails.receiptSms) {
      setNotify(true);
    } else {
      setNotify(false);
    }
  }, [paymentDetails]);

  useEffect(() => {
    if (!validator) {
      setUpdatePaymentModal(false);
    }
  }, [validator]);

  useEffect(() => {
    if (!validator) {
      setRefundModal(false);
      setResolveModal(false);
    }
  }, [validator]);

  const submitEvidence = () => {
    history.push(`${PAGES.PAYMENTS}/${gid}/dispute`);
  };

  const updateNewPayment = (paymentInfo: Object) => {
    const {
      batchNumber,
      rrNumber,
      maskedNumber,
      authCode,
      appName,
      mrn,
      cardType,
      amid,
      atid,
    } = paymentInfo;
    const payload = {
      functionCode: '01',
      responseCode: 'Transaction Success',
      batchNo: batchNumber,
      rrn: rrNumber,
      maskedCardNo: maskedNumber,
      authCode,
      appName,
      cardType,
      amid,
      atid,
      mrn,
    };
    const xApiKey = getApiKey && getApiKey[0].apiKey;
    const config = {
      headers: {
        'x-api-key': xApiKey,
      },
    };
    updatePayment({
      payload,
      config,
    });
  };

  const acceptEvidence = () => {
    acceptDisputeEvidence(gid);
  };

  const selectedReceipts = (record) => {
    const id = record;
    window.open(`${PAGES.RECEIPT}/${id}`);
  };

  const selectedNotify = () => {
    notifyPaymentDetails(gid);
  };

  const submitRefund = (refund: Object) => {
    const {
      amount,
      reason,
      description,
      note,
    } = refund;
    const currency = amount.replace(/[^\d]/g, '');
    const params = {
      description: `Partial Refund For Charge ${description}`,
      refundReason: reason,
      amountRefunded: Number(currency),
      refundNote: note,
    };
    refundPaymentDetails({
      gid,
      params,
    });
  };
  const submitResolve = (resolve: Object) => {
    const {
      status,
    } = resolve;
    const params = {
      disputeStatus: status,
    };
    resolveDisputeEvidence({
      gid,
      params,
    });
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {
        paymentDetails.gid && paymentDetailsStatus === API_STATUS.SUCCESS && (
          <>
            <Card>
              <Row type="flex" justify="space-between" className="my-2 mb-3 mt-2">
                <Col className="d-flex align-items-center">
                  <SpText className="mr-5" fontSize="20px" fontWeight="600">
                    {`${displayAmount.prefix}${displayAmount.amountDisplay}`}
                  </SpText>
                  <SpStatusTag style={{ backgroundColor: getPaymentBackground(paymentDetails.status) }}>
                    {getPaymentStatus(paymentDetails.status)}
                  </SpStatusTag>
                </Col>
                <Col>
                  {
                    notify && (
                      // eslint-disable-next-line max-len
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.ANALYST, ROLES.SUPPORT, ROLES.POS, ROLES.SUPERUSER]}>
                        <SpButton
                          type="secondary"
                          shape="round"
                          className="mr-4"
                          ghost
                          onClick={selectedNotify}
                        >
                          Notify
                        </SpButton>
                      </RBAC>
                    )
                  }
                  {
                    (paymentDetails.status === PAYMENT_TYPES.REQUIRE_PAYMENT_METHOD) && (acquirerName === ACQUIRER_TYPES.IMSPOS) && (
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <SpButton
                          type="secondary"
                          shape="round"
                          ghost
                          onClick={() => setUpdatePaymentModal(true)}
                        >
                          Update Payment
                        </SpButton>
                      </RBAC>
                    )
                  }
                  {
                    (paymentDetails.status === PAYMENT_TYPES.SUCCEEDED || paymentDetails.status === PAYMENT_TYPES.PARTIAL_REFUNDED)
                    && (acquirerName !== ACQUIRER_TYPES.IMSPOS)
                    && (
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <SpButton
                          type="secondary"
                          shape="round"
                          ghost
                          onClick={() => setRefundModal(true)}
                        >
                          Refund
                        </SpButton>
                      </RBAC>
                    )
                  }
                  {
                    paymentDetails.status === PAYMENT_TYPES.DISPUTED_RESPONSE && (
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <SpButton
                          type="secondary"
                          shape="round"
                          className="mr-4"
                          onClick={submitEvidence}
                        >
                          Submit Evidence
                        </SpButton>
                      </RBAC>
                    )
                  }
                  {
                    paymentDetails.status === PAYMENT_TYPES.DISPUTED_REVIEW && (
                      <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                        <SpButton
                          type="secondary"
                          shape="round"
                          onClick={acceptEvidence}
                        >
                          Accept Dispute
                        </SpButton>
                      </RBAC>
                    )
                  }
                  {
                    paymentDetails.status === PAYMENT_TYPES.DISPUTED_REVIEW && (
                      <RBAC permit={[ROLES.SUPERUSER]}>
                        <SpButton
                          type="secondary"
                          className="mr-4 ml-4"
                          shape="round"
                          onClick={submitEvidence}
                        >
                          Review Evidence
                        </SpButton>
                      </RBAC>
                    )
                  }
                  {
                    paymentDetails.status === PAYMENT_TYPES.DISPUTED_REVIEW && (
                      <RBAC permit={[ROLES.SUPERUSER]}>
                        <SpButton
                          type="primary"
                          shape="round"
                          onClick={() => setResolveModal(true)}
                        >
                          Resolve Dispute
                        </SpButton>
                      </RBAC>
                    )
                  }
                </Col>

              </Row>
              <SpForm>
                <Row type="flex">
                  <Col span={6}>
                    <div>
                      <span className="mr-3">Date</span>
                    </div>
                    <div>
                      <SpText fontSize="18px" fontWeight="600">
                        {
                          paymentDetails.paymentDate && selectedAccount && selectedAccount.timezone
                            ? (
                              moment
                                .utc(paymentDetails.paymentDate)
                                .tz(selectedAccount.timezone)
                                .format('MMM DD, YYYY, hh:mm a')
                            )
                            : <>&#8211;</>
                        }
                      </SpText>
                    </div>
                  </Col>
                  {paymentDetails.paymentMethod && paymentDetails.paymentMethod.card
                    ? <CardType card={paymentDetails.paymentMethod.card} />
                    : paymentDetails.paymentMethod && paymentDetails.paymentMethod.upi
                      ? <Upi card={paymentDetails.paymentMethod.upi} />
                      : paymentDetails.paymentMethod && paymentDetails.paymentMethod.bank
                        ? <Ach bank={paymentDetails.paymentMethod.bank} />
                        : paymentDetails.paymentMethod && paymentDetails.paymentMethod.netbanking
                          ? <NetBanking bank={paymentDetails.paymentMethod.netbanking} />
                          : paymentDetails.paymentMethod && paymentDetails.paymentMethod.paymentType
                            ? (
                              <Col span={9}>
                                <div>
                                  <span>Payment Method</span>
                                </div>
                                <div>
                                  {
                                    (paymentDetails.paymentMethod.paymentType && paymentDetails.paymentMethod.paymentType.name) === 'QR'
                                      ? <SpText fontSize="18px" fontWeight="600">QR</SpText> : <>&#8211;</>
                                  }
                                </div>
                              </Col>
                            ) : (
                              <Col span={9}>
                                <div>
                                  <span>Payment Method</span>
                                </div>
                                <div>
                                  <>&#8211;</>
                                </div>
                              </Col>
                            )}
                  {paymentDetails && paymentDetails.customer
                    ? (
                      <Col span={9}>
                        <div>
                          <span>Customer</span>
                        </div>
                        <div>
                          <SpText fontSize="18px" fontWeight="600">{paymentDetails.customer && paymentDetails.customer.name}</SpText>
                        </div>
                      </Col>
                    ) : ''}
                </Row>
              </SpForm>
              <hr />
              <SpForm>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Amount</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>
                      {`${displayAmount.prefix}${displayAmount.amountDisplay}`}
                    </SpH5>
                  </Col>
                </Row>
                {
                  paymentDetails.mdr !== 'CUSTOMER_MDR_PRE_CALC' && (
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Paid Amount</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>
                          {paymentDetails.paidAmount < 0
                            ? `(${displayAmount.prefix}${displayAmount.paidAmountDisplay.replace(/-/g, '')})`
                            : paymentDetails.paidAmount > 0 ? `${displayAmount.prefix}${displayAmount.paidAmountDisplay}` : <>&#8211;</>}
                        </SpH5>
                      </Col>
                    </Row>
                  )
                }
                {
                  paymentDetails.amountRefunded !== 0 && (
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Refunded</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>
                          {`${displayAmount.prefix}${displayAmount.refundDisplay}`}
                        </SpH5>
                      </Col>
                    </Row>
                  )
                }
                {
                  !isIndia && (
                    <>
                      {
                        paymentDetails.feeAmount !== 0 && (
                          <Row type="flex" justify="start">
                            <Col span={4}>
                              <span>Fee</span>
                            </Col>
                            <Col span={9}>
                              <SpH5>
                                {paymentDetails.feeAmount < 0
                                  ? `(${displayAmount.prefix}${displayAmount.feeDisplay.replace(/-/g, '')})`
                                  : paymentDetails.feeAmount > 0 ? `${displayAmount.prefix}${displayAmount.feeDisplay}` : <>&#8211;</>}
                              </SpH5>
                            </Col>
                          </Row>
                        )
                      }
                      {
                        paymentDetails.feeAmount === 0 && (
                          <Row type="flex" justify="start">
                            <Col span={4}>
                              <span>Fee</span>
                            </Col>
                            <Col span={9}>
                              <SpH5>
                                {`${displayAmount.prefix}0.00`}
                              </SpH5>
                            </Col>
                          </Row>
                        )
                      }
                      {
                        paymentDetails.net !== 0 && (
                          <Row type="flex" justify="start">
                            <Col span={4}>
                              <span>Net</span>
                            </Col>
                            <Col span={9}>
                              <SpH5>
                                {paymentDetails.net < 0
                                  ? `(${displayAmount.prefix}${displayAmount.netDisplay.replace(/-/g, '')})`
                                  : paymentDetails.net > 0 ? `${displayAmount.prefix}${displayAmount.netDisplay}` : <>&#8211;</>}
                              </SpH5>
                            </Col>
                          </Row>
                        )
                      }
                      {
                        paymentDetails.net === 0 && (
                          <Row type="flex" justify="start">
                            <Col span={4}>
                              <span>Net</span>
                            </Col>
                            <Col span={9}>
                              <SpH5>
                                {`${displayAmount.prefix}0.00`}
                              </SpH5>
                            </Col>
                          </Row>
                        )
                      }
                    </>
                  )
                }
                {
                  paymentDetails.serviceFee !== 0 && (
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Service Fee</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>
                          {`${displayAmount.prefix}${displayAmount.serviceFeeDisplay}`}
                        </SpH5>
                      </Col>
                    </Row>
                  )
                }
                {
                  paymentDetails.tax !== 0 && (
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Tax</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>
                          {`${displayAmount.prefix}${displayAmount.taxDisplay}`}
                        </SpH5>
                      </Col>
                    </Row>
                  )
                }
                {
                  paymentDetails.tip !== 0 && (
                    <Row type="flex" justify="start">
                      <Col span={4}>
                        <span>Tip</span>
                      </Col>
                      <Col span={9}>
                        <SpH5>
                          {`${displayAmount.prefix}${displayAmount.tipDisplay}`}
                        </SpH5>
                      </Col>
                    </Row>
                  )
                }
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Statement Descriptor</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{(paymentDetails && paymentDetails.statementDescriptor)
                      ? (paymentDetails.statementDescriptor === ' ' ? <>&#8211;</> : paymentDetails.statementDescriptor) : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Description</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{(paymentDetails && paymentDetails.description)
                      ? (paymentDetails.description === ' ' ? <>&#8211;</> : paymentDetails.description) : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
                <Row type="flex" justify="start">
                  <Col span={4}>
                    <span>Auth Code</span>
                  </Col>
                  <Col span={9}>
                    <SpH5>{(paymentDetails && paymentDetails.authCode)
                      ? paymentDetails.authCode : <>&#8211;</>}
                    </SpH5>
                  </Col>
                </Row>
              </SpForm>
              <hr />
              {paymentDetails.paymentMethod && paymentDetails.paymentMethod.card
                ? <CardType cardDetails={paymentDetails.paymentMethod.card} paymentGid={gid} />
                : ''}
              {paymentDetails.paymentMethod && paymentDetails.paymentMethod.upi
                ? <Upi cardDetails={paymentDetails} country={selectedAccount.country.name} paymentGid={gid} />
                : ''}
              {paymentDetails.paymentMethod && paymentDetails.paymentMethod.bank
                ? <Ach bankDetails={paymentDetails.paymentMethod.bank} paymentGid={gid} />
                : ''}
              {
                paymentDetails.paymentMethod && paymentDetails.paymentMethod.netbanking
                  ? <NetBanking bankDetails={paymentDetails.paymentMethod.netbanking} paymentGid={gid} />
                  : ''
              }
              {/* UPDATE PAYMENT MODAL */}
              {updatePaymentModal && (
                <UpdatePayment
                  visible={updatePaymentModal}
                  selectedPayment={paymentDetails}
                  selectedAccount={selectedAccount}
                  submit={updateNewPayment}
                  close={() => setUpdatePaymentModal(false)}
                />
              )}
              {refundModal && (
                <RefundPayment
                  visible={refundModal}
                  submitting={submitting}
                  close={() => setRefundModal(false)}
                  submit={submitRefund}
                  paymentDetails={paymentDetails}
                  refundAmount={formatAmount(displayAmount.refundAmount, currencyType)}
                  selectedAccount={selectedAccount}
                />
              )}
              {resolveModal && (
                <ResolveDispute
                  visible={resolveModal}
                  submitting={submitting}
                  close={() => setResolveModal(false)}
                  submit={submitResolve}
                  paymentDetails={paymentDetails}
                  selectedAccount={selectedAccount}
                />
              )}
            </Card>

            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">TIMELINE</SpText>
                </Col>
              </Row>
              <Row className="pl-3">
                <TimelineBar
                  paymentTimeline={paymentTimeline}
                  paymentDetails={paymentDetails}
                  selectedAccount={selectedAccount}
                />
              </Row>
            </Card>
            {
              paymentDetails.receiptNumber
                ? (
                  <Card className="mb-3">
                    <Row type="flex" justify="space-between" className="my-2">
                      <Col>
                        <SpText className="text-uppercase mb-3" fontSize="20px">Receipt</SpText>
                      </Col>
                      <Col>
                        <SpButton
                          type="secondary"
                          shape="round"
                          className="mr-4"
                          onClick={() => selectedReceipts(paymentDetails.receiptNumber)}
                        >
                          View Receipt
                        </SpButton>
                      </Col>
                    </Row>
                    <SpForm>
                      <Row type="flex" justify="start">
                        <Col span={4}>
                          <span>
                            {
                              paymentDetails.paymentDate && selectedAccount && selectedAccount.timezone
                                ? (
                                  moment
                                    .utc(paymentDetails.paymentDate)
                                    .tz(selectedAccount.timezone)
                                    .format('MMM DD, YYYY, hh:mm a')
                                )
                                : <>&#8211;</>
                            }
                          </span>
                        </Col>
                        <Col span={4}>
                          <span>{`Receipt ${(paymentDetails && paymentDetails.receiptNumber) ? paymentDetails.receiptNumber : <>&#8211;</>}`}</span>
                        </Col>
                      </Row>
                    </SpForm>
                  </Card>
                ) : ''
            }
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  paymentDetails: state.paymentDetails,
  globalRestrictedKey: state.globalRestrictedKey.content,
  paymentDetailsStatus: state.paymentDetails.paymentDetailsStatus,
  paymentTimeline: state.paymentDetails.paymentTimeline,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  displayAmount: displayAmountSelector(state.paymentDetails),
});

const mapDispatchToProps = (dispatch) => ({
  fetchPaymentDetails: gid => dispatch(paymentDetailsAction.fetchPaymentDetails({
    payload: gid,
  })),
  fetchPaymentTimeline: gid => dispatch(paymentDetailsAction.fetchPaymentTimeline({
    payload: gid,
  })),
  refundPaymentDetails: param => dispatch(paymentDetailsAction.refundPaymentDetails({
    payload: param,
  })),
  notifyPaymentDetails: gid => dispatch(paymentDetailsAction.notifyPaymentDetails({
    payload: gid,
  })),
  acceptDisputeEvidence: gid => dispatch(paymentDetailsAction.acceptDisputeEvidence({
    payload: gid,
  })),
  resolveDisputeEvidence: param => dispatch(paymentDetailsAction.resolveDisputeEvidence({
    payload: param,
  })),
  updatePayment: param => dispatch(paymentsActions.updatePayment({
    payload: param,
  })),
  fetchGlobalRestrictedKey: param => dispatch(globalRestrictedKeyActions.fetchGlobalRestrictedKey({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
