// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Badge,
  Icon,
} from 'antd';
import {
  SpButton,
  SpStatusTag,
  FilterWrapper,
  SpText,
} from 'components/DesignKit';
import getBulkTransferBackground from 'utils/getBulkTransferBackground';
import getBulkTransferStatus from 'utils/getBulkTransferStatus';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';
import getFilterOperators from 'utils/getFilterOperators';

import {
  bulkTransferActions,
  transferSettingsActions,
  filterParamsActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
  FILTER_STATUSES,
} from 'appconstants';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import RBAC from 'components/rbac';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import UploadBulk from './components/UploadBulk';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const BULKTRANSFER_FILTERS_TYPES = FILTERS_TYPES.BULKTRANSFER;
const bulkTransferTypes = FILTER_STATUSES.BULKTRANSFER;

type Props = {
  bulkTransfer: Array<Object>,
  fetchBulkTransfer: Function,
  fetchTransferSetting: Function,
  transferSettings: object,
  addBulkTransfer: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  submitting: boolean,
  validator: boolean,
  history: {
    push: Function,
  },
  setFilterData: Function,
  preserveFilters: Object,
  isMobileView: Boolean,
};

const BulkTransferList = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    bulkTransfer,
    loading,
    addBulkTransfer,
    fetchBulkTransfer,
    fetchTransferSetting,
    transferSettings,
    totalElements,
    submitting,
    validator,
    test,
    history,
    setFilterData,
    preserveFilters,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const [pagination, setPagination] = useState({});
  const [uploadBulkModal, setUploadBulkModal] = useState(false);
  const myRef = useRef();
  const { pathname } = location;
  const [open, setOpen] = useState(false);
  const [filterTypes, setFilterTypes] = useState(BULKTRANSFER_FILTERS_TYPES);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterTransfers, setFilterTransfers] = useState((preserveFilters && preserveFilters.filterTransfers)
    ? (preserveFilters && preserveFilters.filterTransfers)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  const [lastFour, setLastFour] = useState('');
  const [otpRequires, setOtpRequires] = useState('');
  const [supportsSchedule, setSupportsSchedule] = useState('');
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterTransfers: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });

  const exportData = bulkTransfer ? bulkTransfer.map((values) => ({
    fileNumber: values.fileNumber,
    fileName: values.fileName,
    transferCount: values.transferCount,
    status: getBulkTransferStatus(values.status),
    createdAt: moment
      .utc(values.createdAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a'),
    processedAt: values.processedAt ? moment
      .utc(values.processedAt)
      .tz(selectedAccount.timezone)
      .format('MMM DD, YYYY, hh:mm a') : '',
  })) : '';

  const exportHeaders = [
    { label: 'FILE NUMBER', key: 'fileNumber' },
    { label: 'FILE NAME', key: 'fileName' },
    { label: 'TOTAL TRANSFER', key: 'transferCount' },
    { label: 'STATUS', key: 'status' },
    { label: 'ADDED ON', key: 'createdAt' },
    { label: 'PROCESSED ON', key: 'processedAt' },
  ];

  const debouncedFetchBulkTransfer = debounce(fetchBulkTransfer, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'createdAt';
    const direction = parsed.direction || 'DESC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    debouncedFetchBulkTransfer({ currentPage, sortParams, filterParams });
    fetchTransferSetting({ sortParams });
  };

  useEffect(() => {
    // This call is happennig for test mode and if it was live we should have issuer.
    if (test) {
      getData(location.search);
    } else if (selectedAccount && selectedAccount.issuer) {
      getData(location.search);
    }
    if (selectedAccount && selectedAccount.country) {
      setPhoneCode(getCountryPhoneCode(selectedAccount.country.id));
    }
    if (selectedAccount && selectedAccount.contactNumber) {
      setLastFour((selectedAccount.contactNumber).slice(-4));
    }
    if (selectedAccount && selectedAccount.issuer) {
      setOtpRequires(selectedAccount.issuer.requiresOtp);
      setSupportsSchedule(selectedAccount.issuer.supportsSchedule);
    }
  }, [location, test, selectedAccount]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    if (!validator) {
      getData();
      setUploadBulkModal(false);
    }
  }, [validator]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [bulkTransfer]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const addNewBulkTransfer = (bulkFileInfo: Object) => {
    const {
      name,
      bulkTransferFile,
      otp,
      UniqueId,
      transferApiDate,
    } = bulkFileInfo;

    if (otpRequires) {
      addBulkTransfer({
        fileUrl: bulkTransferFile,
        fileName: name,
        otp,
        uniqueId: UniqueId,
        scheduleDate: transferApiDate || null,
      });
    } else if ((transferSettings && transferSettings.approvalEmail)) {
      addBulkTransfer({
        fileUrl: bulkTransferFile,
        fileName: name,
        otp,
        uniqueId: UniqueId,
        scheduleDate: transferApiDate || null,
      });
    } else {
      addBulkTransfer({
        fileUrl: bulkTransferFile,
        fileName: name,
        scheduleDate: transferApiDate || null,
      });
    }
  };

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    const tempPage = page.current;
    let tempParams = '';
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.BULKTRANSFER,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.BULKTRANSFER,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  const selectedRecord = (record) => {
    const bulkTransferGid = record.gid;
    history.push({
      pathname: `${PAGES.BULKTRANSFER}/${bulkTransferGid}`,
      state: location.search,
    });
  };

  const onCheck = (e, index) => {
    const list = [...filterTransfers];
    if (filterTransfers.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = BULKTRANSFER_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterTransfers(list);
  };

  const handleChange = (e, index) => {
    const list = [...filterTransfers];
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'transferCount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = BULKTRANSFER_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterTransfers(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterTransfers];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterTransfers(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterTransfers(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterTransfers];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterTransfers(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterTransfers(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterTransfers[index].filterName === 'createdAt') {
      if (filterTransfers[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).startOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterTransfers[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterTransfers[index].filterOperator === 'GT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else if (filterTransfers[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterTransfers];
    list[index].filterValue = selectedValue;
    setFilterTransfers(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterTransfers];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterTransfers[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterTransfers(list);
  };

  const handleAddClick = () => {
    const list = [...filterTransfers];
    setFilterTransfers([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterTransfers([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(BULKTRANSFER_FILTERS_TYPES);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.BULKTRANSFER);
  };

  const applyFilters = () => {
    const transferFilters = filterTransfers.filter((item) => item.checked);
    for (let i = 0; i < transferFilters.length; i += 1) {
      if (transferFilters[i].filterOperator === 'BT') {
        if (!transferFilters[i].filterName
          || !transferFilters[i].filterOperator || !transferFilters[i].filterValueFrom
          || !transferFilters[i].filterValueTo) {
          return;
        }
      } else if (!transferFilters[i].filterName || !transferFilters[i].filterOperator || !transferFilters[i].filterValue) {
        return;
      }
    }
    if (transferFilters[0].filterValue || (transferFilters[0].filterValueFrom && transferFilters[0].filterValueTo)) {
      setCount(transferFilters && transferFilters.length);
      for (let i = 0; i < transferFilters.length; i += 1) {
        if (transferFilters[i].checked) {
          if (transferFilters[i].filterName === 'createdAt') {
            transferFilters[i] = transferFilters[i].filterValue;
          } else if (transferFilters[i].filterName === 'transferCount' && transferFilters[i].filterOperator === 'BT') {
            const name = transferFilters[i].filterName;
            const from = Number(transferFilters[i].filterValueFrom);
            const to = Number(transferFilters[i].filterValueTo);
            transferFilters[i] = `${name}.AF=${from}&${name}.BF=${to}`;
          } else if (transferFilters[i].filterName === 'transferCount' && transferFilters[i].filterOperator !== 'BT') {
            const value = Number(transferFilters[i].filterValue);
            transferFilters[i] = `${transferFilters[i].filterName}.${transferFilters[i].filterOperator}=${value}`;
          } else if (transferFilters[i].filterName === 'email') {
            const email = encodeURIComponent(transferFilters[i].filterValue);
            transferFilters[i] = `${transferFilters[i].filterName}.${transferFilters[i].filterOperator}=${email}`;
          } else {
            transferFilters[i] = `${transferFilters[i].filterName}.${transferFilters[i].filterOperator}=${transferFilters[i].filterValue}`;
          }
        }
      }
      const filterParams = encodeURIComponent(transferFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'createdAt';
      const direction = parsed.direction || 'DESC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterTransfers,
        });
        history.push({
          pathname: PAGES.BULKTRANSFER,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterTransfers,
        });
        history.push({
          pathname: PAGES.BULKTRANSFER,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };


  const columns = [
    {
      title: 'FILE NUMBER',
      dataIndex: 'fileNumber',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (fileNumber) => (
        <span style={{ cursor: 'pointer' }}>
          {fileNumber || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'FILE NAME',
      dataIndex: 'fileName',
      width: '15%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (fileName) => (
        <span style={{ cursor: 'pointer' }}>
          {fileName || <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'TOTAL TRANSFER',
      dataIndex: 'transferCount',
      width: '18%',
      align: 'left',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (transferCount) => (
        <span style={{ cursor: 'pointer' }}>
          {transferCount || 0}
        </span>
      ),
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      width: '15%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (status) => (
        <SpStatusTag style={{ backgroundColor: getBulkTransferBackground(status) }}>
          {getBulkTransferStatus(status)}
        </SpStatusTag>
      ),
    },
    {
      title: 'ADDED ON',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'descend',
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'PROCESSED ON',
      dataIndex: 'processedAt',
      width: '20%',
      align: 'left',
      render: (processedAt) => {
        if (processedAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(processedAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  if (loading || !selectedAccount) {
    return <Loading />;
  }

  return (
    <>
      {
        ((!test) && (selectedAccount && (!selectedAccount.issuer)))
          ? (
            <Card
              className="mx-auto mt-5"
              style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
              bodyStyle={{ height: '100%' }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
                <Icon
                  type="exclamation-circle"
                  className="mb-4"
                  style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
                />
                <h5>Please go to settings and provide additional business information to enable live transfers</h5>
              </div>
            </Card>
          )
          : (
            <Card>
              <Row className="my-2">
                <div className="d-flex justify-content-end">
                  <RBAC permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}>
                    <Col>
                      <SpButton
                        type="secondary"
                        shape="round"
                        ghost
                        className="mr-3"
                        onClick={() => setUploadBulkModal(true)}
                      >
                        {'\u002B'}&nbsp;Upload Bulk
                      </SpButton>
                      <span>
                        {
                          exportData !== null
                            ? (
                              <Tooltip placement="left" title="Click here to export Bulk Transfer">
                                <CSVLink
                                  target="_blank"
                                  className="mr-3"
                                  filename="Bulk Transfer Report.csv"
                                  data={exportData}
                                  headers={exportHeaders}
                                  onMouseEnter={() => setFillColor(true)}
                                  onMouseLeave={() => setFillColor(false)}
                                >
                                  <ExportIcon
                                    fill={fillColor
                                      ? '#279dfe'
                                      : '#C0C0C0'}
                                  />
                                </CSVLink>
                              </Tooltip>
                            ) : null
                        }
                      </span>
                      <span ref={myRef}>
                        <span
                          onClick={() => setOpen(!open)}
                          style={{ cursor: 'pointer' }}
                          onMouseEnter={() => setFilterColor(true)}
                          onMouseLeave={() => setFilterColor(false)}
                        >
                          {open && (
                            <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                              <FilterIcon
                                fill={filterColor
                                  ? '#279dfe'
                                  : '#C0C0C0'}
                              />
                            </Badge>
                          )}
                          {!open && (
                            <Tooltip placement="left" title="Click here to filter Bulk Transfer">
                              <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                                <FilterIcon
                                  fill={filterColor
                                    ? '#279dfe'
                                    : '#C0C0C0'}
                                />
                              </Badge>
                            </Tooltip>
                          )}
                        </span>
                        {open && (
                          <FilterWrapper
                            style={{ marginLeft: '-80px' }}
                          >
                            <Filters
                              filtersGrid={filterTransfers}
                              filterTypes={filterTypes}
                              handleChange={handleChange}
                              onCheck={onCheck}
                              fiterOperators={fiterOperators}
                              handleOperator={handleOperator}
                              handleValue={handleValue}
                              startDate={startDate}
                              endDate={endDate}
                              currentDate={currentDate}
                              handleFromValue={handleFromValue}
                              handleToValue={handleToValue}
                              stuses={bulkTransferTypes}
                            />
                            <div className="px-4 mt-2">
                              {
                                filterTransfers.length < BULKTRANSFER_FILTERS_TYPES.length && (
                                  <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                                    <SpText
                                      fontWeight="600"
                                      color="#279dfe"
                                      onClick={handleAddClick}
                                    >
                                      +&nbsp; Add another filter
                                    </SpText>
                                  </div>
                                )
                              }
                            </div>
                            <div className="d-flex mb-2 justify-content-end mr-3">
                              <SpButton
                                type="secondary"
                                shape="round"
                                className="mr-2"
                                ghost
                                onClick={resetFilters}
                              >
                                Clear
                              </SpButton>
                              <SpButton
                                type="primary"
                                shape="round"
                                onClick={applyFilters}
                              >
                                Save
                              </SpButton>
                            </div>
                          </FilterWrapper>
                        )}
                      </span>
                    </Col>
                  </RBAC>
                </div>
              </Row>

              {/* UPLOAD BULK MODAL */}
              {uploadBulkModal && (
                <UploadBulk
                  visible={uploadBulkModal}
                  submitting={submitting}
                  phoneCode={phoneCode}
                  lastFour={lastFour}
                  otpRequires={otpRequires}
                  transferSettings={transferSettings}
                  supportsSchedule={supportsSchedule}
                  selectedAccount={selectedAccount}
                  close={() => setUploadBulkModal(false)}
                  submit={addNewBulkTransfer}
                />
              )}
              <RBAC
                fallback={(
                  <Table
                    onRow={(record) => ({
                      onClick: () => {
                        selectedRecord(record);
                      },
                    })}
                    columns={columns}
                    rowKey={(record) => record.gid}
                    dataSource={bulkTransfer}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                )}
                permit={[ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS, ROLES.SUPERUSER]}
              >
                <Table
                  onRow={(record) => ({
                    onClick: () => {
                      selectedRecord(record);
                    },
                  })}
                  rowClassName={() => 'ant-table-clickable-row'}
                  columns={columns}
                  rowKey={(record) => record.gid}
                  dataSource={bulkTransfer}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
              </RBAC>
            </Card>
          )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  bulkTransfer: state.bulkTransfer.content,
  transferSettings: state.transferSettings.content,
  totalElements: state.bulkTransfer.totalElements,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchBulkTransfer: param => dispatch(bulkTransferActions.fetchBulkTransfer({
    payload: param,
  })),
  fetchTransferSetting: param => dispatch(transferSettingsActions.fetchTransferSetting({
    payload: param,
  })),
  addBulkTransfer: param => dispatch(bulkTransferActions.addBulkTransfer({
    payload: param,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(BulkTransferList);
