import { BUSINESS } from '../actionTypes';

const initialState = {
  business: {},
  businessCategories: [],
  businessRepresentatives: [],
  businessType: '',
  businessDocusign: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BUSINESS.BUSINESS_CATEGORIES_SUCCESS:
      return {
        ...state,
        businessCategories: action.payload,
      };
    case BUSINESS.BUSINESS_REPRESENTATIVE_SUCCESS:
      return {
        ...state,
        businessRepresentatives: action.payload,
      };
    case BUSINESS.EDIT_BUSINESS_SUCCESS:
      return {
        ...state,
        business: action.payload,
      };
    case BUSINESS.BUSINESS_REPRESENTATIVES_EDIT_SUCCESS:
      return {
        ...state,
        businessRepresentatives: action.payload,
      };
    case BUSINESS.FETCH_BUSINESS_SUCCESS:
      return {
        ...state,
        business: action.payload,
      };
    case BUSINESS.BUSINESS_TYPE_SELECTED:
      return {
        ...state,
        businessType: action.payload,
      };
    case BUSINESS.FETCH_DOCUSIGN_SUCCESS:
      return {
        ...state,
        businessDocusign: action.payload,
      };
    default:
      return state;
  }
};
