// @flow
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import {
  Card,
  Table,
  Row,
  Menu,
  Col,
  Tooltip,
  Icon,
  Collapse,
  Switch,
  Checkbox,
  Radio,
} from 'antd';
import moment from 'moment-timezone';

import {
  SpForm,
  SpText,
  SpH5,
  SpTab,
  SpButton,
  SpStatusTag,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import {
  couponDetailsActions,
  couponActions,
} from 'store/actions';
import {
  PAGES,
  ROLES,
  API_STATUS,
  COUPON_DURATION,
} from 'appconstants';
import getCouponBackground from 'utils/getCouponBackground';
import formatAmount from 'utils/formatAmount';
import formatNumber from 'utils/formatNumber';
import RBAC from 'components/rbac';
import DeleteSelectedCoupon from './components/DeleteSelectedCoupon';
import EditCoupon from './components/EditCoupon';
import AddPromotionalCode from './components/AddPromotionalCode';
import DeletePromotionalCode from './components/DeletePromotionalCode';

const { Panel } = Collapse;

type Props = {
  fetchCouponDetails: Function,
  couponDetails: Object,
  loading: boolean,
  selectedAccount: Object,
  submitting: boolean,
  history: {
    push: Function,
  },
  match: {
    params: Object,
  },
  couponDetailsStatus: string,
  deleteCoupon: Function,
  validator: boolean,
  updateCoupon: Function,
  fetchCustomers: Function,
  customers: Array<Object>,
  addPromoCode: Function,
  deletePromoCode: Function,
  redemptions: Array<Object>,
  promotionals: Array<Object>,
  allShops: Array<Object>,
  allMenus: Array<Object>,
  allCategories: Array<Object>,
  fetchCouponShops: Function,
  fetchCouponMenus: Function,
  fetchCouponCategories: Function,
  fetchCouponShop: Function,
  fetchCouponMenu: Function,
  updateCouponShop: Function,
  fetchCouponItems: Function,
  allItems: Array<Object>,
  // couponMenu: Object,
  couponShop: Array<Object>,
  isMobileView: Boolean,
}

const CouponDetails = (props: Props) => {
  const {
    fetchCouponDetails,
    couponDetails,
    loading,
    submitting,
    history,
    selectedAccount,
    couponDetailsStatus,
    deleteCoupon,
    validator,
    updateCoupon,
    fetchCustomers,
    customers,
    addPromoCode,
    deletePromoCode,
    redemptions,
    promotionals,
    allShops,
    allMenus,
    allCategories,
    fetchCouponShops,
    fetchCouponMenus,
    fetchCouponCategories,
    fetchCouponShop,
    fetchCouponMenu,
    updateCouponShop,
    fetchCouponItems,
    allItems,
    // couponMenu,
    couponShop,
    isMobileView,
  } = props;

  const tableId = document.getElementsByTagName('table');
  if (tableId && tableId[0] && isMobileView) {
    tableId[0].style.width = '1600px';
  } else if (tableId && tableId[0] && !isMobileView) {
    tableId[0].style.width = '100%';
  }

  const { gid } = props.match.params;
  const path = props.match.url;
  const [deleteCouponModal, setDeleteCouponModal] = useState(false);
  const [editCouponModal, seteditCouponModal] = useState(false);
  const [addPromoCodeModal, setAddPromoCodeModal] = useState(false);
  const [deletePromoModal, setDeletePromoModal] = useState(false);
  const gidPath = gid.split('-')[1];
  const [redirect, setRedirect] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const [allCustomers, setAllCustomers] = useState(customers);
  const typeOfCurrency = selectedAccount && selectedAccount.currency.name;
  const currencyPrefix = selectedAccount && selectedAccount.currency.prefix;
  const [selectedPromoCode, setSelectedPromoCode] = useState({});
  const [status, setStatus] = useState();
  const [percentage, setPercentage] = useState(null);
  const [amount, setAmount] = useState(null);
  const [current, setCurrent] = useState('assign');
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const disabled = couponDetails && couponDetails.active;
  const [currentCoupon, setCurrentCoupon] = useState('menu');
  const [selectedCouponShop, setSelectedCouponShop] = useState('');
  const [shopStackabilityMap, setShopStackabilityMap] = useState({});
  const [menuGids, setMenuGids] = useState([]);
  const [categoryGids, setCategoryGids] = useState([]);
  const [itemGids, setItemGids] = useState([]);
  const [appliedCouponsShopList, setAppliedCouponShopList] = useState([]);

  const getData = () => {
    fetchCouponShops();
    fetchCouponShop(gid);
    // fetchCouponMenus();
    // fetchCouponMenu(gid);
    // fetchCouponCategories();
    // fetchCouponCategory(gid);
    fetchCouponDetails(gid);
    fetchCustomers();
  };

  const balanceUpdate = (val) => {
    setCurrent(val);
  };

  const couponMenuUpdate = (val) => {
    setCurrentCoupon(val);
  };


  useEffect(() => {
    if (!gidPath) {
      history.push(PAGES.COUPONS);
    }
  }, []);

  useEffect(() => {
    if (couponShop && couponShop.length !== 0 && current !== 'assign') {
      const tempShopList = [];
      for (let i = 0; i < couponShop.length; i += 1) {
        tempShopList.push(couponShop[i].shop.gid);
      }
      setAppliedCouponShopList(tempShopList);
    }
  }, [couponShop, current]);

  const updateUnlink = (event, shop) => {
    if (appliedCouponsShopList.includes(shop.gid)) {
      const params = {
        removeDiscount: true,
      };
      setRedirect(true);
      updateCouponShop({
        params,
        gid,
        selectedCouponShop: shop.gid,
      });
    } else {
      const params = {
        menuGids,
        categoryGids,
        itemGids,
      };
      setRedirect(true);
      updateCouponShop({
        params,
        gid,
        selectedCouponShop: shop.gid,
      });
    }
  };

  const updateAssignCoupon = () => {
    if (selectedCouponShop) {
      const tempShop = couponShop.filter((item) => item.shop.gid === selectedCouponShop);
      const isStakable = (tempShop && tempShop[0] && tempShop[0].stackable) ? tempShop[0].stackable : false;
      const params = {
        menuGids,
        categoryGids,
        itemGids,
        stackable: isStakable,
      };
      setRedirect(true);
      updateCouponShop({
        params,
        gid,
        selectedCouponShop,
      });
    }
  };

  const handleSelectChange = (selectedRowKey) => {
    switch (currentCoupon) {
      case 'menu':
        // eslint-disable-next-line no-case-declarations
        const tempMenu = selectedRowKey.filter((item) => item.split('-')[0] === 'inventorymenu');
        setMenuGids(tempMenu);
        break;
      case 'category':
        // eslint-disable-next-line no-case-declarations
        const tempCategory = selectedRowKey.filter((item) => item.split('-')[0] === 'inventorycategory');
        setCategoryGids(tempCategory);
        break;
      default:
        // eslint-disable-next-line no-case-declarations
        const tempItem = selectedRowKey.filter((item) => item.split('-')[0] === 'item');
        setItemGids(tempItem);
    }
    setSelectedRowKeys(selectedRowKey);
  };

  // eslint-disable-next-line no-unused-vars
  const [rowSelection, setRowSelection] = useState({
    type: 'checkbox',
    selectedRowKeys,
    onChange: handleSelectChange,
    columnWidth: '5%',
    fixed: 'left',
  });

  useEffect(() => {
    setRowSelection((prevRowSelection) => ({
      ...prevRowSelection,
      selectedRowKeys,
    }));
  }, [selectedRowKeys]);

  useEffect(() => {
    if (couponDetails && couponDetails.gid) {
      setStatus((couponDetails && couponDetails.active) ? 'Active' : 'Deactivated');
      setPercentage(couponDetails.percentageOff);
      setAmount(couponDetails.amountOff);
    }
  }, [couponDetails]);

  useEffect(() => {
    if (!validator && redirect) {
      getData();
      seteditCouponModal(false);
      setAddPromoCodeModal(false);
      setDeletePromoModal(false);
      setSelectedRowKeys([]);
      setCurrent('assign');
      setCurrentCoupon('menu');
      setMenuGids([]);
      setCategoryGids([]);
      setItemGids([]);
      setSelectedCouponShop('');
      setShopStackabilityMap({});
      setDeleteCouponModal(false);
      setSelectedCouponShop('');
      setShopStackabilityMap({});
      setAppliedCouponShopList([]);
    }
  }, [validator]);

  /**
   * Get data when selected account changes
   */
  useEffect(() => {
    getData();
  }, [selectedAccount]);

  useEffect(() => {
    setAllCustomers(customers);
  }, [customers]);

  useEffect(() => {
    couponShop.forEach(coupon => {
      // eslint-disable-next-line max-len
      if (coupon && coupon.coupon && coupon.coupon.gid && coupon.coupon.gid === gid && coupon.shop && coupon.shop.gid && (selectedCouponShop === '' || coupon.shop.gid === selectedCouponShop)) {
        setShopStackabilityMap({ [coupon.shop.gid]: coupon.stackable });
        switch (coupon.discountLevel) {
          case 'MENU_LEVEL':
            setCurrentCoupon('menu');
            break;
          case 'CATEGORY_LEVEL':
            setCurrentCoupon('category');
            break;
          case 'ITEM_LEVEL':
            setCurrentCoupon('item');
            break;
          default:
            setCurrentCoupon('menu');
        }
      } else {
        setCurrentCoupon('menu');
      }
    });
  }, [couponShop, selectedCouponShop]);

  const getSelectedMenus = (record, coupon) => {
    if (coupon && coupon.menus && coupon.menus.length !== 0) {
      const gidsMenu = coupon.menus.map((option) => option.gid);
      return gidsMenu.includes(record.gid);
    }
    return false;
  };

  const getSelectedCategories = (record, coupon) => {
    if (coupon && coupon.categories && coupon.categories.length !== 0) {
      const gudCategories = coupon.categories.map((option) => option.gid);
      return gudCategories.includes(record.gid);
    }
    return false;
  };

  const getSelectedItems = (record, coupon) => {
    if (coupon && coupon.items && coupon.items.length !== 0) {
      const gidItems = coupon.items.map((option) => option.gid);
      return gidItems.includes(record.gid);
    }
    return false;
  };

  useEffect(() => {
    if (current === 'assign') {
      setSelectedCouponShop('');
      setMenuGids([]);
      setCategoryGids([]);
      setItemGids([]);
    }
  }, [current]);

  useEffect(() => {
    couponShop.forEach(coupon => {
      if (coupon && coupon.shop && coupon.shop.gid && coupon.shop.gid === selectedCouponShop) {
        switch (currentCoupon) {
          case 'menu':
            setSelectedRowKeys(allMenus.filter(record => getSelectedMenus(record.inventoryMenu, coupon)).map(record => record.inventoryMenu.gid));
            break;
          case 'category':
            setSelectedRowKeys(allCategories.filter(record => getSelectedCategories(record, coupon)).map(record => record.gid));
            break;
          default:
            setSelectedRowKeys(allItems.filter(record => getSelectedItems(record, coupon)).map(record => record.gid));
        }
      }
    });
  }, [currentCoupon, selectedCouponShop, allMenus, allCategories, allItems]);

  useEffect(() => {
    couponShop.forEach(coupon => {
      if (coupon && coupon.shop && coupon.shop.gid && coupon.shop.gid === selectedCouponShop) {
        setMenuGids(allMenus.filter(record => getSelectedMenus(record.inventoryMenu, coupon)).map(record => record.inventoryMenu.gid));
        setCategoryGids(allCategories.filter(record => getSelectedCategories(record, coupon)).map(record => record.gid));
        setItemGids(allItems.filter(record => getSelectedItems(record, coupon)).map(record => record.gid));
      }
    });
  }, [selectedCouponShop, allMenus, allCategories, allItems]);


  const fetchCustomer = (searchParams) => {
    fetchCustomers(searchParams);
  };

  const deleteSelectedCoupons = (couponGid: string) => {
    deleteCoupon(couponGid);
    setRedirect(true);
  };

  const editSelectedCoupons = (coupon: object) => {
    const {
      name,
      active,
    } = coupon;
    const params = {
      name,
      active,
    };
    updateCoupon({
      params,
      gid,
    });
  };

  const addPromotionalCode = (promocode: Object) => {
    setRedirect(true);
    const {
      code,
      minimumAmount,
      promotionalRedeemed,
      promoExpiresAt,
      customerGid,
    } = promocode;
    const payload = {
      active: true,
      code,
      enabledForCheckout: true,
      couponGid: gid,
      expiresAt: promoExpiresAt,
      maximumRedemption: promotionalRedeemed ? parseInt(promotionalRedeemed, 10) : null,
      minimumAmount: minimumAmount ? parseInt((minimumAmount).replace(/[_\W]+/g, ''), 10) : null,
      minimumAmountCurrency: typeOfCurrency,
      restrictedToCustomers: [
        customerGid,
      ],
    };
    addPromoCode({
      payload,
      gid,
    });
  };

  const showDeletePromoModal = (record) => {
    setSelectedPromoCode(record);
    setDeletePromoModal(true);
  };

  const deleteSelectedPromo = (promoGid: String) => {
    setRedirect(true);
    deletePromoCode({
      promoGid,
      gid,
    });
  };

  const sortPromotionalCodes = (array) => {
    array.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    return array;
  };

  const columns = [
    {
      title: 'PROMOTION CODE',
      dataIndex: 'code',
      width: '20%',
      align: 'left',
    },
    {
      title: 'REDEMPTION',
      dataIndex: 'maximumRedemption',
      width: '20%',
      align: 'center',
      sorter: false,
      render: (text, record) => {
        if (record.maximumRedemption) {
          return (
            <span>
              {record.redemptionCount}/{record.maximumRedemption}
            </span>
          );
        } if (!record.maximumRedemption) {
          return (
            <span>
              {record.redemptionCount}/0
            </span>
          );
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'EXPIRES',
      dataIndex: 'expiresAt',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (expiresAt) => {
        if (expiresAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(expiresAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'CREATED ON',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
    {
      title: 'ACTION',
      dataIndex: '',
      width: '20%',
      align: 'center',
      render: (text, record) => (
        <span>
          <Tooltip placement="top" title={record.active ? 'deactivate' : ''}>
            <SpButton
              style={{ cursor: 'pointer' }}
              onClick={(e) => { e.stopPropagation(); showDeletePromoModal(record); }}
              disabled={!record.active}
              ghost
            >
              <Icon
                type="delete"
                style={{ fontSize: '20px', cursor: 'pointer' }}
              />
            </SpButton>
          </Tooltip>
        </span>
      ),
    },
  ];

  const redemptionsColumns = [
    {
      title: 'NAME',
      dataIndex: 'customerName',
      width: '15%',
      align: 'left',
    },
    {
      title: 'EMAIL',
      dataIndex: 'customerEmail',
      width: '20%',
      align: 'center',
      sorter: false,
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      width: '15%',
      align: 'left',
      sorter: false,
    },
    {
      title: 'AMOUNT',
      dataIndex: 'amountBeforeDiscount',
      width: '15%',
      align: 'left',
      sorter: false,
      render: (amountBeforeDiscount) => {
        const totalAmount = (amountBeforeDiscount / 100).toFixed(2);
        if (amountBeforeDiscount < 0) {
          return (
            <span>{`(${currencyPrefix} ${formatNumber(totalAmount, currencyType).replace(/-/g, '')})`}</span>
          );
        }
        return (
          <span> {`${currencyPrefix} ${formatNumber(totalAmount, currencyType)}`}</span>
        );
      },
    },
    {
      title: 'DISCOUNT',
      dataIndex: 'discountAmount',
      width: '15%',
      align: 'left',
      sorter: false,
      render: (discountAmount) => {
        const totalAmount = (discountAmount / 100).toFixed(2);
        if (discountAmount < 0) {
          return (
            <span>{`(${currencyPrefix} ${formatNumber(totalAmount, currencyType).replace(/-/g, '')})`}</span>
          );
        }
        return (
          <span> {`${currencyPrefix} ${formatNumber(totalAmount, currencyType)}`}</span>
        );
      },
    },
    {
      title: 'DATE',
      dataIndex: 'createdAt',
      width: '20%',
      align: 'left',
      sorter: false,
      render: (createdAt) => {
        if (createdAt && selectedAccount && selectedAccount.timezone) {
          const tzDate = moment
            .utc(createdAt)
            .tz(selectedAccount.timezone)
            .format('MMM DD, YYYY, hh:mm a');
          return (<span>{tzDate}</span>);
        }
        return (
          <>&#8211;</>
        );
      },
    },
  ];

  const itemsColumn = [
    {
      title: 'ITEMS',
      dataIndex: 'item',
      width: '100%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record && record.name) {
          return (
            <Tooltip placement="top" title={`Item: ${record.name}`}>
              <span style={{ cursor: 'pointer' }}>
                {record.name}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="No item available">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
  ];

  const shopColumn = [
    {
      title: 'SHOP',
      dataIndex: 'shops',
      width: '25%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record && record.shop && record.shop.name) {
          return (
            <Tooltip placement="top" title={`Shop: ${record.shop.name}`}>
              <span style={{ cursor: 'pointer' }}>
                {record.shop.name}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="No shop available">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'MENU',
      dataIndex: 'menu',
      width: '25%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record && record.menus) {
          return (
            <>
              {
              record.menus.map((item) => (
                <Tooltip placement="top" title={`Menu: ${item.name}`}>
                  <span style={{ cursor: 'pointer' }}>
                    {item.name},&nbsp;
                  </span>
                </Tooltip>
              ))
            }
            </>

          );
        }
        return (
          <Tooltip placement="top" title="No shop available">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'CATEGORIES',
      dataIndex: 'category',
      width: '25%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record && record.categories) {
          return (
            <>
              {
              record.categories.map((item) => (
                <Tooltip placement="top" title={`Category: ${item.name}`}>
                  <span style={{ cursor: 'pointer' }}>
                    {item.name},&nbsp;
                  </span>
                </Tooltip>
              ))
            }
            </>

          );
        }
        return (
          <Tooltip placement="top" title="No shop available">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'ITEM',
      dataIndex: 'item',
      width: '25%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record && record.items) {
          return (
            <>
              {
              record.items.map((item) => (
                <Tooltip placement="top" title={`Item: ${item.name}`}>
                  <span style={{ cursor: 'pointer' }}>
                    {item.name},&nbsp;
                  </span>
                </Tooltip>
              ))
            }
            </>

          );
        }
        return (
          <Tooltip placement="top" title="No shop available">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
  ];


  const menuColumn = [
    {
      title: 'MENUS',
      dataIndex: 'menus',
      width: '100%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record && record.inventoryMenu && record.inventoryMenu.name) {
          return (
            <Tooltip placement="top" title={`Menu: ${record.inventoryMenu.name}`}>
              <span style={{ cursor: 'pointer' }}>
                {record.inventoryMenu.name}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="No menu available">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
  ];


  const categoryColumn = [
    {
      title: 'CATEGORIES',
      dataIndex: 'categories',
      width: '100%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record && record.name) {
          return (
            <Tooltip placement="top" title={`Category: ${record.name}`}>
              <span style={{ cursor: 'pointer' }}>
                {record.name}
              </span>
            </Tooltip>
          );
        }
        return (
          <Tooltip placement="top" title="No category available">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
  ];

  const menuCheck = (val) => {
    if (val) {
      fetchCouponMenus(val);
      fetchCouponCategories(val);
      fetchCouponItems(val);
      fetchCouponMenu({ gid, val });
    } else {
      setSelectedRowKeys([]);
    }
    setSelectedCouponShop(val || '');
  };

  if (loading) {
    return <Loading />;
  }

  const handleSwitchChange = (e, shopGid) => {
    if (selectedCouponShop === shopGid) {
      const params = {
        menuGids,
        categoryGids,
        itemGids,
        stackable: e,
      };
      setRedirect(true);
      updateCouponShop({
        params,
        gid,
        selectedCouponShop: shopGid,
      });
    } else {
      couponShop.forEach(coupon => {
        if (coupon && coupon.shop && coupon.shop.gid && coupon.shop.gid === shopGid) {
          const params = {
            menuGids: allMenus.filter(record => getSelectedMenus(record.inventoryMenu, coupon)).map(record => record.inventoryMenu.gid),
            categoryGids: allCategories.filter(record => getSelectedCategories(record, coupon)).map(record => record.gid),
            itemGids: allItems.filter(record => getSelectedItems(record, coupon)).map(record => record.gid),
            stackable: e,
          };
          setRedirect(true);
          updateCouponShop({
            params,
            gid,
            selectedCouponShop: shopGid,
          });
        }
      });
    }
  };

  const getStackability = (shopGid) => {
    const tempShop = couponShop.filter((item) => item.shop.gid === shopGid);
    const isStakable = (tempShop && tempShop[0] && tempShop[0].stackable) ? tempShop[0].stackable : false;
    return isStakable;
  };

  return (
    <>
      {
        couponDetails.gid && couponDetailsStatus === API_STATUS.SUCCESS && (
          <>
            <Card className="mb-3">
              <Row type="flex" justify="space-between" className="my-2 mb-3">
                <Col>
                  <SpText className="text-uppercase mr-4" fontSize="20px">
                    {couponDetails.name}
                  </SpText>
                </Col>
                <RBAC
                  permit={
                    [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
                      ROLES.OPERATIONS, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]
                  }
                >
                  <Col>
                    <SpButton
                      type="danger"
                      shape="round"
                      onClick={() => setDeleteCouponModal(true)}
                      disabled={!disabled}
                    >
                      Deactivate Coupon
                    </SpButton>
                  </Col>
                </RBAC>
              </Row>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={5}>
                    <span>Status</span>
                  </Col>
                  <Col span={6}>
                    <SpStatusTag style={{ backgroundColor: getCouponBackground(status) }}>{status}</SpStatusTag>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                {
                  couponDetails.percentageOff ? (
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Percentage discount</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>{percentage}%</SpH5>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )
                }
              </SpForm>
              <SpForm>
                {
                  couponDetails.amountOff ? (
                    <Row type="flex" justify="start" align="middle">
                      <Col span={5}>
                        <span>Amount discount</span>
                      </Col>
                      <Col span={6}>
                        <SpH5>{currencyType.prefix} {formatAmount((amount / 100), currencyType)}</SpH5>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )
                }
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start" align="middle">
                  <Col span={5}>
                    <span>Duration</span>
                  </Col>
                  {
                    couponDetails && couponDetails.validity === COUPON_DURATION[2].value ? (
                      <Col span={6}>
                        <SpH5>{couponDetails.validiyInMonths} months</SpH5>
                      </Col>
                    ) : (
                      <Col span={6}>
                        <SpH5>{couponDetails.validity}</SpH5>
                      </Col>
                    )
                  }
                </Row>
              </SpForm>
              <SpForm>
                <Row type="flex" justify="start">
                  <Col span={12}>
                    <Col span={10}>
                      <span>Created on</span>
                    </Col>
                    <Col span={12}>
                      {
                        couponDetails && couponDetails.createdAt ? (
                          <SpH5>{
                            moment
                              .utc(couponDetails && couponDetails.createdAt)
                              .tz(selectedAccount.timezone)
                              .format('MMM DD, YYYY, hh:mm a')
                          }
                          </SpH5>
                        ) : (
                          <SpH5>&#8211;</SpH5>
                        )
                      }
                    </Col>
                  </Col>
                  <Col span={12} className="d-flex justify-content-end">
                    <SpH5 color="#7f7f7f">{couponDetails.gid}</SpH5>
                  </Col>
                </Row>
              </SpForm>
              <SpForm>
                <Row>
                  <Col>
                    <RBAC
                      permit={
                        [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
                          ROLES.OPERATIONS, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]
                      }
                    >
                      <SpButton
                        className="mr-2"
                        type="secondary"
                        shape="round"
                        onClick={() => seteditCouponModal(true)}
                      >
                        Update Details
                      </SpButton>
                    </RBAC>
                  </Col>
                </Row>
              </SpForm>
              {/* DELETE COUPON MODAL */}
              {deleteCouponModal && (
                <DeleteSelectedCoupon
                  visible={deleteCouponModal}
                  couponDetails={couponDetails}
                  submitting={submitting}
                  close={() => setDeleteCouponModal(false)}
                  submit={deleteSelectedCoupons}
                />
              )}
              {/* EDIT COUPON MODAL */}
              {editCouponModal && (
                <EditCoupon
                  visible={editCouponModal}
                  couponDetails={couponDetails}
                  close={() => seteditCouponModal(false)}
                  submit={editSelectedCoupons}
                />
              )}
              {/* ADD PROMOTIONAL CODE MODAL */}
              {addPromoCodeModal && (
                <AddPromotionalCode
                  visible={addPromoCodeModal}
                  couponDetails={couponDetails}
                  close={() => setAddPromoCodeModal(false)}
                  submit={addPromotionalCode}
                  selectedAccount={selectedAccount}
                  fetchCustomer={fetchCustomer}
                  allCustomers={allCustomers}
                />
              )}
              {/* DELETE COUPON MODAL */}
              {deletePromoModal && (
                <DeletePromotionalCode
                  visible={deletePromoModal}
                  selectedPromoCode={selectedPromoCode}
                  submitting={submitting}
                  close={() => setDeletePromoModal(false)}
                  submit={deleteSelectedPromo}
                />
              )}
            </Card>
            <Card className="pl-2 mb-3">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">PROMOTIONAL CODES</SpText>
                </Col>
                <Col>
                  <RBAC
                    permit={
                      [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
                        ROLES.OPERATIONS, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]
                    }
                  >
                    <SpButton
                      className="mr-2"
                      type="secondary"
                      shape="round"
                      onClick={() => setAddPromoCodeModal(true)}
                    >
                      {'\u002B'}&nbsp;Add Promotional Code
                    </SpButton>
                  </RBAC>
                </Col>
              </Row>
              <Table
                columns={columns}
                rowKey={(record) => record.gid}
                dataSource={sortPromotionalCodes(promotionals)}
                // pagination={false}
              />
            </Card>
            <Card className="pl-2 mb-3">
              <Row type="flex" justify="space-between" className="my-2">
                <Col>
                  <SpText className="text-uppercase mb-3" fontSize="20px">REDEMPTIONS</SpText>
                </Col>
              </Row>
              <Table
                columns={redemptionsColumns}
                rowKey={(record) => record.gid}
                dataSource={redemptions}
                // pagination={false}
              />
            </Card>
            <Card className="pl-2">
              <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '15px' }}>
                <Menu.Item key="assign" className="pl-2">
                  <SpTab
                    to={`${path}`}
                    selected={current === 'assign'}
                    onClick={() => balanceUpdate('assign')}
                  >
                    Assigned Coupon
                  </SpTab>
                </Menu.Item>
                <Menu.Item key="coupon">
                  <SpTab
                    to={`${path}`}
                    selected={current === 'coupon'}
                    onClick={() => balanceUpdate('coupon')}
                  >
                    Assign
                  </SpTab>
                </Menu.Item>
              </Menu>
              {
                current === 'coupon' ? (
                  <Row type="flex" justify="space-between" className="my-2">
                    <Col>
                      <SpText className="text-uppercase mb-3" fontSize="20px">&nbsp;</SpText>
                    </Col>
                    <Col>
                      <RBAC
                        permit={
                          [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER,
                            ROLES.OPERATIONS, ROLES.SUPPORT, ROLES.SUPERUSER, ROLES.INTERNALSUPPORT, ROLES.ONBOARDING]
                        }
                      >
                        <SpButton
                          type="primary"
                          shape="round"
                          onClick={() => updateAssignCoupon()}
                          disabled={!selectedCouponShop}
                        >
                          &nbsp;Apply Coupon
                        </SpButton>
                      </RBAC>
                    </Col>
                  </Row>
                ) : (
                  <div className="my-2">&nbsp;</div>
                )
              }
              {
                current === 'assign' ? (
                  <Table
                    rowClassName={() => 'ant-table-clickable-row'}
                    columns={shopColumn}
                    // rowSelection={{
                    //   selectedRowKeys,
                    //   onChange: handleSelectChange,
                    // }}
                    rowKey={(record) => record.coupon && record.coupon.gid && record.coupon.gid}
                    className="custom-table"
                    dataSource={couponShop}
                  />
                ) : (
                  <>
                    <Row
                      type="flex"
                      justify="space-between"
                      className="my-2 py-3 px-3"
                      style={{ backgroundColor: '#FAFAFA', borderBottom: '1px solid #D9D9D9' }}
                    >
                      <Col>
                        <SpText className="text-uppercase mb-3" fontSize="16px" fontWeight="600">Shop</SpText>
                      </Col>
                      <Col>
                        <SpText className="text-uppercase mb-3" fontSize="16px" fontWeight="600">Stackable</SpText>
                      </Col>
                    </Row>
                    <Collapse onChange={menuCheck} expandIconPosition="end" accordion>
                      {
                        allShops.map((shop) => (
                          <Panel
                            header={(
                              <>
                                <Radio
                                  checked={appliedCouponsShopList.includes(shop.gid)}
                                  className="mr-2"
                                  onClick={(e) => { e.stopPropagation(); updateUnlink(e, shop); }}
                                />
                                <SpText fontSize="16px">
                                  {shop.name}
                                </SpText>
                              </>
                            )}
                            key={shop.gid}
                            extra={<Switch checked={getStackability(shop.gid)} onChange={(e) => handleSwitchChange(e, shop.gid)} />}
                          >
                            <Menu
                              onClick={e => setCurrentCoupon(e.key)}
                              selectedKeys={[currentCoupon]}
                              mode="horizontal"
                              style={{ fontSize: '15px' }}
                            >
                              <Menu.Item key="menu" className="pl-2">
                                <SpTab
                                  to={`${path}`}
                                  selected={current === 'menu'}
                                  onClick={() => couponMenuUpdate('menu')}
                                  fontSize="16px"
                                >
                                  MENU
                                </SpTab>
                              </Menu.Item>
                              <Menu.Item key="category">
                                <SpTab
                                  to={`${path}`}
                                  selected={current === 'category'}
                                  onClick={() => couponMenuUpdate('category')}
                                  fontSize="16px"
                                >
                                  CATEGORY
                                </SpTab>
                              </Menu.Item>
                              <Menu.Item key="item">
                                <SpTab
                                  to={`${path}`}
                                  selected={current === 'item'}
                                  onClick={() => couponMenuUpdate('item')}
                                  fontSize="16px"
                                >
                                  ITEM
                                </SpTab>
                              </Menu.Item>
                            </Menu>
                            {
                              currentCoupon === 'menu' ? (
                                <Table
                                  rowClassName={() => 'ant-table-clickable-row'}
                                  columns={menuColumn}
                                  rowSelection={{
                                    selectedRowKeys,
                                    onChange: handleSelectChange,
                                  }}
                                  rowKey={(record) => record.inventoryMenu.gid}
                                  className="custom-table"
                                  dataSource={allMenus}
                                />
                              ) : currentCoupon === 'category' ? (
                                <Table
                                  rowClassName={() => 'ant-table-clickable-row'}
                                  columns={categoryColumn}
                                  rowSelection={{
                                    selectedRowKeys,
                                    onChange: handleSelectChange,
                                  }}
                                  rowKey={(record) => record.gid}
                                  className="custom-table"
                                  dataSource={allCategories}
                                />
                              ) : (
                                <Table
                                  rowClassName={() => 'ant-table-clickable-row'}
                                  columns={itemsColumn}
                                  rowSelection={{
                                    selectedRowKeys,
                                    onChange: handleSelectChange,
                                  }}
                                  rowKey={(record) => record.gid}
                                  className="custom-table"
                                  dataSource={allItems}
                                />
                              )
                            }
                          </Panel>
                        ))
                      }

                    </Collapse>
                  </>
                )
              }
            </Card>
          </>
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  couponDetails: state.couponDetails.content,
  couponDetailsStatus: state.couponDetails.couponDetailsStatus,
  loading: state.loading.loading,
  test: state.account.test,
  isSuperUser: state.user.superUser,
  selectedAccount: state.account.selectedAccount,
  submitting: state.loading.submitting,
  validator: state.loading.validator,
  customers: state.coupons.customers,
  redemptions: state.couponDetails.redemptions,
  promotionals: state.couponDetails.promotionals,
  allShops: state.couponDetails.couponListShops,
  allMenus: state.couponDetails.couponListMenus,
  allCategories: state.couponDetails.couponListCategories,
  allItems: state.couponDetails.couponListItems,
  couponShop: state.couponDetails.couponShop,
  couponMenu: state.couponDetails.couponMenu,
  couponCategory: state.couponDetails.couponCategory,
  isMobileView: state.mobileView.isMobileView,
});

const mapDispatchToProps = (dispatch) => ({
  fetchCouponDetails: gid => dispatch(couponDetailsActions.fetchCouponDetails({
    payload: gid,
  })),
  fetchCouponShops: param => dispatch(couponDetailsActions.fetchCouponShops({
    payload: param,
  })),
  fetchCouponMenus: param => dispatch(couponDetailsActions.fetchCouponMenus({
    payload: param,
  })),
  fetchCouponCategories: param => dispatch(couponDetailsActions.fetchCouponCategories({
    payload: param,
  })),
  fetchCouponShop: gid => dispatch(couponDetailsActions.fetchCouponShop({
    payload: gid,
  })),
  fetchCouponMenu: gid => dispatch(couponDetailsActions.fetchCouponMenu({
    payload: gid,
  })),
  fetchCouponCategory: gid => dispatch(couponDetailsActions.fetchCouponCategory({
    payload: gid,
  })),
  fetchCouponItems: gid => dispatch(couponDetailsActions.fetchCouponItems({
    payload: gid,
  })),
  deleteCoupon: param => dispatch(couponDetailsActions.deleteCoupon({
    payload: param,
  })),
  updateCoupon: param => dispatch(couponDetailsActions.updateCoupon({
    payload: param,
  })),
  fetchCustomers: param => dispatch(couponActions.fetchCustomers({
    payload: param,
  })),
  addPromoCode: param => dispatch(couponDetailsActions.addPromoCode({
    payload: param,
  })),
  deletePromoCode: param => dispatch(couponDetailsActions.deletePromoCode({
    payload: param,
  })),
  updateCouponShop: param => dispatch(couponDetailsActions.updateCouponShop({
    payload: param,
  })),
  updateCouponMenu: param => dispatch(couponDetailsActions.updateCouponMenu({
    payload: param,
  })),
  updateCouponCategory: param => dispatch(couponDetailsActions.updateCouponCategory({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(CouponDetails);
