// @flow
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import debounce from 'lodash.debounce';
import {
  Card,
  Table,
  Row,
  Col,
  Tooltip,
  Badge,
  Drawer,
  List,
  Spin,
  Input,
  Switch,
  Modal,
} from 'antd';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import {
  SpButton,
  FilterWrapper,
  SpText,
  SpDragInput,
} from 'components/DesignKit';

import Loading from 'components/Loading';
import Filters from 'components/Filters';
import getFilterOperators from 'utils/getFilterOperators';

import {
  inventoryItemsActions,
  notificationActions,
  filterParamsActions,
  inventoryPrinterLabelActions,
} from 'store/actions';
import { FILTERS_SPECS } from 'store/actionTypes';
import {
  PAGES,
  ROLES,
  FILTERS_TYPES,
  FILTER_STATUSES,
} from 'appconstants';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import moment from 'moment-timezone';
import formatNumber from 'utils/formatNumber';
import RBAC from 'components/rbac';
import online from 'imgs/online.svg';
import FilterIcon from 'components/NavIcons/filterIcon';
import ExportIcon from 'components/NavIcons/exportIcon';
import ItemClose from 'components/NavIcons/itemClose';
import DragIcon from 'components/NavIcons/dragIcon';
import SortAscending from 'imgs/SortAscending.png';
import SortDescending from 'imgs/SortDescending.png';
import AddItem from './components/AddNewItem';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

const TransactionTypes = FILTER_STATUSES.ONPOS;

const AvailableTypes = FILTER_STATUSES.AVAILABLE;

type Props = {
  inventoryItems: Array<Object>,
  itemsList: Array<Object>,
  modifierItemsList: Array<Object>,
  sortItemsList: Array<Object>,
  fetchInventoryItems: Function,
  fetchInventorySortItems: Function,
  fetchItemsList: Function,
  fetchModifierItemsList: Function,
  updateInventoryItems: Function,
  addInventoryItems: Function,
  updateInventorySortItems: Function,
  selectedAccount: Object,
  totalElements: number,
  test: boolean,
  loading: boolean,
  history: {
    push: Function,
  },
  setFilterData: Function,
  preserveFilters: Object,
  submitting: boolean,
  validator: boolean,
  fetchPrinterLabelList: Function,
  labelList: Array,
  updateBulkItems: Function,
  fetchInventoryItemsSearch: Function,
  searchTypes: String,
};

const ItemsList = (props: Props) => {
  const location = useLocation();
  const {
    selectedAccount,
    inventoryItems,
    itemsList,
    modifierItemsList,
    sortItemsList,
    loading,
    fetchInventoryItems,
    fetchInventorySortItems,
    fetchModifierItemsList,
    fetchItemsList,
    updateInventoryItems,
    updateInventorySortItems,
    addInventoryItems,
    totalElements,
    test,
    history,
    preserveFilters,
    setFilterData,
    submitting,
    validator,
    fetchPrinterLabelList,
    labelList,
    updateBulkItems,
    fetchInventoryItemsSearch,
    searchTypes,
  } = props;

  const [pagination, setPagination] = useState({});
  const { pathname } = location;
  const myRef = useRef();
  const [open, setOpen] = useState(false);
  const PAYMENTLINKS_FILTERS_TYPES = FILTERS_TYPES.INVENTORY_ITEMS;
  const [filterTypes, setFilterTypes] = useState(FILTERS_TYPES.INVENTORY_ITEMS);
  const [fiterOperators, setFilterOperators] = useState([]);
  const [filterPayments, setFilterPayments] = useState((preserveFilters && preserveFilters.filterPayments)
    ? (preserveFilters && preserveFilters.filterPayments)
    : [{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currentDate, setCurrentDate] = useState('');
  const [count, setCount] = useState(null);
  const [fillColor, setFillColor] = useState(false);
  const [filterColor, setFilterColor] = useState(false);
  const currencyType = selectedAccount && selectedAccount.currency;
  const preserveParam = ((preserveFilters && preserveFilters.pathname) === pathname)
    ? queryString.parse(preserveFilters.search)
    : setFilterData({
      pathname,
      search: '',
      filterPayments: [{
        filterType: '',
        filterName: '',
        filterOperator: '',
        filterValue: '',
        filterNameDisplay: '',
        filterValueFrom: '',
        filterValueTo: '',
        checked: true,
      }],
    });
  const [addItemModal, setAddItemModal] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [dragData, setDragData] = useState(sortItemsList);
  const [payloadObject, setPayloadObject] = useState({});
  const [onSortUp, setOnSortUp] = useState(true);
  const [spiner, setSpinner] = useState(false);
  const [prevPageNum, setPrevPageNum] = useState(1);
  const [sortOrderFetch, setSortOrderFetch] = useState(true);
  const kdsRole = localStorage.getItem('kdsRole');
  const [searchText, setSearchText] = useState('');
  const [filterStatusType, setFilterStatusType] = useState('');
  const [updateStatus, setUpdateStatus] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [disableSwitch, setDisableSwitch] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [availableSpace, setAvailableSpace] = useState(false);

  // eslint-disable-next-line
  const handleSelectChange = (selectedRowKeys, selectedItems) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedKeys(selectedItems);
  };

  useEffect(() => {
    // eslint-disable-next-line
    setRowSelection((prevRowSelection) => ({
      ...prevRowSelection,
      selectedRowKeys,
    }));
  }, [selectedRowKeys]);

  const [rowSelection, setRowSelection] = useState({
    type: 'checkbox',
    selectedRowKeys,
    onChange: handleSelectChange,
  });

  const onClose = () => {
    setOpen1(false);
    setSpinner(false);
  };
  const [sorting, setSorting] = useState({
    field: 'name',
    order: 'ascend',
  });

  const exportData = inventoryItems ? inventoryItems.map((values) => ({
    name: values.name || '',
    // eslint-disable-next-line max-len
    price: `${selectedAccount.currency && selectedAccount.currency.prefix}${formatNumber((values.price / 100).toFixed(2), currencyType)}`,
    // eslint-disable-next-line max-len
    cost: values.cost ? `${selectedAccount.currency && selectedAccount.currency.prefix}${formatNumber((values.cost / 100).toFixed(2), currencyType)}` : '',
    categories: values.categories && values.categories.length !== 0
      ? values.categories.map((info, index) => (values.categories.length === index + 1
        ? info.name
        : `${info.name}, `)) : '',
    modifierGroups: values.modifierGroups && values.modifierGroups.length !== 0
      ? values.modifierGroups.map((info, index) => (values.modifierGroups.length === index + 1
        ? info.name
        : `${info.name}, `)) : '',
    order: values.sortOrder || 0,
    hidden: values.hidden ? 'No' : 'Yes',
    isAvail: values.available ? 'Yes' : 'No',
  })) : '';

  const exportHeaders = [
    { label: 'NAME', key: 'name' },
    { label: 'PRICE', key: 'price' },
    { label: 'COST', key: 'cost' },
    { label: 'CATEGORIES', key: 'categories' },
    { label: 'MODIFIER GROUPS', key: 'modifierGroups' },
    { label: 'SORT ORDER', key: 'order' },
    { label: 'ON POS', key: 'hidden' },
    { label: 'AVAILABLE', key: 'isAvail' },
  ];

  const debouncedFetchInventoryItems = debounce(fetchInventoryItems, 2000, { leading: true });

  const getData = (query) => {
    const parsed = queryString.parse(query);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'name';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    // const sortParam = '&sortBy=sortOrder&direction=ASC';
    const filterParam = parsed.filterParams || '';
    const filterParams = (preserveParam ? preserveParam.filterParams : filterParam || '');
    const nameSearch = searchText ? (searchText === '' ? null : `&name.LIKE=${searchText}`) : null;
    debouncedFetchInventoryItems({
      currentPage, sortParams, filterParams, nameSearch,
    });
    fetchItemsList('&sortBy=name&direction=DESC');
    fetchModifierItemsList('&sortBy=name&direction=DESC');
    fetchPrinterLabelList('&sortBy=createdAt&direction=DESC');
    // fetchInventorySortItems({ sortParam });
  };

  useEffect(() => {
    getData(location.search);
  }, [location, test, selectedAccount]);

  useEffect(() => {
    setDragData(sortItemsList);
  }, [sortItemsList]);

  useEffect(() => {
    if (!validator && updateStatus) {
      setAddItemModal(false);
      setOpen1(false);
      setOnSortUp(true);
      setSelectedRowKeys([]);
      setSelectedKeys([]);
      setDisableSwitch(false);
      setModalVisible(false);
      setSearchText('');
      // fetchInventoryItems('&sortBy=sortOrder&direction=ASC');
    }
    if (!validator && open1) {
      setAddItemModal(false);
      setOpen1(false);
      setOnSortUp(true);
      setSearchText('');
      // fetchInventoryItems('&sortBy=sortOrder&direction=ASC');
    }
    if (!validator) {
      setAddItemModal(false);
      setOpen1(false);
      setOnSortUp(true);
      getData(location.search);
      // fetchInventoryItems('&sortBy=sortOrder&direction=ASC');
    }
  }, [validator]);

  const handleClick = e => {
    if (myRef.current && myRef.current.contains(e.target)) {
      return;
    }
    if (e.target.value === undefined
      && e.toElement.className !== 'ant-calendar-date'
      && e.toElement.className !== 'ant-calendar-prev-month-btn'
      && e.toElement.className !== 'ant-calendar-next-month-btn') {
      setOpen(false);
    }
  };

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    setPagination(existingElements => ({
      ...existingElements,
      total: totalElements,
      current: parseInt(currentPage, 10),
      showTotal: total => `Total ${total} items`,
    }));
  }, [inventoryItems]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleTableChange = (page, sorter, filters) => {
    const parsed = queryString.parse(location.search);
    const filterParams = parsed.filterParams || '';
    setPrevPageNum(Number(page.current));
    const tempPage = page.current;
    let tempParams = '';
    // if (sorting.order === 'ascend') {
    //   setSorting({
    //     field: filters.field,
    //     order: 'descend',
    //   });
    // } else {
    //   setSorting({
    //     field: filters.field,
    //     order: 'ascend',
    //   });
    // }
    setSorting({
      field: filters.field,
      order: filters.order,
    });
    if (filters.order === 'ascend') {
      tempParams = `&sortBy=${filters.field}&direction=ASC`;
    } else if (filters.order === 'descend') {
      tempParams = `&sortBy=${filters.field}&direction=DESC`;
    } else {
      tempParams = '';
    }
    if (location.search && filterParams) {
      const filterParam = encodeURIComponent(filterParams);
      history.push({
        pathname: PAGES.INVENTORY_ITEMS,
        search: `?filterParams=${filterParam}&pageNum=${tempPage}${tempParams}`,
      });
    } else {
      history.push({
        pathname: PAGES.INVENTORY_ITEMS,
        search: `?pageNum=${tempPage}${tempParams}`,
      });
    }
  };

  useEffect(() => {
    if (searchTypes) {
      setSearchText(searchTypes);
      const sortParams = '&sortBy=name&direction=ASC';
      const currentPage = 1;
      const filterParams = (preserveParam ? preserveParam.filterParams : '');
      setTimeout(() => {
        debouncedFetchInventoryItems({
          currentPage, sortParams, filterParams, nameSearch: searchTypes ? (searchTypes === '' ? null : `&name.LIKE=${searchTypes}`) : null,
        });
      }, 10);
    }
  }, [searchTypes]);

  const onCheck = (e, index) => {
    const list = [...filterPayments];
    if (filterPayments.length !== 1) {
      if (!e) {
        list.splice(index, 1);
        const filterTypeOptions = PAYMENTLINKS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
        setFilterTypes(filterTypeOptions);
      } else {
        list[index].checked = e;
      }
    }
    setFilterPayments(list);
  };

  const addSelectedItem = (customer: Object) => {
    const {
      name,
      price,
      alternateName,
      onlineName,
      onlineEnabled,
      sku,
      isPos,
      priceType,
      primaryColor,
      categories,
      order,
      modifierGroups,
      isItemAvailable,
      isRecurring,
      cost,
      logoUrl,
      description,
      tagDetails,
      enableStock,
      enableDynamicAvailability,
      stockQuantity,
      onlineAvailability,
      posAvailability,
      shopDetails,

    } = customer;
    const totalPrice = price ? price.replace(/[^\d]/g, '') : 0;
    const totalCost = cost ? cost.replace(/[^\d]/g, '') : 0;

    const params = {
      name,
      alternateName,
      onlineName,
      onlineEnabled,
      sku,
      price: Number(totalPrice),
      hidden: !isPos,
      priceType,
      sortOrder: Number(order),
      colorCode: primaryColor,
      categoryGids: categories || null,
      shopGids: shopDetails || [],
      modifierGroupGids: modifierGroups || null,
      available: isItemAvailable,
      recurring: isRecurring,
      cost: Number(totalCost),
      imageUrl: logoUrl,
      description,
      tagGids: tagDetails || [],
      enableStock,
      enableDynamicAvailability,
      stockQuantity: Number(stockQuantity),
      onlineAvailability: Number(onlineAvailability * 100),
      posAvailability: Number(posAvailability * 100),
    };
    addInventoryItems({
      params,
    });
  };

  const handleChange = (e, index) => {
    const list = [...filterPayments];
    if (e[1] === 'hidden') {
      setFilterStatusType('hidden');
    } if (e[1] === 'available') {
      setFilterStatusType('available');
    }
    if (e[1] === 'createdAt') {
      list[index].filterType = 'Date';
    } else if (e[1] === 'amount') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'applicationFee') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'status') {
      list[index].filterType = 'Dropdown';
    } else if (e[1] === 'price') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'cost') {
      list[index].filterType = 'Number';
    } else if (e[1] === 'hidden') {
      list[index].filterType = 'Dropdown';
    } else if (e[1] === 'available') {
      list[index].filterType = 'Dropdown';
    } else {
      list[index].filterType = 'String';
    }
    list[index].filterName = e[1];
    list[index].filterNameDisplay = e[0];
    list[index].filterOperator = '';
    list[index].filterValue = '';
    const filterTypeOptions = PAYMENTLINKS_FILTERS_TYPES.filter(({ value: id1 }) => !list.some(({ filterName: id2 }) => id2 === id1));
    setFilterPayments(list);
    setFilterTypes(filterTypeOptions);
    setFilterOperators(getFilterOperators(e[1]));
  };

  const handleFromValue = (e, index) => {
    const list = [...filterPayments];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueFrom = e.currentTarget.value;
      setFilterPayments(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueFrom = e;
      setFilterPayments(list);
    }
  };

  const handleToValue = (e, index) => {
    const list = [...filterPayments];
    const regex = /^\d*\.?\d*$/;
    if (e.currentTarget && regex.test(e.currentTarget.value.replace(/,/g, ''))) {
      list[index].filterValueTo = e.currentTarget.value;
      setFilterPayments(list);
    } else if (regex.test(e.replace(/,/g, ''))) {
      list[index].filterValueTo = e;
      setFilterPayments(list);
    }
  };

  const handleValue = (e, index) => {
    let selectedValue = '';
    if (filterPayments[index].filterName === 'createdAt') {
      if (filterPayments[index].filterOperator === 'BT') {
        const date1 = e[0];
        const date2 = e[1];
        const utcDate1 = moment(date1).endOf('day');
        setStartDate(utcDate1);
        const utcDate2 = moment(date2).startOf('day');
        setEndDate(utcDate2);
        const utcString1 = utcDate1.format(MOMENT_FORMAT);
        const utcString2 = utcDate2.format(MOMENT_FORMAT);
        const selectedDate1 = moment.tz(utcString1, selectedAccount.timezone);
        const selectedDate2 = moment.tz(utcString2, selectedAccount.timezone);
        const dateStart = selectedDate1.utc().format(MOMENT_FORMAT);
        const dateEnd = selectedDate2.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}&createdAt.LT=${dateEnd}`;
      } else if (filterPayments[index].filterOperator === 'LT') {
        const utcDate = moment(e).startOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.LT=${dateStart}`;
      } else if (filterPayments[index].filterOperator === 'GT') {
        const utcDate = moment(e).endOf('day');
        setCurrentDate(utcDate);
        const utcString = utcDate.format(MOMENT_FORMAT);
        const dateSelected = moment.tz(utcString, selectedAccount.timezone);
        const dateStart = dateSelected.utc().format(MOMENT_FORMAT);
        selectedValue = `createdAt.GT=${dateStart}`;
      }
    } else if (filterPayments[index].filterName === 'status') {
      selectedValue = e;
    } else {
      if (!e.currentTarget) {
        selectedValue = e;
      }
      if (e.currentTarget && e.currentTarget.value) {
        selectedValue = e.currentTarget.value;
      }
    }
    const list = [...filterPayments];
    list[index].filterValue = selectedValue;
    setFilterPayments(list);
  };

  const handleOperator = (e, index) => {
    const list = [...filterPayments];
    list[index].filterOperator = e;
    if (list[index].filterValue) {
      if (list[index].filterOperator !== 'BT'
        && list[index].filterName === 'createdAt') {
        handleValue(currentDate, index);
      } else if (list[index].filterOperator === 'BT'
        && list[index].filterName === 'createdAt') {
        const dateList = [startDate, endDate];
        handleValue(dateList, index);
      } else if (list[index].filterOperator !== 'BT'
        && list[index].filterOperator !== 'LT'
        && list[index].filterOperator !== 'GT') {
        const value = filterPayments[index].filterValue;
        handleValue(value, index);
      }
    }
    setFilterPayments(list);
  };

  const handleAddClick = () => {
    const list = [...filterPayments];
    setFilterPayments([...list, {
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
  };

  const resetFilters = () => {
    setFilterData({
      pathname,
      search: '',
    });
    setFilterPayments([{
      filterType: '',
      filterName: '',
      filterOperator: '',
      filterValue: '',
      filterNameDisplay: '',
      filterValueFrom: '',
      filterValueTo: '',
      checked: true,
    }]);
    setFilterTypes(FILTERS_TYPES.INVENTORY_ITEMS);
    setStartDate('');
    setEndDate('');
    setCurrentDate('');
    setCount(null);
    history.push(PAGES.INVENTORY_ITEMS);
  };

  const applyFilters = () => {
    const paymentsFilters = filterPayments.filter((item) => item.checked);
    for (let i = 0; i < paymentsFilters.length; i += 1) {
      if (!paymentsFilters[i].filterName || !paymentsFilters[i].filterOperator || !paymentsFilters[i].filterValue) {
        return;
      }
    }
    if (paymentsFilters[0].filterValue || (paymentsFilters[0].filterValueFrom && paymentsFilters[0].filterValueTo)) {
      setCount(paymentsFilters && paymentsFilters.length);
      for (let i = 0; i < paymentsFilters.length; i += 1) {
        if (paymentsFilters[i].checked) {
          if (paymentsFilters[i].filterName === 'createdAt') {
            paymentsFilters[i] = paymentsFilters[i].filterValue;
          } else if (paymentsFilters[i].filterName === 'amount' && paymentsFilters[i].filterOperator === 'BT') {
            const name = paymentsFilters[i].filterName;
            const from = paymentsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = paymentsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (paymentsFilters[i].filterName === 'amount' && paymentsFilters[i].filterOperator !== 'BT') {
            const value = paymentsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${value}`;
          } else if (paymentsFilters[i].filterName === 'cost' && paymentsFilters[i].filterOperator === 'BT') {
            const name = paymentsFilters[i].filterName;
            const from = paymentsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = paymentsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (paymentsFilters[i].filterName === 'cost' && paymentsFilters[i].filterOperator !== 'BT') {
            const value = paymentsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${value}`;
          } else if (paymentsFilters[i].filterName === 'price' && paymentsFilters[i].filterOperator === 'BT') {
            const name = paymentsFilters[i].filterName;
            const from = paymentsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = paymentsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (paymentsFilters[i].filterName === 'price' && paymentsFilters[i].filterOperator !== 'BT') {
            const value = paymentsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${value}`;
          } else if (paymentsFilters[i].filterName === 'phoneNumber') {
            const value = paymentsFilters[i].filterValue.replace('+', '%2B');
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${value}`;
          } else if (paymentsFilters[i].filterName === 'applicationFee' && paymentsFilters[i].filterOperator === 'BT') {
            const name = paymentsFilters[i].filterName;
            const from = paymentsFilters[i].filterValueFrom.replace(/[. ,:-]+/g, '');
            const to = paymentsFilters[i].filterValueTo.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${name}.GT=${from}&${name}.LT=${to}`;
          } else if (paymentsFilters[i].filterName === 'applicationFee' && paymentsFilters[i].filterOperator !== 'BT') {
            const value = paymentsFilters[i].filterValue.replace(/[. ,:-]+/g, '');
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${value}`;
          } else if (paymentsFilters[i].filterName === 'email') {
            const email = encodeURIComponent(paymentsFilters[i].filterValue);
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${email}`;
          } else {
            paymentsFilters[i] = `${paymentsFilters[i].filterName}.${paymentsFilters[i].filterOperator}=${paymentsFilters[i].filterValue}`;
          }
        }
      }

      const filterParams = encodeURIComponent(paymentsFilters.join('&'));
      const parsed = queryString.parse(location.search);
      const currentPage = 1;
      const sortBy = parsed.sortBy || 'name';
      const direction = parsed.direction || 'ASC';
      const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
      if (location.search && parsed.sortBy && parsed.pageNum && parsed.direction) {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.INVENTORY_ITEMS,
          search: `?filterParams=${filterParams}&pageNum=${currentPage}${sortParams}`,
        });
      } else {
        setFilterData({
          pathname,
          search: `?filterParams=${filterParams}`,
          filterPayments,
        });
        history.push({
          pathname: PAGES.INVENTORY_ITEMS,
          search: `?filterParams=${filterParams}`,
        });
      }
      setOpen(false);
    }
  };

  const updateItems = (record, info, updateType) => {
    const shopIds = (record && record.shops && record.shops.length !== 0) ? record.shops.map((option) => option.gid) : [];
    const tagIds = (record && record.tags && record.tags.length !== 0) ? record.tags.map((option) => option.gid) : [];
    const categoryIds = (record && record.categories && record.categories.length !== 0) ? record.categories.map((option) => option.gid) : [];
    // eslint-disable-next-line max-len
    const modifierIds = (record && record.modifierGroups && record.modifierGroups.length !== 0) ? record.modifierGroups.map((option) => option.gid) : [];
    const params = {
      name: record.name,
      alternateName: record.alternateName,
      sku: record.sku,
      price: (record && record.price) || 0,
      hidden: record.hidden,
      priceType: record.priceType,
      sortOrder: record.sortOrder,
      colorCode: record.colorCode,
      categoryGids: categoryIds || [],
      modifierGroupGids: modifierIds || [],
      available: updateType === 'available' ? info : record.available,
      onlineEnabled: updateType === 'onlineEnabled' ? info : record.onlineEnabled,
      cost: (record && record.cost) || 0,
      imageUrl: record.imageUrl,
      description: record.description,
      shopGids: shopIds || [],
      tagGids: tagIds || [],
    };
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'name';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    updateInventoryItems({
      gid: record.gid,
      params,
      payload: {
        currentPage, sortParams, filterParam,
      },
    });
    setUpdateStatus(true);
  };

  const selectedRecord = (record) => {
    fetchInventoryItemsSearch(searchText);
    const itemGid = record.gid;
    history.push({
      pathname: `${PAGES.INVENTORY_ITEMS}/${itemGid}`,
      state: location.search,
    });
  };

  const handleModalToggle = () => {
    const filterdValues = selectedKeys && selectedKeys.filter(data => !data.available);
    // eslint-disable-next-line
    const avail = (filterdValues.length === 0) ? true : false;
    setAvailableSpace(avail);
    setModalVisible(!modalVisible);
  };

  const handleAvailableSpaceToggle = async (value) => {
    setAvailableSpace(value);
    const params = [];
    // eslint-disable-next-line
    const updatedItemPayload = selectedKeys.map((record) => {
      const shopIds = (record && record.shops && record.shops.length !== 0) ? record.shops.map((option) => option.gid) : [];
      const tagIds = (record && record.tags && record.tags.length !== 0) ? record.tags.map((option) => option.gid) : [];
      const categoryIds = (record && record.categories && record.categories.length !== 0) ? record.categories.map((option) => option.gid) : [];
      // eslint-disable-next-line max-len
      const modifierIds = (record && record.modifierGroups && record.modifierGroups.length !== 0) ? record.modifierGroups.map((option) => option.gid) : [];
      const param = {
        name: record.name,
        gid: record.gid,
        alternateName: record.alternateName,
        sku: record.sku,
        price: (record && record.price) || 0,
        hidden: record.hidden,
        priceType: record.priceType,
        sortOrder: record.sortOrder,
        colorCode: record.colorCode,
        categoryGids: categoryIds || [],
        modifierGroupGids: modifierIds || [],
        available: value,
        cost: (record && record.cost) || 0,
        imageUrl: record.imageUrl,
        description: record.description,
        shopGids: shopIds || [],
        tagGids: tagIds || [],
      };
      params.push(param);
    });
    const parsed = queryString.parse(location.search);
    const currentPage = parsed.pageNum || 1;
    const sortBy = parsed.sortBy || 'name';
    const direction = parsed.direction || 'ASC';
    const sortParams = `&sortBy=${sortBy}&direction=${direction}`;
    const filterParam = parsed.filterParams || '';
    await updateBulkItems({
      params,
      payload: {
        currentPage, sortParams, filterParam,
      },
    });
    setUpdateStatus(true);
    setDisableSwitch(true);
  };


  const columns = [
    {
      title: 'IMAGE',
      dataIndex: 'imageUrl',
      width: '15%',
      align: 'center',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (imageUrl) => (
        <span>
          {imageUrl ? <img src={imageUrl} alt="item" width="100" height="100" /> : <>&#8211;</>}
        </span>
      ),
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      width: '10%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (name) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {name || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'ONLINE NAME',
      dataIndex: 'onlineName',
      width: '10%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (onlineName) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {onlineName || <>&#8211;</>}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'PRICE',
      dataIndex: 'price',
      width: '10%',
      align: 'left',
      render: (price) => {
        const total = (price / 100).toFixed(2);
        return (
          <span> {`${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(total, currencyType)}`}</span>
        );
      },
    },
    {
      title: 'COST',
      dataIndex: 'cost',
      width: '10%',
      align: 'left',
      render: (cost) => {
        const total = (cost / 100).toFixed(2);
        return (
          <span> {`${selectedAccount.currency && selectedAccount.currency.prefix} ${formatNumber(total, currencyType)}`}</span>
        );
      },
    },
    {
      title: 'CATEGORIES',
      dataIndex: 'categories',
      width: '15%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record.categories && record.categories.length !== 0) {
          return (
            <Tooltip placement="top" title="">
              <span style={{ cursor: 'pointer' }}>
                {
                  record.categories.map((info, index) => (record.categories.length === index + 1
                    ? <span>{`${info.name}`}</span>
                    : <span>{`${info.name}, `}</span>))
                }
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'MODIFIER GROUPS',
      dataIndex: 'modifierGroups',
      width: '15%',
      align: 'left',
      className: 'no-break-word',
      render: (text, record) => {
        if (record.modifierGroups && record.modifierGroups.length !== 0) {
          return (
            <Tooltip placement="top" title="">
              <span style={{ cursor: 'pointer' }}>
                {
                  record.modifierGroups.map((info, index) => (record.modifierGroups.length === index + 1
                    ? <span>{`${info.name}`}</span>
                    : <span>{`${info.name}, `}</span>))
                }
              </span>
            </Tooltip>
          );
        } return (
          <Tooltip placement="top" title="">
            <span style={{ cursor: 'pointer' }}>
              <>&#8211;</>
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: 'SORT ORDER',
      dataIndex: 'sortOrder',
      className: 'no-break-word',
      width: '10%',
      align: 'left',
      sorter: true,
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (sortOrder) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {sortOrder || 0}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'ON POS',
      dataIndex: 'hidden',
      className: 'no-break-word',
      width: '10%',
      align: 'left',
      render: (hidden) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {hidden ? 'No' : 'Yes'}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'ONLINE',
      dataIndex: 'onlineEnabled',
      className: 'no-break-word',
      width: '10%',
      align: 'left',
      sorter: false,
      defaultSortOrder: 'ascend',
      sortDirections: ['descend', 'ascend', 'descend'],
      render: (onlineEnabled) => (
        <Tooltip placement="top" title="">
          <span style={{ cursor: 'pointer' }}>
            {onlineEnabled ? 'Yes' : 'No'}
          </span>
        </Tooltip>
      ),
      // render: (onlineEnabled, record) => (
      //   <div
      //     onClick={(e) => {
      //       e.stopPropagation();
      //       updateItems(record, !onlineEnabled, 'onlineEnabled');
      //     }}
      //   >
      //     <Switch
      //       checked={onlineEnabled}
      //     />
      //   </div>
      // ),
    },
    {
      title: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div>AVAILABLE</div>
          {
            (selectedRowKeys && selectedRowKeys.length) > 0 ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleModalToggle();
                  setDisableSwitch(false);
                }}
              >
                <img
                  src={online}
                  alt="Online Icon"
                  style={{ width: 5, marginLeft: 15, cursor: 'pointer' }}
                />
              </div>
            ) : (
              <div>
                <img
                  src={online}
                  alt="Online Icon"
                  style={{ width: 5, marginLeft: 15, cursor: 'not-allowed' }}
                />
              </div>
            )
          }

        </div>
      ),
      dataIndex: 'available',
      className: 'no-break-word',
      width: '10%',
      align: 'left',
      sorter: false,
      sortDirections: ['ascend', 'descend', 'ascend'],
      render: (available, record) => (
        <div
          onClick={(e) => {
            e.stopPropagation();
            updateItems(record, !available, 'available');
          }}
        >
          <Switch
            checked={available}
          />
        </div>
      ),
    },
  ];

  const arrayMove = (array, fromIndex, toIndex) => {
    const newArray = [...array];
    const [movedItem] = newArray.splice(fromIndex, 1);
    newArray.splice(toIndex, 0, movedItem);
    return newArray;
  };
  const SortableItem = SortableElement(({ value }) => (
    <>
      <List.Item
        style={{
          cursor: 'pointer',
          border: 'none',
        }}
      >
        <Row>
          <Col>
            <SpDragInput style={{ width: '410px' }}>
              <DragIcon /> &nbsp;&nbsp; <div style={{ marginLeft: '10px' }}>{value.name}</div>
            </SpDragInput>
          </Col>
        </Row>
      </List.Item>
    </>
  ));
  const SortableList = SortableContainer(() => (
    <List
      dataSource={dragData}
      renderItem={(item, index) => (
        <SortableItem key={item.gid} index={index} value={item} />
      )}
    />
  ));
  const handleSortEnd = ({ oldIndex, newIndex }) => {
    setSortOrderFetch(false);
    const reorderedData = arrayMove(dragData, oldIndex, newIndex);
    setDragData(reorderedData);
    const outputObject = {};
    reorderedData.forEach((item, index) => {
      outputObject[item.gid] = index;
    });
    setPayloadObject(outputObject);
  };
  const saveChanges = () => {
    let params;
    if (sortOrderFetch === true) {
      const outputObject = {};
      dragData.forEach((item, index) => {
        outputObject[item.gid] = index;
      });
      params = outputObject;
    } else {
      params = payloadObject;
    }
    setSpinner(false);
    updateInventorySortItems({
      params,
    });
  };
  const onArrowUpChange = () => {
    setOnSortUp(false);
    setSortOrderFetch(true);
    const sortParam = '&sortBy=name&direction=DESC';
    setSpinner(true);
    fetchInventorySortItems({ sortParam });
  };
  const onArrowDownChange = () => {
    setOnSortUp(true);
    setSortOrderFetch(true);
    setSpinner(true);
    const sortParam = '&sortBy=name&direction=ASC';
    fetchInventorySortItems({ sortParam });
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const sortParams = '&sortBy=name&direction=ASC';
    const currentPage = 1;
    const search = true;
    const filterParams = (preserveParam ? preserveParam.filterParams : '');
    debouncedFetchInventoryItems({
      currentPage, sortParams, filterParams, nameSearch: value ? (value === '' ? null : `?name.LIKE=${value}`) : null, search,
    });
  };

  if (loading && !spiner) {
    return <Loading />;
  }

  return (
    <Card>
      <Row className="mt-4 mb-4">
        <Col>
          <Input.Search
            value={searchText}
            placeholder="Name"
            onChange={(e) => handleSearch(e.target.value)}
            enterButton
          />
        </Col>
      </Row>
      <Row className="my-2">
        <div className="d-flex justify-content-end">
          <RBAC
            permit={
              [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS,
                ROLES.SUPERUSER, ROLES.KDSUSER1, ROLES.KDSADMIN1]
            }
          >
            <Col>
              <Drawer
                title={(
                  <>
                    <div style={{ marginTop: '5px', color: '#000', fontSize: '20px' }}>
                      <Row type="flex" justify="space-between">
                        <Col>
                          Arrange Items
                        </Col>
                        <Col>
                          <span style={{ cursor: 'pointer' }} onClick={() => setOpen1(false)}>
                            <ItemClose />
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
                placement="right"
                closable={false}
                onClose={onClose}
                visible={open1}
                width={500}
                footer={null}
              >
                {
                  loading ? (
                    <div
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      <Spin size="small" />
                    </div>
                  ) : (
                    <>
                      <Row className="d-flex justify-content-end mt-2 mb-2 mr-4">
                        {
                          onSortUp ? (
                            <div
                              onClick={onArrowUpChange}
                              style={{ cursor: 'pointer' }}
                            >
                              <img src={SortDescending} alt="" />
                            </div>
                          ) : (
                            <div
                              onClick={onArrowDownChange}
                              style={{ cursor: 'pointer' }}
                            >
                              <img src={SortAscending} alt="" />
                            </div>
                          )
                        }
                      </Row>
                      <div
                        style={{ marginBottom: '50px' }}
                      >
                        <SortableList
                          items={dragData}
                          onSortEnd={handleSortEnd}
                        />
                      </div>
                      <div>
                        <Row
                          className="d-flex space-between mt-2"
                          style={{
                            position: 'absolute',
                            bottom: 0,
                            width: '450px',
                            padding: '20px',
                            backgroundColor: '#fff',
                            textAlign: 'center',
                            marginLeft: '-15px',
                          }}
                        >
                          <Col
                            style={{
                              marginLeft: '15px',
                            }}
                          >
                            <SpButton
                              type="secondary"
                              style={{ marginRight: '8px', width: '175px' }}
                              onClick={onClose}
                            >
                              Cancel
                            </SpButton>
                          </Col>
                          <Col>
                            <SpButton
                              type="primary"
                              style={{ width: '175px' }}
                              onClick={saveChanges}
                            >
                              Save
                            </SpButton>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )
                }
              </Drawer>
              {
                kdsRole !== 'KDS-USER' && (
                  <>
                    {/* {
                      dragData && dragData.length > 1 ? (
                        <SpButton
                          type="secondary"
                          shape="round"
                          ghost
                          className="mr-3"
                          onClick={() => {
                            const sortParam = '&sortBy=sortOrder&direction=ASC';
                            fetchInventorySortItems({ sortParam });
                            setOpen1(true);
                            setSpinner(true);
                          }}
                        >
                          {'\u002B'}&nbsp;Change Layout
                        </SpButton>
                      ) : null
                    } */}
                    <SpButton
                      type="secondary"
                      shape="round"
                      ghost
                      className="mr-3"
                      onClick={() => {
                        const sortParam = '&sortBy=name&direction=ASC';
                        fetchInventorySortItems({ sortParam });
                        setOpen1(true);
                        setSpinner(true);
                      }}
                    >
                      {'\u002B'}&nbsp;Change Layout
                    </SpButton>
                    <SpButton
                      type="secondary"
                      shape="round"
                      ghost
                      className="mr-3"
                      onClick={() => setAddItemModal(true)}
                    >
                      {'\u002B'}&nbsp;Add Item
                    </SpButton>
                  </>
                )
              }
              <span>
                {
                  exportData !== null
                    ? (
                      <Tooltip placement="left" title="Click here to export Inventory Items">
                        <CSVLink
                          target="_blank"
                          className="mr-3"
                          filename="Inventory Items Report.csv"
                          data={exportData}
                          headers={exportHeaders}
                          onMouseEnter={() => setFillColor(true)}
                          onMouseLeave={() => setFillColor(false)}
                        >
                          <ExportIcon
                            fill={fillColor
                              ? '#279dfe'
                              : '#C0C0C0'}
                          />
                        </CSVLink>
                      </Tooltip>
                    ) : null
                }
              </span>
              <span ref={myRef}>
                <span
                  onClick={() => setOpen(!open)}
                  style={{ cursor: 'pointer' }}
                  onMouseEnter={() => setFilterColor(true)}
                  onMouseLeave={() => setFilterColor(false)}
                >
                  {open && (
                    <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                      <FilterIcon
                        fill={filterColor
                          ? '#279dfe'
                          : '#C0C0C0'}
                      />
                    </Badge>
                  )}
                  {!open && (
                    <Tooltip placement="left" title="Click here to filter Inventory Items">
                      <Badge count={count} style={{ backgroundColor: '#0090ff' }}>
                        <FilterIcon
                          fill={filterColor
                            ? '#279dfe'
                            : '#C0C0C0'}
                        />
                      </Badge>
                    </Tooltip>
                  )}
                </span>
                {open && (
                  <FilterWrapper
                    style={{ marginLeft: '20px' }}
                  >
                    <Filters
                      filtersGrid={filterPayments}
                      filterTypes={filterTypes}
                      handleChange={handleChange}
                      onCheck={onCheck}
                      fiterOperators={fiterOperators}
                      handleOperator={handleOperator}
                      handleValue={handleValue}
                      startDate={startDate}
                      endDate={endDate}
                      currentDate={currentDate}
                      stuses={filterStatusType === 'hidden' ? TransactionTypes : AvailableTypes}
                      availabie={AvailableTypes}
                      handleFromValue={handleFromValue}
                      handleToValue={handleToValue}
                    />
                    <div className="px-4 mt-2">
                      {
                        filterPayments.length < PAYMENTLINKS_FILTERS_TYPES.length && (
                          <div className="mb-2 ml-3" style={{ cursor: 'pointer' }}>
                            <SpText
                              fontWeight="600"
                              color="#279dfe"
                              onClick={handleAddClick}
                            >
                              +&nbsp; Add another filter
                            </SpText>
                          </div>
                        )
                      }
                    </div>
                    <div className="d-flex mb-2 justify-content-end mr-3">
                      <SpButton
                        type="secondary"
                        shape="round"
                        className="mr-2"
                        ghost
                        onClick={resetFilters}
                      >
                        Clear
                      </SpButton>
                      <SpButton
                        type="primary"
                        shape="round"
                        onClick={applyFilters}
                      >
                        Save
                      </SpButton>
                    </div>
                  </FilterWrapper>
                )}
              </span>
            </Col>
          </RBAC>
        </div>
      </Row>
      <Modal
        visible={modalVisible}
        closable={false}
        footer={null}
        onCancel={handleModalToggle}
        width={200}
        style={{
          top: '277px',
          left: '470px',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px',
          }}
        >

          <div>
            <Switch
              checked={availableSpace}
              disabled={disableSwitch}
              onChange={handleAvailableSpaceToggle}
            />
          &nbsp; &nbsp;Availablity
          </div>
        </div>
      </Modal>
      {/* ADD ITEM MODAL */}
      {
        addItemModal && (
          <AddItem
            visible={addItemModal}
            submitting={submitting}
            itemsList={itemsList}
            modifierItemsList={modifierItemsList}
            labelList={labelList}
            selectedAccount={selectedAccount}
            close={() => setAddItemModal(false)}
            submit={addSelectedItem}
          />
        )
      }
      <RBAC
        fallback={(
          <Table
            columns={columns.map(column => {
              if (column.dataIndex === sorting.field) {
                return {
                  ...column,
                  sortOrder: sorting.order,
                };
              }
              return column;
            })}
            onRow={(record) => ({
              onClick: () => {
                selectedRecord(record);
              },
            })}
            rowKey={(record) => record.gid}
            dataSource={inventoryItems}
            pagination={pagination}
            onChange={handleTableChange}
            rowSelection={rowSelection}
          />
        )}
        permit={
          [ROLES.OWNER, ROLES.ADMINISTRATOR, ROLES.DEVELOPER, ROLES.OPERATIONS,
            ROLES.SUPERUSER, ROLES.KDSUSER1, ROLES.KDSADMIN1]
        }
      >
        <Table
          rowClassName={() => 'ant-table-clickable-row'}
          columns={columns.map(column => {
            if (column.dataIndex === sorting.field) {
              return {
                ...column,
                sortOrder: sorting.order,
              };
            }
            return column;
          })}
          onRow={(record) => ({
            onClick: () => {
              selectedRecord(record);
            },
          })}
          rowKey={(record) => record.gid}
          dataSource={inventoryItems}
          pagination={pagination}
          onChange={handleTableChange}
          rowSelection={rowSelection}
        />
      </RBAC>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  inventoryItems: state.inventoryItems.content,
  totalElements: state.inventoryItems.totalElements,
  modifierItemsList: state.inventoryItems.modifierItemsList.content,
  itemsList: state.inventoryItems.itemsList.content,
  sortItemsList: state.inventoryItems.sortItemsList.content,
  loading: state.loading.loading,
  validator: state.loading.validator,
  submitting: state.loading.submitting,
  test: state.account.test,
  selectedAccount: state.account.selectedAccount,
  isSuperUser: state.user.superUser,
  preserveFilters: state.filterParams.preserveFilters,
  labelList: state.inventoryPrinterLabel.labelList.content,
  searchTypes: state.inventoryItems.searchTypes,
});

const mapDispatchToProps = (dispatch) => ({
  fetchInventoryItems: param => dispatch(inventoryItemsActions.fetchInventoryItems({
    payload: param,
  })),
  fetchInventoryItemsSearch: param => dispatch(inventoryItemsActions.fetchInventoryItemsSearch({
    payload: param,
  })),
  fetchInventorySortItems: param => dispatch(inventoryItemsActions.fetchInventorySortItems({
    payload: param,
  })),
  fetchItemsList: param => dispatch(inventoryItemsActions.fetchItemsList({
    payload: param,
  })),
  fetchModifierItemsList: param => dispatch(inventoryItemsActions.fetchModifierItemsList({
    payload: param,
  })),
  updateInventoryItems: param => dispatch(inventoryItemsActions.updateInventoryItems({
    payload: param,
  })),
  updateInventorySortItems: param => dispatch(inventoryItemsActions.updateInventorySortItems({
    payload: param,
  })),
  addInventoryItems: param => dispatch(inventoryItemsActions.addInventoryItems({
    payload: param,
  })),
  setNotification: ({ type, payload }) => dispatch(notificationActions.setNotification({
    type,
    payload,
  })),
  setFilterData: (value) => dispatch(filterParamsActions.setFilterData({
    type: FILTERS_SPECS.SET_FILTER_DATA,
    payload: value,
  })),
  fetchPrinterLabelList: (payload) => dispatch(inventoryPrinterLabelActions.fetchPrinterLabelList({
    payload,
  })),
  updateBulkItems: param => dispatch(inventoryItemsActions.updateBulkItems({
    payload: param,
  })),
});

// $FlowFixMe
export default connect(mapStateToProps, mapDispatchToProps)(ItemsList);
