import { call, put } from 'redux-saga/effects';

import generateQuery from 'utils/generateQuery';

import {
  axiosAuthInstance,
  API_END_POINTS,
} from 'api';
import { MESSAGES } from 'appconstants';

import {
  VENDORS,
  LOADING,
  NOTIFICATION,
} from '../actionTypes';

const fetchVendors = async (payload) => {
  const query = generateQuery(payload.currentPage);
  const sorting = payload.sortParams ? payload.sortParams : '&sortBy=createdAt&direction=DESC';
  if (payload.filterParams && !payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CONTACTS}?${payload.filterParams}`);
  }
  if (payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CONTACTS}${query}${sorting}&${payload.filterParams}`);
  }
  if (!payload.filterParams && payload.sortParams) {
    return axiosAuthInstance.get(`${API_END_POINTS.GET_CONTACTS}${query}${sorting}`);
  }
  return axiosAuthInstance.get(`${API_END_POINTS.GET_CONTACTS}${query}${sorting}`);
};

const postVendor = async ({ params }) => axiosAuthInstance.post(API_END_POINTS.GET_CONTACTS, params);

const patchVendor = async ({ gid, params }) => axiosAuthInstance.patch(`${API_END_POINTS.GET_CONTACTS}/${gid}`, params);

const vendorDelete = async ({ gid }) => axiosAuthInstance.delete(`${API_END_POINTS.GET_CONTACTS}/${gid}`);

// eslint-disable-next-line max-len
const deleteSingleCustomTypeAPI = async (payload) => {
  console.log('deleteSingleCustomTypeAPI', payload);
  axiosAuthInstance.delete(`${API_END_POINTS.CUSTOM_SUGESSTIONS}/${payload.customType}?type=${payload.type}`);
};

const deleteAllCustomTypesAPI = async (payload) => axiosAuthInstance.delete(`${API_END_POINTS.CUSTOM_SUGESSTIONS}?type=${payload.type}`);

const getCustomTypesAPI = async (payload) => {
  console.log('payload', payload);
  return axiosAuthInstance.get(`${API_END_POINTS.CUSTOM_SUGESSTIONS}?type=${payload.type}`);
};

export function* getVendors(action) {
  try {
    const { data: { entity } } = yield call(fetchVendors, action.payload);
    yield put({ type: VENDORS.SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: VENDORS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* addVendor(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(postVendor, action.payload);
    const { data: { entity } } = yield call(fetchVendors, action.payload);
    yield put({ type: VENDORS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.VENDORS.ADD_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* deleteVendor(action) {
  try {
    yield put({ type: LOADING.VALIDATING });
    yield put({ type: LOADING.SUBMITTING });
    yield call(vendorDelete, action.payload);
    const { data: { entity } } = yield call(fetchVendors, action.payload);
    yield put({ type: VENDORS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.VENDORS.DELETE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}

export function* updateVendor(action) {
  try {
    yield put({ type: LOADING.SUBMITTING });
    yield put({ type: LOADING.VALIDATING });
    yield call(patchVendor, action.payload);
    const { data: { entity } } = yield call(fetchVendors, action.payload);
    yield put({ type: VENDORS.SUCCESS, payload: entity });
    yield put({
      type: NOTIFICATION.SUCCESS,
      payload: MESSAGES.VENDORS.UPDATE_SUCCESS,
    });
    yield put({ type: LOADING.VALIDATED });
  } catch (e) {
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  } finally {
    yield put({ type: LOADING.SUBMITTED });
  }
}


export function* getCustomTypes(action) {
  try {
    console.log('getCustomTypes action ', action.payload);
    const { data: { entity } } = yield call(getCustomTypesAPI, action.payload);
    yield put({ type: VENDORS.CUSTOM_TYPE_SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: VENDORS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* deleteSingleCustomType(action) {
  try {
    console.log('deleteSingleCustomType action ', action);
    yield call(deleteSingleCustomTypeAPI, action.payload);
    const { data: { entity } } = yield call(getCustomTypesAPI, action.payload);
    yield put({ type: VENDORS.CUSTOM_TYPE_SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: VENDORS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}

export function* deleteAllCustomTypes(action) {
  try {
    console.log('deleteAllCustomTypes action ', action);
    yield call(deleteAllCustomTypesAPI, action.payload);
    const { data: { entity } } = yield call(getCustomTypesAPI, action.payload);
    yield put({ type: VENDORS.CUSTOM_TYPE_SUCCESS, payload: entity });
  } catch (e) {
    yield put({ type: VENDORS.FAILED, payload: e.response.data });
    if (e.response.status !== 401) {
      yield put({
        type: NOTIFICATION.ERROR,
        payload: e.response.data ? e.response.data.message : MESSAGES.API_ERROR,
      });
    }
  }
}
