import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  withRouter,
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Menu, Card, Icon } from 'antd';
import { SpTab } from 'components/DesignKit';

import FundingSourceList from './components/FundingSourceList';
import FundingSourceLink from './components/FundingSourceLink';
import Contacts from '../Vendors/VendorsList';

type Props = {
  location: {
    pathname: string
  },
};

const FundingSources = (props: Props) => {
  const { selectedAccount, test } = useSelector(state => state.account);
  const [current, setCurrent] = useState('contacts');
  const {
    location,
  } = props;

  const setMenu = (query) => {
    switch (query) {
      case '/funding-sources/link': {
        setCurrent('funding-sourcelink');
        break;
      }
      case '/funding-sources': {
        setCurrent('funding-sources');
        break;
      }
      case '/funding-sources/contacts': {
        setCurrent('contacts');
        break;
      }
      default:
        setCurrent('contacts');
    }
  };

  useEffect(() => {
    setMenu(location.pathname);
  }, [location]);

  return (
    <div>
      {
        ((!test) && (selectedAccount && (!selectedAccount.issuer)))
          ? (
            <Card
              className="mx-auto mt-5"
              style={{ width: 500, height: 250, boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.3)' }}
              bodyStyle={{ height: '100%' }}
            >
              <div className="d-flex flex-column align-items-center justify-content-center mx-auto h-100">
                <Icon
                  type="exclamation-circle"
                  className="mb-4"
                  style={{ color: 'red', fontSize: '32px', textAlign: 'center' }}
                />
                <h5>Please go to settings and provide additional business information to enable live transfers</h5>
              </div>
            </Card>
          )
          : (
            <>
              <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
                <Menu.Item key="contacts">
                  <SpTab
                    to="/funding-sources/contacts"
                    selected={current === 'contacts'}
                  >
                    Contacts
                  </SpTab>
                </Menu.Item>
                <Menu.Item key="funding-sources">
                  <SpTab
                    to="/funding-sources"
                    selected={current === 'funding-sources'}
                  >
                    Funding Sources
                  </SpTab>
                </Menu.Item>
                <Menu.Item key="funding-sourcelink">
                  <SpTab
                    to="/funding-sources/link"
                    selected={current === 'funding-sourcelink'}
                  >
                    Funding Source Links
                  </SpTab>
                </Menu.Item>
              </Menu>
              <Switch>
                <Route exact path="/funding-sources/link" component={FundingSourceLink} />
                <Route exact path="/funding-sources" component={FundingSourceList} />
                <Route exact path="/funding-sources/contacts" component={Contacts} />
              </Switch>
            </>
          )
      }
    </div>
  );
};

export default withRouter(FundingSources);
