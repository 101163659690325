export default status => {
  if (status === 'CREATED') {
    return '#e2e2e2';
  } if (status === 'PROCESSING') {
    return '#ececff';
  } if (status === 'SUCCEEDED') {
    return '#ececff';
  } if (status === 'PARTIAL_SUCCEEDED') {
    return '#e2e2e2';
  } if (status === 'FAILED') {
    return '#ffe0b1';
  } return '#e2e2e2';
};
