/* eslint-disable */
import React from 'react';

const paymentpages = ({ fill = "silver" }) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1.65C0 0.73873 0.73873 0 1.65 0H11.3453C12.2566 0 12.9953 0.73873 12.9953 1.65V7.65C12.9953 8.12811 12.8874 8.2924 12.5625 8.59375L10.1654 7.19107C10.5225 6.5868 11.0562 6.0992 11.6953 5.79921V3.141C10.9044 2.7698 10.2751 2.11133 9.94182 1.3H3.27867C2.91431 2.18693 2.19623 2.89117 1.3 3.23717V5.70304C2.31435 6.09464 3.10049 6.94514 3.40389 8H6.06607L6.06607 9.3H1.65C0.73873 9.3 0 8.56127 0 7.65V1.65Z" fill={fill}/>
<path d="M6.49765 6.14999C7.32608 6.14999 7.99765 5.47842 7.99765 4.64999C7.99765 3.82157 7.32608 3.14999 6.49765 3.14999C5.66922 3.14999 4.99765 3.82157 4.99765 4.64999C4.99765 5.47842 5.66922 6.14999 6.49765 6.14999Z" fill={fill}/>
<path d="M8.61728 13.7461C8.45786 13.7461 8.30696 13.7118 8.16457 13.6432C8.02217 13.5746 7.89905 13.4789 7.79518 13.3559L5.47198 10.5704L5.73575 10.3008C5.87271 10.1576 6.04011 10.0784 6.23793 10.0633C6.43576 10.0482 6.61656 10.0981 6.78033 10.2128L7.57272 10.7653V7.42583C7.57272 7.31567 7.61094 7.22237 7.68739 7.14592C7.76384 7.06947 7.85714 7.03125 7.96728 7.03125C8.07743 7.03125 8.17073 7.06947 8.24718 7.14592C8.32363 7.22237 8.36185 7.31567 8.36185 7.42583V12.2653L7.26257 11.507L8.42961 12.8555C8.45625 12.8871 8.48734 12.9119 8.52288 12.9299C8.5584 12.948 8.59615 12.957 8.63613 12.957H11.0662C11.2327 12.957 11.3752 12.8977 11.4938 12.7792C11.6124 12.6606 11.6716 12.5181 11.6716 12.3516V9.95699C11.6716 9.84685 11.7099 9.75355 11.7863 9.6771C11.8628 9.60065 11.9561 9.56243 12.0662 9.56243C12.1763 9.56243 12.2696 9.60065 12.3461 9.6771C12.4225 9.75355 12.4608 9.84685 12.4608 9.95699V12.3516C12.4608 12.7371 12.3247 13.0659 12.0526 13.338C11.7805 13.6101 11.4517 13.7461 11.0662 13.7461H8.61728ZM8.93903 11.0795V8.75395C8.93903 8.64413 8.97725 8.551 9.0537 8.47455C9.13015 8.3981 9.22344 8.35988 9.33359 8.35988C9.44374 8.35988 9.53703 8.3981 9.61348 8.47455C9.68993 8.551 9.72816 8.64413 9.72816 8.75395V11.0795H8.93903ZM10.3053 11.0795V9.24323C10.3053 9.13308 10.3436 9.03978 10.42 8.96333C10.4965 8.88688 10.5897 8.84865 10.6999 8.84865C10.81 8.84865 10.9033 8.88688 10.9798 8.96333C11.0562 9.03978 11.0945 9.13308 11.0945 9.24323V11.0795H10.3053Z" fill={fill}/>
  </svg>

);

export default paymentpages;