import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  createRef,
} from 'react';
import {
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Radio,
  Checkbox,
} from 'antd';
import NumberFormat from 'react-number-format';
import SimpleReactValidator from 'simple-react-validator';
import { Transition } from 'react-transition-group';
import {
  SpForm,
  SpError,
  SpTextMute,
  SpText,
  SpButton,
} from 'components/DesignKit';

import {
  validators,
} from 'utils/validationMessages';

import { API_END_POINTS } from 'api';
import moment from 'moment-timezone';

import {
  MESSAGES,
  PHONE_LENGTH,
  // COUNTRIES,
  COUNTRY_CODES,
} from 'appconstants';
import getCountryPhoneCode from 'utils/getCountryPhoneCode';

import KYCFileUpload from 'components/KycFileUpload';

const { Option } = Select;

type Props = {
  country: string,
  isIndia: boolean,
  statesInCountry: Array<Object>,
  businessRef: Array<Object>,
  id: number,
  show: boolean,
  visible: boolean,
  onRemoveRepresentatives: Function,
  selectedAccount: Object,
  phoneCode: string,
}

const duration = 200;

const defaultStyle = {
  transition: `opacity ${duration}ms linear`,
  opacity: 0,
  display: 'none',
};

const transitionStyles = {
  entering: { opacity: 0.25, display: 'block' },
  entered: { opacity: 1, display: 'block' },
  exiting: { opacity: 0, display: 'block' },
  exited: { opacity: 0, display: 'none' },
};

const BusinessRepresentativeDetails = (props: Props, ref) => {
  const {
    country,
    statesInCountry,
    isIndia,
    businessRef,
    onRemoveRepresentatives,
    id,
    show,
    visible,
    selectedAccount,
    phoneCode,
  } = props;

  const thisRef = createRef();
  const businessAdress = businessRef.current.getValues();
  const bzipCode = businessAdress.zipCode;
  const bcity = businessAdress.city;
  const bstate = businessAdress.state;
  const bstreetAddress = businessAdress.streetAddress;
  const [, forceUpdate] = useState();
  const [givenName, setGivenName] = useState('');
  const [familyName, setFamilyName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [pan, setPan] = useState('');
  const [title, setTitle] = useState('');
  const [legalAddress, setLegalAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState();
  const [ssn, setSsn] = useState('');
  const [bankStatementUrl, setBankStatementUrl] = useState('');
  const [businessRepPanUrl, setBusinessRepPanUrl] = useState(null);
  const [dob, setDob] = useState('');
  const [hasControl, setHasControl] = useState(true);
  const [checked, setChecked] = useState(false);
  const [showRemove, setShowRemove] = useState(false);
  const [zipTag, setZipTag] = useState('');
  const [countryCode, setCountryCode] = useState(phoneCode
    || getCountryPhoneCode((selectedAccount) && (selectedAccount.country) && (selectedAccount.country.id)));
  const [phoneFormate, setPhoneFormate] = useState('');
  const [ownerShipPercentage, setOwnerShipPercentage] = useState();
  const [driverLicenseUrl, setDriverLicenseUrl] = useState('');
  const panRegex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  const postalCodeRegex = country === 'India' ? /^[1-9][0-9]{5}$/ : /^[0-9][0-9]{4}$/;
  const ifNumber = /^[0-9]+$/;

  useEffect(() => {
    if (phoneCode === '+1') {
      setPhoneFormate('(###) ###-####');
    } else if (phoneCode === '+91') {
      setPhoneFormate('##### #####');
    }
  }, [phoneCode]);

  useEffect(() => {
    if (ownerShipPercentage >= 25) {
      setHasControl(true);
    } else {
      setHasControl(false);
    }
  }, [ownerShipPercentage]);

  const simpleValidator = useRef(new SimpleReactValidator({
    validators: {
      phoneLength: {
        message: MESSAGES.ADD_USER.PHONE,
        rule: val => val.length >= PHONE_LENGTH.MIN && val.length <= PHONE_LENGTH.MAX,
      },
      pan: {
        message: MESSAGES.BUSINESS_DETAILS.PAN,
        rule: val => panRegex.test(val),
      },
      nameValidation: {
        message: 'Name must be valid',
        rule: val => /^[A-Za-z0-9&,\s.-]{1,50}$/.test(val),
    },
      validSSN: {
        message: MESSAGES.BUSINESS_DETAILS.SSN,
        rule: val => (new RegExp(/^[0-9]{9}$/).exec(val) !== null),
      },
      postalcode: {
        message: validators.address.pincode,
        rule: val => (postalCodeRegex.test(val)),
      },
      checkLowerCase: {
        message: 'Email address must not contain uppercase letters.',
        rule: (val) => val === val.toLowerCase(),
      },
      ownerShip: {
        message: 'Percentage ownership should be equal or more than 25% and equal or less than 100%',
        rule: (val) => {
          const value = val && val.replace('%', '');
          return (ifNumber.test(value) && (value >= 25) && (value <= 100));
        },
      },
    },
  }));

  const onCountryCodeChange = (value) => {
    if (value === '+1') {
      setPhoneFormate('(###) ###-####');
      setCountryCode(value);
    } else if (value === '+91') {
      setPhoneFormate('##### #####');
      setCountryCode(value);
    }
  };

  const onPhoneNumberChange = (e) => {
    const updatedPhone = e.currentTarget.value;
    setPhoneNumber(updatedPhone.replace(/[^0-9+]/g, ''));
  };

  useEffect(() => {
    if (show) {
      setShowRemove(true);
    } else {
      setShowRemove(false);
    }
  }, [show]);

  useEffect(() => {
    if ((selectedAccount && selectedAccount.country && selectedAccount.country.id) === 1) {
      setZipTag('Zip code');
    } else if ((selectedAccount && selectedAccount.country && selectedAccount.country.id) === 2) {
      setZipTag('Pin code');
    }
  }, []);

  useEffect(() => {
    if (checked) {
      setLegalAddress(bstreetAddress);
      setZipCode(bzipCode);
      setCity(bcity);
      setState(bstate);
    } else {
      setLegalAddress();
      setZipCode();
      setCity();
      setState();
    }
  }, [checked]);

  useImperativeHandle(ref, () => ({
    validate: () => {
      const formValid = simpleValidator.current.allValid();
      if ((!formValid)) {
        simpleValidator.current.showMessages();
        forceUpdate(1);
        return false;
      }
      return true;
    },
    getValues: () => ({
      givenName,
      familyName,
      contactEmail,
      phoneNumber,
      title,
      legalAddress,
      zipCode,
      city,
      state,
      ssn,
      pan,
      dob,
      hasControl,
      bankStatementUrl,
      countryCode,
      businessRepPanUrl,
      driverLicenseUrl,
      ownerShipPercentage,
    }),
    offsetTop: thisRef.current.offsetTop,
    scrollIntoView: (...params) => thisRef.current.scrollIntoView(...params),
  }));

  return (
    <div ref={thisRef}>
      <Transition
        in={visible}
        timeout={duration}
      >
        {(stateTransition) => (
          <div
            style={{
              ...defaultStyle,
              ...transitionStyles[stateTransition],
            }}
          >
            <Row type="flex" className="my-2 mb-3">
              <Col className="mr-5">
                <SpText className="text-uppercase" fontSize="20px">
                  Business Representative Details
                </SpText>
              </Col>
              <Col>
                {
                  showRemove
                    ? (
                      <SpButton
                        push={18}
                        type="secondary"
                        shape="round"
                        id={id}
                        onClick={() => onRemoveRepresentatives(id)}
                      >
                        Remove
                      </SpButton>
                    )
                    : null
                }
              </Col>
            </Row>
            <SpForm>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Given Name</span>
                </Col>
                <Col span={4}>
                  <Input
                    placeholder="Given Name"
                    value={givenName}
                    onBlur={() => setGivenName(givenName && givenName.trim())}
                    onChange={e => setGivenName(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('Given Name', givenName, 'required|nameValidation')}
                  </SpError>
                </Col>

                <Col span={3} className="ml-4">
                  <span>Family Name</span>
                </Col>
                <Col span={4}>
                  <Input
                    placeholder="Family Name"
                    value={familyName}
                    onBlur={() => setFamilyName(familyName && familyName.trim())}
                    onChange={e => setFamilyName(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('Family Name', familyName, 'required|nameValidation')}
                  </SpError>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Contact Email</span>
                </Col>
                <Col span={6}>
                  <Input
                    placeholder="example@example.com"
                    value={contactEmail}
                    onBlur={() => setContactEmail(contactEmail && contactEmail.trim())}
                    onChange={e => setContactEmail(e.currentTarget.value && e.currentTarget.value.toLowerCase())}
                  />
                  <SpError>
                    {simpleValidator.current.message('Email', contactEmail, 'required|email')}
                  </SpError>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Phone Number</span>
                </Col>
                <Col span={6}>
                  <div className="d-flex">
                    <Select
                      style={{ width: '25%' }}
                      defaultValue={phoneCode || countryCode}
                      onChange={onCountryCodeChange}
                    >
                      {COUNTRY_CODES.map((item) => (
                        <Option key={item.ID} value={item.PHONE_CODE}>
                          {item.PHONE_CODE}
                        </Option>
                      ))}
                    </Select>
                    <div style={{ width: '75%' }}>
                      <NumberFormat
                        className="InputnumFormate"
                        value={phoneNumber}
                        format={phoneFormate}
                        mask="_"
                        allowEmptyFormatting
                        onChange={onPhoneNumberChange}
                      />
                      <SpError>
                        {simpleValidator.current.message('Phone', phoneNumber, 'required|phone')}
                      </SpError>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Title</span>
                </Col>
                <Col span={6}>
                  <Input
                    placeholder="CEO"
                    value={title}
                    onBlur={() => setTitle(title && title.trim())}
                    onChange={e => setTitle(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('Title', title, 'required|alpha')}
                  </SpError>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Legal Address of the Representative</span>
                </Col>
                <Col span={6}>
                  <Input
                    placeholder="Street address of the representative"
                    value={legalAddress}
                    onBlur={() => setLegalAddress(legalAddress && legalAddress.trim())}
                    onChange={e => setLegalAddress(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('Address', legalAddress, 'required')}
                  </SpError>
                </Col>
                <Col span={6} className="ml-4">
                  <Checkbox
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    value="Same as Business Address"
                  >Same as the business address
                  </Checkbox>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>{zipTag}</span>
                </Col>
                <Col span={4}>
                  <Input
                    placeholder={zipTag}
                    value={zipCode}
                    onBlur={() => setZipCode(zipCode && zipCode.trim())}
                    onChange={e => setZipCode(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('Area Code', zipCode, 'required|postalcode')}
                  </SpError>
                </Col>

                <Col span={2} className="ml-4">
                  <span>City</span>
                </Col>
                <Col span={4}>
                  <Input
                    placeholder="City"
                    value={city}
                    onBlur={() => setCity(city && city.trim())}
                    onChange={e => setCity(e.currentTarget.value)}
                  />
                  <SpError>
                    {simpleValidator.current.message('City', city, 'required')}
                  </SpError>
                </Col>

                <Col span={2} className="ml-4">
                  <span>State</span>
                </Col>
                <Col span={4}>
                  <Select
                    placeholder="Select State"
                    showSearch
                    optionFilterProp="children"
                    className="w-100"
                    value={state}
                    onChange={value => setState(value)}
                  >
                    {
                      statesInCountry.map(s => (
                        <Option
                          key={1}
                          value={s.id}
                          className="text-capitalize"
                        >
                          {s.name}
                        </Option>
                      ))
                    }
                  </Select>
                  <SpError>
                    {simpleValidator.current.message('State', state, 'required')}
                  </SpError>
                </Col>
              </Row>
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Country</span>
                </Col>
                <Col span={4}>
                  <Select value={country} style={{ width: '100%' }} disabled>
                    <Option key={country} value={country}>{country}</Option>
                  </Select>
                </Col>
              </Row>
              {
                isIndia
                  ? (
                    <Row type="flex" justify="start" align="middle">
                      <Col span={4}>
                        <span>Representative&apos;s PAN</span>
                      </Col>
                      <Col span={4}>
                        <Input
                          placeholder="PAN"
                          value={pan}
                          onBlur={() => setPan(pan && pan.trim())}
                          onChange={e => setPan((e.currentTarget.value).toUpperCase())}
                        />
                        <SpError>
                          {simpleValidator.current.message('pan', pan, 'required|pan', { messages: { required: 'The PAN field is required' } })}
                        </SpError>
                      </Col>
                    </Row>
                  )
                  : (
                    <Row type="flex" justify="start" align="middle">
                      <Col span={4}>
                        <span>SSN</span>
                      </Col>
                      <Col span={4}>
                        <Input.Password
                          placeholder="SSN"
                          autoComplete="new-password"
                          value={ssn}
                          onBlur={() => setSsn(ssn && ssn.trim())}
                          onChange={e => setSsn(e.currentTarget.value)}
                        />
                        <SpError>
                          {simpleValidator.current.message('ssn', ssn, 'required|validSSN', { messages: { required: 'This SSN field is required' } })}
                        </SpError>
                      </Col>
                    </Row>
                  )
              }
              <Row type="flex" justify="start" align="middle">
                <Col span={4}>
                  <span>Date of birth</span>
                </Col>
                <Col span={6}>
                  {/* Todo (format={COUNTRIES.USA.id === countryId ? 'MM-DD-YYYY' : 'DD-MM-YYYY'}) */}
                  <DatePicker
                    allowClear={false}
                    onChange={(date, dateString) => setDob(dateString)}
                    disabledDate={current => current && current > moment().subtract(1, 'days')}
                  />
                  <SpError>
                    {simpleValidator.current.message('Date of birth', dob, 'required')}
                  </SpError>
                </Col>
              </Row>
              {
                isIndia
                  ? (
                    <>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={4}>
                          <span>Id Proof</span>
                        </Col>
                        <Col span={6}>
                          <KYCFileUpload
                            name="Bank Statement"
                            endPoint={API_END_POINTS.UPLOAD_REP_ADDRESS}
                            url={bankStatementUrl}
                            setUrl={value => setBankStatementUrl(value)}
                            type="Business"
                          />
                          <SpError>
                            {simpleValidator.current.message('Id proof', bankStatementUrl, 'required')}
                          </SpError>
                        </Col>
                        <Col span={8} className="ml-4">
                          <SpTextMute>
                            Upload an Image or pdf with both sides of a government issued photo ID
                            (Passport, Driving License, Aadhar Card, Election Card). The address on ID
                            should match provided address. Self attested mandatory.
                          </SpTextMute>
                        </Col>
                      </Row>
                      <Row type="flex" justify="start" align="middle">
                        <Col span={4}>
                          <span>PAN Proof</span>
                        </Col>
                        <Col span={6}>
                          <KYCFileUpload
                            name="PAN Proof"
                            endPoint={API_END_POINTS.UPLOAD_REP_PAN}
                            url={businessRepPanUrl}
                            keyType="BUSINESS_REP_PAN"
                            setUrl={value => setBusinessRepPanUrl(value)}
                            type="Business"
                          />
                          <SpError>
                            {simpleValidator.current.message('pan proof', businessRepPanUrl, 'required')}
                          </SpError>
                        </Col>
                        <Col span={8} className="ml-4">
                          <SpTextMute>
                            PAN Card of the Signing Authority. Self attested mandatory.
                          </SpTextMute>
                        </Col>
                      </Row>
                    </>
                  )
                  : null
              }
              {!isIndia && (
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Govt. ID (Driver License, Passport etc.)</span>
                  </Col>
                  <Col span={6}>
                    <KYCFileUpload
                      name="Driver License"
                      endPoint={API_END_POINTS.US_BUSINESS_GOVERNMENT_ID}
                      url={driverLicenseUrl}
                      keyType="US_BUSINESS_GOVERNMENT_ID"
                      setUrl={value => setDriverLicenseUrl(value)}
                      type="Business"
                    />
                    <SpError>
                      {simpleValidator.current.message('govt id url', driverLicenseUrl, 'required')}
                    </SpError>
                  </Col>
                  <Col span={8} className="ml-4" />
                </Row>
              )}
              {!isIndia && (
                <Row type="flex" justify="start" align="middle">
                  <Col span={4}>
                    <span>Percentage Ownership</span>
                  </Col>
                  <Col span={6}>
                    <Input
                      suffix="%"
                      value={ownerShipPercentage}
                      placeholder="Percentage Ownership"
                      onBlur={() => setOwnerShipPercentage(ownerShipPercentage && ownerShipPercentage.trim())}
                      onChange={e => setOwnerShipPercentage(e.currentTarget.value)}
                    />
                    <SpError>
                      {simpleValidator.current.message('OwnerShip Percentage', ownerShipPercentage, 'required|ownerShip')}
                    </SpError>
                  </Col>
                </Row>
              )}
              {
                isIndia && (
                  <Row>
                    <Col className="mb-2">
                      Do you own more than 25% of this business, or exercise significant control?
                    </Col>
                    <Col className="d-flex">
                      <Radio.Group
                        onChange={e => setHasControl(e.target.value)}
                        value={hasControl}
                      >
                        {/* eslint-disable-next-line */}
                        <Radio value={true} className="ml-2">Yes, I do</Radio>
                        <Radio value={false}>No, I do not</Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                )
              }
            </SpForm>
          </div>
        )}
      </Transition>
    </div>
  );
};

// $FlowFixMe
export default forwardRef(BusinessRepresentativeDetails);
