import React, { useEffect, useState } from 'react';
import {
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import { Menu } from 'antd';
import queryString from 'query-string';

import { SpTab } from 'components/DesignKit';
import AllInvoices from './AllInvoices';

type Props = {
  location: {
    pathname: string,
    search: string,
  },
  history: {
    push: Function,
  },
};

const Vendorinvoices = (props: Props) => {
  const [current, setCurrent] = useState('invoices');
  const { path } = useRouteMatch();
  const {
    location,
    history,
  } = props;

  const setMenu = (query) => {
    const parsed = queryString.parse(query);
    if (parsed['status.IN']) {
      const value = parsed['status.IN'];
      switch (value) {
        case 'DRAFT': {
          setCurrent('draft');
          break;
        }
        case 'ACTIVE': {
          setCurrent('outstanding');
          break;
        }
        case 'PAID': {
          setCurrent('paid');
          break;
        }
        case 'ALL': {
          setCurrent('invoices');
          break;
        }
        default:
          setCurrent('invoices');
      }
    }
  };

  useEffect(() => {
    if (location.search) {
      setMenu(location.search);
    } else {
      history.push(`${path}?status.IN=ALL`);
    }
  }, [location]);

  return (
    <div>
      <Menu onClick={e => setCurrent(e.key)} selectedKeys={[current]} mode="horizontal" style={{ fontSize: '20px' }}>
        <Menu.Item key="draft">
          <SpTab
            to={`${path}?status.IN=DRAFT`}
            selected={current === 'draft'}
          >
            Draft
          </SpTab>
        </Menu.Item>
        <Menu.Item key="outstanding">
          <SpTab
            to={`${path}?status.IN=ACTIVE`}
            selected={current === 'outstanding'}
          >
            Outstanding
          </SpTab>
        </Menu.Item>
        <Menu.Item key="paid">
          <SpTab
            to={`${path}?status.IN=PAID`}
            selected={current === 'paid'}
          >
            Paid
          </SpTab>
        </Menu.Item>
        <Menu.Item key="invoices">
          <SpTab
            to={`${path}?status.IN=ALL`}
            selected={current === 'invoices'}
          >
            All Invoices
          </SpTab>
        </Menu.Item>
      </Menu>
      <AllInvoices {...props} />
    </div>
  );
};

export default withRouter(Vendorinvoices);
