/* eslint-disable */
import React from 'react';

const paymentslink = ({ fill = "silver" }) => (
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.8877 8.83472H10.2703L13 5.72418V6.62997C13 7.84762 12.0543 8.83472 10.8877 8.83472Z" fill={fill} />
    <path d="M2.11232 0H8.33211L6.95299 1.46983H2.11232C1.72345 1.46983 1.40821 1.79887 1.40821 2.20475V6.62997C1.40821 6.83541 1.48897 7.02116 1.61916 7.15452L0.633849 8.20464C0.242692 7.80448 0 7.24681 0 6.62997V2.20475C0 0.987102 0.945716 0 2.11232 0Z" fill={fill} />
    <path d="M12.0214 1.75629C13.1118 2.89439 13.1118 4.73961 12.0214 5.87771L9.75757 8.24064C8.66719 9.37873 6.89932 9.37874 5.80894 8.24064C5.53397 7.95364 5.53397 7.48831 5.80894 7.20131C6.08391 6.91431 6.52973 6.91431 6.8047 7.20131C7.34514 7.7654 8.22137 7.7654 8.76182 7.20131L11.0257 4.83838C11.5661 4.27428 11.5661 3.35971 11.0257 2.79562C10.4852 2.23153 9.60901 2.23153 9.06857 2.79562L7.57054 4.35919C7.29557 4.64619 6.84976 4.64619 6.57479 4.35919C6.29982 4.07219 6.29982 3.60687 6.57479 3.31986L8.07281 1.75629C9.16319 0.618193 10.9311 0.618193 12.0214 1.75629Z" fill={fill} />
    <path d="M1.72413 12.1464C0.633751 11.0083 0.633751 9.16311 1.72414 8.02501L3.988 5.66208C5.07839 4.52398 6.84625 4.52398 7.93664 5.66208C8.21161 5.94908 8.21161 6.4144 7.93664 6.70141C7.66166 6.98841 7.21585 6.98841 6.94088 6.70141C6.40044 6.13731 5.5242 6.13731 4.98376 6.70141L2.71989 9.06434C2.17945 9.62843 2.17945 10.543 2.71989 11.1071C3.26033 11.6712 4.13657 11.6712 4.67701 11.1071L6.17503 9.54353C6.45 9.25652 6.89582 9.25652 7.17079 9.54353C7.44576 9.83053 7.44576 10.2959 7.17079 10.5829L5.67277 12.1464C4.58238 13.2845 2.81452 13.2845 1.72413 12.1464Z" fill={fill} />
  </svg>

);

export default paymentslink;